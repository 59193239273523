import React from "react";
import { useEffect, useState } from "react";

function MortgageQuery() {
  const apiUrl = process.env.REACT_APP_URL;
  const [data, setData] = useState([]);
  const apiKey = process.env.REACT_APP_API_KEY;
  const token = localStorage.getItem("Token");

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/mortgage/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setData(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);
  console.log(data);
  return (
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
    <div className="loader-inner">
      <svg>
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
            <feColorMatrix
              in="blur"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
              result="gooey"
            />
            <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}

          {/* dashbard-menu-wrap end  */}
          {/* content */}
          <div className="dashboard-content">
            {/* <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div> */}
            <div className="container dasboard-container">
              {/* dashboard-title */}

              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-listing-box fl-wrap">
                  {/* dashboard-listings-wrap*/}
                  <div className="dashboard-listings-wrap fl-wrap">
                    <div
                      className="box-widget-title "
                      style={{
                        textAlign: "center",
                        marginRight: "270px",
                        marginBottom: "-72px",
                      }}
                    >
                      Mortgage Query
                    </div>
                    <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                      <div className="row">
                        <div className="col-md-4">
                          {/*box-widget*/}

                          {/*box-widget end */}
                          {/*box-widget*/}
                          <div
                            className="box-widget fl-wrap"
                            style={{ marginTop: "45px" }}
                          >
                            <div className="banner-widget fl-wrap">
                              <div className="bg-wrap bg-parallax-wrap-gradien">
                                <div
                                  className="bg  "
                                  data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                />
                              </div>
                              <div className="banner-widget_content">
                                <h5>
                                  Do you want to join our real estate network?
                                </h5>
                                {/* <a
                                  href="#"
                                  className="btn float-btn color-bg small-btn"
                                >
                                   Listing
                                </a> */}
                              </div>
                            </div>
                          </div>
                          {/*box-widget end */}
                        </div>
                        <div className="col-sm-8" style={{ marginTop: "62px" }}>
                          <div className="listing-item">
                            <article className="geodir-category-listing fl-wrap">
                              <div className="geodir-category-img fl-wrap">
                                <a
                                  href="#"
                                  className="geodir-category-img_item"
                                >
                                  <img
                                    src="https://hauzzi.webkype.net/images/Mortgage-icon.jpg"
                                    alt
                                  />
                                </a>
                              </div>
                              <div className="geodir-category-content fl-wrap">
                                <div
                                  className="card-verified tolt"
                                  data-microtip-position="left"
                                  data-tooltip="Connect to CRM"
                                >
                                  {/* <i className="fa-solid fa-user-check" /> */}
                                  <a
                                    className="btn float-btn color-bg small-btn"
                                    style={{
                                      marginTop: "-17px",
                                      marginRight: "-14px",
                                    }}
                                  >
                                    CRM
                                  </a>
                                </div>

                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="agent_card-title fl-wrap">
                                      <h4>
                                        <a href="#">Gajendra Singh</a>
                                      </h4>
                                      <div className="geodir-category-location fl-wrap mb-0">
                                        <a href="#">
                                          Type of employment: Permanent
                                        </a>
                                      </div>
                                      <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                        <a href="#">
                                          Family Income: 13000 Euros/Mon
                                        </a>
                                      </div>
                                      <div className="geodir-category-location fl-wrap mt-0">
                                        <a href="#">
                                          Fees for other loans : 1200 Euros/Mon
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="agent_card-title fl-wrap">
                                      <div className="geodir-category-location fl-wrap mb-0">
                                        <a href="#">
                                          Type of purchase : Primary
                                        </a>
                                      </div>
                                      <div className="geodir-category-location fl-wrap mt-0 mb-0">
                                        <a href="#">
                                          When are you going to buy : I'm
                                          looking for housing/Mon
                                        </a>
                                      </div>
                                      <div className="geodir-category-location fl-wrap mt-0">
                                        <a href="#">
                                          Property Price : 685,000 Euros
                                        </a>
                                      </div>
                                      <div className="geodir-category-location fl-wrap mt-0">
                                        <a href="#">
                                          Savings contributed : 500.000 Euros
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit. Maecenas in pulvinar neque.
                                  Nulla finibus lobortis pulvinar. Donec a
                                  consectetur nulla.
                                </p>
                                <div className="small-facts fl-wrap">
                                  <ul>
                                    <li>
                                      <i className="fa-solid fa-phone" />{" "}
                                      <span>
                                        M: <strong>8130352808</strong>
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-envelope" />{" "}
                                      <span>
                                        E:{" "}
                                        <strong>
                                          gajendra.connect@gmail.com
                                        </strong>
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-comment-alt" />{" "}
                                      <span>
                                        Age: <strong>35 Years</strong>
                                      </span>
                                    </li>
                                    <li>
                                      <i className="fa-solid fa-comment-alt" />{" "}
                                      <span>
                                        Date: <strong>3/Feb/2024</strong>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </article>
                          </div>
                        </div>
                        {/*  agecy card item */}
                      </div>
                    </div>
                  </div>
                  {/* dashboard-listings-wrap end*/}
                </div>
                {/* pagination*/}

                {/* pagination end*/}
              </div>
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
}

export default MortgageQuery;
