import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Blog() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };
  useEffect(() => {
    fetch(`${apiUrl}/blog/getAll`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setData(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, []);

  console.log(data);

  return (

    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />

      <div id="main">

        <div id="wrapper">
          <div className="dashbard-menu-overlay" />
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-listing-box fl-wrap">
                  {/* col-list-wrap */}
                  <section className="gray-bg small-padding ">
                    <div className="container">
                      <div className="row">
                        {/* search sidebar*/}
                        <div className="col-md-4">
                          {/* <div className="list-searh-input-wrap-title fl-wrap">
                            <i className="fa-solid fa-sliders-h" />
                            <span>Search Agency</span>
                          </div> */}

                          {/*box-widget*/}

                          {/*box-widget end */}
                          {/*box-widget*/}
                          <div
                            className="box-widget fl-wrap"
                          // style={{ marginTop: "70px" }}
                          >
                            <div className="banner-widget fl-wrap">
                              <div className="bg-wrap bg-parallax-wrap-gradien">
                                <div
                                  className="bg  "
                                  data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                />
                              </div>
                              <div className="banner-widget_content">
                                <h5>Ideas are easy. Implementation is hard</h5>
                                <a
                                  href="addblog"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  Add Blog
                                </a>
                              </div>
                            </div>
                          </div>

                          {/*box-widget end */}
                        </div>
                        {/* search sidebar end*/}
                        <div className="col-md-8">
                          {/* list-main-wrap-header*/}
                          <div className="list-main-wrap-header box-list-header fl-wrap">
                            {/* list-main-wrap-title*/}
                            <div className="list-main-wrap-title">
                              <h2>
                                All Blogs
                                <strong>{data.length}</strong>
                              </h2>
                            </div>
                            {/* list-main-wrap-title end*/}
                            {/* list-main-wrap-opt*/}
                            <div className="list-main-wrap-opt">
                              {/* price-opt*/}
                              {/* <div className="price-opt">
                                <span className="price-opt-title">
                                  Sort by:
                                </span>
                                <div className="listsearch-input-item">
                                  <select
                                    data-placeholder="Popularity"
                                    className="chosen-select no-search-select"
                                  >
                                    <option>Popularity</option>
                                    <option>New Blog</option>
                                  </select>
                                </div>
                              </div> */}
                              {/* price-opt end*/}
                            </div>
                            {/* list-main-wrap-opt end*/}
                          </div>
                          {/* list-main-wrap-header end*/}
                          {/* dashboard-listings-wrap*/}
                          <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                style={{ display: "inline-grid" }}
                              >
                                {data.length &&
                                  data.map((item, index) => (
                                    <div
                                      className="listing-item"
                                      key={index}
                                      style={{ width: "100%" }}
                                    >
                                      <article className="geodir-category-listing fl-wrap">
                                        <div className="geodir-category-img fl-wrap">
                                          <a
                                            href="#"
                                            className="geodir-category-img_item"
                                          >
                                            {/* {item.agencyDetails.map(
                                            (agencyItem, index) => (
                                              <img
                                                key={index}
                                                src={`${apiUrl}/uploads/${agencyItem.photo}`}
                                                alt
                                              />
                                            )
                                          )} */}
                                            {
                                              <img
                                                style={{
                                                  height: "100px",
                                                  width: "100px",
                                                }}
                                                key={index}
                                                src={
                                                  item.photo == ""
                                                    ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                    : `${apiUrl}/uploads/${item.photo}`
                                                }
                                                alt
                                              />
                                            }
                                          </a>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-around",
                                              fontSize: "20px",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <a
                                              style={{ cursor: "pointer" }}
                                              data-tooltip="Edit"
                                              data-microtip-position="right"
                                              className="tolt "
                                            >
                                              {" "}
                                              <i class="fa-regular fa-pen-to-square"></i>
                                            </a>

                                            <a
                                              style={{ cursor: "pointer" }}
                                              className="tolt "
                                              data-tooltip="Delete"
                                              data-microtip-position="left"
                                            >
                                              <i class="fa-regular fa-square-minus"></i>
                                            </a>
                                          </div>
                                        </div>

                                        <div className="geodir-category-content fl-wrap">
                                          <div
                                            data-microtip-position="left"
                                            data-tooltip="Category"
                                            style={{ float: "right" }}
                                          >
                                            <i class="fa-solid fa-layer-group"></i>{" "}
                                            <span>{item.category}</span>
                                          </div>
                                          <div
                                            className="agent_card-title fl-wrap"
                                            style={{
                                              paddingTop: 0,
                                              width: "470px",
                                            }}
                                          >
                                            <h4>{item.title}</h4>
                                          </div>
                                          <div
                                            style={{
                                              // overflow: "hidden",
                                              // whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              height: "150px",
                                            }}
                                          >
                                            <p
                                              key={index}
                                              style={{ marginRight: "60px" }}
                                            >
                                              { }
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: TruncatedParagraph(
                                                    item.content,
                                                    300
                                                  ),
                                                }}
                                              />
                                            </p>
                                          </div>

                                          {/* <div className="small-facts fl-wrap">
                                            <ul>
                                              <li>
                                                <i className="fa-solid fa-home" />{" "}
                                                <span>{item.category}</span>
                                              </li>
                                            </ul>
                                          </div> */}
                                        </div>
                                      </article>
                                    </div>
                                  ))}
                              </div>
                              {/*  agecy card item */}
                            </div>
                            {/* dashboard-listings-wrap end*/}
                            {/* pagination*/}
                            {/* <div className="pagination">
                              <a href="#" className="prevposts-link">
                                <i className="fa fa-caret-left" />
                              </a>
                              <a href="#">1</a>
                              <a href="#" className="current-page">
                                2
                              </a>
                              <a href="#">3</a>
                              <a href="#">4</a>
                              <a href="#" className="nextposts-link">
                                <i className="fa fa-caret-right" />
                              </a>
                            </div> */}
                            {/* pagination end*/}
                          </div>
                          {/* col-md 8 end */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
}

export default Blog;
