import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../index.css";
// import dd from '..//..//assets/dd.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser, faKey, faUser, faEnvelope, faPhone, faLocationPin, faGlobe, faHome, faEye, faLock, faShield, faShareNodes, faCamera } from '@fortawesome/free-solid-svg-icons';
// import { FaFacebook, FaInstagram, FaTwitter, FaVk } from "react-icons/fa";
const apiUrl = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const Token = localStorage.getItem("Token");

const AddStaff = () => {
  const [permission, setPermission] = useState([])

  const initialFormData = {
    userType: "",
    name: "",
    email: "",
    password: "",
    permission: []

  };
  const [formData, setFormData] = useState(initialFormData);
  const [blogPhoto, setBlogPhoto] = useState(null);
  const navigate = useNavigate();

  // console.log('permission =>', permission)

  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }


  };

  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData, "formData======???>>");

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      // const response = await fetch(`${apiUrl}/employee/add-staff`, {
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${Token}`,
      //   },
      //   body: formDataToSend,
      // });

      const response = await fetch(`${apiUrl}/employee/add-staff`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // window.location.reload()
      navigate("/staff");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              {/* dasboard-wrapper*/}
              <div className="dasboard-wrapper fl-wrap no-pag">
                <div className="row">
                  <div
                    className="col-md-8"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      {/* <div className="dasboard-widget-title fl-wrap">
                        {" "}
                        <h5>
                          <i className="fa-solid fa-user-circle" />
                          Change Avatar{" "}
                        </h5>{" "}
                      </div>{" "} */}
                      {/* <div className="dasboard-widget-box nopad-dash-widget-box fl-wrap">
                        <div className="edit-profile-photo">
                          {" "}
                          {blogPhoto && (
                            <img
                              src={URL.createObjectURL(blogPhoto)}
                              alt="Selected File"
                              // style={{ maxWidth: "350px" }}
                            />
                          )}
                          <div className="change-photo-btn">
                            {" "}
                            <div className="photoUpload">
                              <span> Upload Staff Photo</span>
                              <input
                                type="file"
                                className="upload"
                                name="photo"
                                onChange={(e) => {
                                  const files = e.target.files;
                                  if (files.length > 0) {
                                    const propertyFile = files[0];
                                    if (
                                      propertyFile.type.startsWith("image/")
                                    ) {
                                      setBlogPhoto(propertyFile);
                                      handleChange(e);
                                    }
                                  }
                                }}
                              />{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg"
                            data-bg="https://hauzzi.webkype.net/images/bg/3.jpg"
                          />{" "}
                        </div>{" "}
                        <div className="change-photo-btn cpb-2  ">
                          {" "}
                          <div className="photoUpload color-bg">
                            {" "}
                            <span>
                              <i className="fa-solid fa-camera" /> Change Cover{" "}
                            </span>
                            <input type="file" className="upload" />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "} */}
                      <div className="dasboard-widget-title fl-wrap">
                        {" "}
                        <h5>
                          <i className="fa-solid fa-key" />
                          Staff Info{" "}
                        </h5>{" "}
                      </div>{" "}
                      <div className="dasboard-widget-box fl-wrap">
                        {" "}
                        <form className="custom-form" onSubmit={handleSubmit}>
                          {" "}
                          <label>
                            User Type
                            {/* <span className="dec-icon">
                              <i className="fa-solid fa-user" />{" "}
                            </span>{" "} */}
                          </label>{" "}
                          {/* <input
                            type="text"
                            placeholder="Alica"
                            defaultValue
                            style={{ width: "100%" }}
                          />{" "} */}
                          <select
                            name="userType"
                            value={formData.userType}
                            onChange={(e) => { handleChange(e) }}
                            style={{
                              height: "4vh",
                              display: "inline",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #808080",
                              transition: "box-shadow 0.3s ease",
                              outline: "none  ",
                              width: "400px",
                              height: "50px",
                              color: "#808080",
                              background: "#f5f7fb",
                              marginBottom: "10px",
                            }}
                            required
                          >
                            <option>Select Type</option>
                            <option value="0">Staff</option>
                            <option value="1">Admin</option>
                          </select>
                          <label>
                            Full Name{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-user" />{" "}
                            </span>{" "}
                          </label>{" "}
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            required
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />{" "}
                          <label>
                            Email Address{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-envelope" />{" "}
                            </span>{" "}
                          </label>{" "}
                          <input
                            type="text"
                            placeholder="email"
                            name="email"
                            required
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <label>
                            Phone
                            <span className="dec-icon">
                              <i className="fa-solid fa-phone" />{" "}
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="+7(123)987654"
                            name="phone"
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          /> */}
                          <label>
                            Password
                            <span className="dec-icon">
                              <i className="fa-solid fa-map-marker" />{" "}
                            </span>
                          </label>
                          <input
                            type="password"
                            placeholder="Enter Password"
                            name="password"
                            required
                            style={{ width: "100%" }}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <label>
                          Website{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-globe" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="themeforest.net"
                          defaultValue
                          style={{ width: "100%" }}
                        /> */}
                          {/* <label>
                          Agensy
                          <span className="dec-icon">
                            <i className="fa-solid fa-home-lg-alt" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Mavers RealEstate Agency"
                          defaultValue
                          style={{ width: "100%" }}
                        /> */}
                          {/* <label>Notes </label>
                        <textarea
                          cols={40}
                          rows={3}
                          placeholder="About Me"
                          style={{ marginBottom: 20, width: "100%" }}
                          defaultValue={""}
                        />
                      </div> */}
                          <button
                            type="submit"
                            className="btn color-bg float-btn m-0"
                            onClick={() => {
                              setFormData((prev) => {
                                const obj = { ...prev }
                                obj.permission = permission
                                return obj
                              })
                            }}
                          >
                            Add Staff
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="dasboard-widget-title dbt-mm fl-wrap">
                        <h5>
                          <i className="fa-solid fa-key" />
                          Checkboxes
                        </h5>
                      </div>

                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Master
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-lock-open-alt" />
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name='Master'
                              placeholder
                              defaultChecked={permission.includes('Master')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })

                                }
                              }}
                            // style={{ width: "100%" }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              CMS
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-lock-alt" />
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="CMS"
                              placeholder
                              defaultChecked={permission.includes('CMS')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Staff
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name='Staff'
                              placeholder
                              defaultChecked={permission.includes('Staff')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Agent
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="Agent"
                              placeholder
                              defaultChecked={permission.includes('Agent')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              User
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="User"
                              placeholder
                              defaultChecked={permission.includes('User')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Agency
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="Agency"
                              placeholder
                              defaultChecked={permission.includes('Agency')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Property
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="Property"
                              placeholder
                              defaultChecked={permission.includes('Property')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Booking
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              className="pass-input"
                              name="Booking"
                              placeholder
                              defaultChecked={permission.includes('Booking')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>
                          <div
                            className="pass-input-wrap fl-wrap"
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                            }}
                          >
                            <label>
                              Reports
                              {/* <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span> */}
                            </label>
                            <input
                              type="checkbox"
                              name="Reports"
                              className="pass-input"
                              placeholder
                              defaultChecked={permission.includes('Reports')}
                              onChange={(e) => {
                                console.log(e.target.name)
                                if (e.target.checked) {
                                  setPermission((prev) => [...prev, e.target.name])
                                } else {
                                  setPermission((prev) => {
                                    const arr = [...prev]
                                    const index = arr.indexOf(e.target.name)
                                    if (index > -1) {
                                      arr.splice(index, 1)
                                    }
                                    return arr
                                  })
                                }
                              }}
                            />
                          </div>

                          {/* <button className="btn    color-bg  float-btn">
                            Give Permission
                          </button> */}
                        </div>
                      </div>
                      {/* <div
                      className="dasboard-widget-title fl-wrap"
                      style={{ marginTop: 30 }}
                    >
                      <h5>
                        <i className="fa-solid fa-share-alt" />
                        Your Socials
                      </h5>
                    </div> */}
                      {/* <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <label>
                          Facebook{" "}
                          <span className="dec-icon">
                            <i className="fab fa-facebook" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://www.facebook.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Twitter{" "}
                          <span className="dec-icon">
                            <i className="fab fa-twitter" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://twitter.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Instagram
                          <span className="dec-icon">
                            <i className="fab fa-instagram" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://www.instagram.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Vkontakte
                          <span className="dec-icon">
                            <i className="fab fa-vk" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://vk.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                      </div>
                      <button className="btn color-bg float-btn">
                        Save Changes
                      </button>
                    </div> */}

                      {/* <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Current Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-lock-open-alt" />
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              defaultValue
                              style={{ width: "100%" }}
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-lock-alt" />
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              defaultValue
                              style={{ width: "100%" }}
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Confirm New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              style={{ width: "100%" }}
                              defaultValue
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <button className="btn    color-bg  float-btn">
                            Save Changes
                          </button>
                        </div>
                      </div> */}
                      {/* <div
                        className="dasboard-widget-title fl-wrap"
                        style={{ marginTop: 30 }}
                      >
                        <h5>
                          <i className="fa-solid fa-share-alt" />
                          Your Socials
                        </h5>
                      </div> */}
                    </div>
                    {/* <div className="col-md-4">
                      <div className="dasboard-widget-title dbt-mm fl-wrap">
                        <h5>
                          <i className="fa-solid fa-key" />
                          Change Password
                        </h5>
                      </div>
                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Current Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-lock-open-alt" />
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              defaultValue
                              style={{ width: "100%" }}
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-lock-alt" />
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              style={{ width: "100%" }}
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <div className="pass-input-wrap fl-wrap">
                            <label>
                              Confirm New Password
                              <span className="dec-icon">
                                <i className="fa-solid fa-shield-check" />{" "}
                              </span>
                            </label>
                            <input
                              type="password"
                              className="pass-input"
                              placeholder
                              style={{ width: "100%" }}
                              defaultValue
                            />
                            <span className="eye">
                              <i
                                className="fa-solid fa-eye"
                                aria-hidden="true"
                              />{" "}
                            </span>
                          </div>
                          <button className="btn    color-bg  float-btn">
                            Save Changes
                          </button>
                        </div>
                      </div>
                      <div
                        className="dasboard-widget-title fl-wrap"
                        style={{ marginTop: 30 }}
                      >
                        <h5>
                          <i className="fa-solid fa-share-alt" />
                          Your Socials
                        </h5>
                      </div>
                      <div className="dasboard-widget-box fl-wrap">
                        <div className="custom-form">
                          <label>
                            Facebook{" "}
                            <span className="dec-icon">
                              <i className="fab fa-facebook" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="https://www.facebook.com/"
                            defaultValue
                            style={{ width: "100%" }}
                          />
                          <label>
                            Twitter{" "}
                            <span className="dec-icon">
                              <i className="fab fa-twitter" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="https://twitter.com/"
                            defaultValue
                            style={{ width: "100%" }}
                          />
                          <label>
                            Instagram
                            <span className="dec-icon">
                              <i className="fab fa-instagram" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="https://www.instagram.com/"
                            defaultValue
                            style={{ width: "100%" }}
                          />
                          <label>
                            Vkontakte
                            <span className="dec-icon">
                              <i className="fab fa-vk" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="https://vk.com/"
                            defaultValue
                            style={{ width: "100%" }}
                          />
                        </div>
                        <button className="btn color-bg float-btn">
                          Save Changes
                        </button>
                      </div>
                    </div> */}

                    {/*box-widget*/}
                    <div className="box-widget fl-wrap">
                      <div className="banner-widget fl-wrap">
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg  "
                            data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                          />
                        </div>
                        <div className="banner-widget_content">
                          <h5>
                            Do you want to join our real estate network?
                          </h5>
                          {/* <a
                                  href="#"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  Become an Property
                                </a> */}
                        </div>
                      </div>
                    </div>
                    {/*box-widget end */}


                  </div>
                </div>

              </div>

              <div
                className="dashboard-footer"
                style={{ left: "-95px", bottom: "-50px", right: "-95px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>

              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
};

export default AddStaff;
