import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../index.css";
// import JoditEditor from "jodit-react";

// import { Editor } from "@tinymce/tinymce-react";

// import JoditEditor from "jodit-react";
// import HTMLReactParser from "html-react-parser";

function AddBlog() {
  const initialFormData = {
    title: "",
    content: "",
    category: "",
    photo: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [blogPhoto, setBlogPhoto] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");


  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    // if (name === "content") {
    //   return;
    // }


    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  // setFormData((prev) => ({
  //   ...prev,
  //   content: stripHtmlTags(content),
  // }));

  // const editorRef = useRef(null);
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  // A function to handle the submit of the property form

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setFormData((prev) => ({
  //     ...prev,
  //     content: editorRef.current.getContent(),
  //   }));
  //   try {
  //     const formDataToSend = new FormData();
  //     for (const key in formData) {
  //       if (formData[key] !== null) {
  //         formDataToSend.append(key, formData[key]);
  //       }
  //     }
  //     console.log(editorRef.current.getContent());
  //     formDataToSend.append("content", editorRef.current.getContent());
  //     console.log(formData, "formData======???>>");
  //     const response = await fetch(`${apiUrl}/blog/add`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${Token}`,
  //       },
  //       body: formDataToSend,
  //     });

  //     const response2 = await response.json();
  //     if (response2.status === "error") {
  //       throw new Error(response2.message);
  //     }
  //     toast.success(response2.message);
  //     // window.location.reload()
  //     navigate("/blog");
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const content1 = editor.current
    console.log('content! =>', content1);
    // console.log('content =>', content)
    // console.log('content1 =>', HTMLReactParser(content1).props.children);

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const plainText = doc.body.textContent || "";

    setFormData((prev) => ({ ...prev, content: plainText }));
    try {
      const formDataToSend = new FormData();

      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }



      const response = await fetch(`${apiUrl}/blog/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      navigate("/blog");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link rel="shortcut icon" href="images/favicon.ico" />
      {/*loader*/}
      {/* <div className="loader-wrap">
    <div className="loader-inner">
      <svg>
        <defs>
          <filter id="goo">
            <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
            <feColorMatrix
              in="blur"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
              result="gooey"
            />
            <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* content */}

          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fas fa-bars" />
              </span>
              Dasboard Menu{" "}
            </div>{" "}
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              {/* dasboard-wrapper*/}{" "}
              <div className="dasboard-wrapper fl-wrap no-pag">
                {" "}
                <div className="row">
                  <div className="col-md-4">
                    {/* <div className="list-searh-input-wrap-title fl-wrap">
                            <i className="fa-solid fa-sliders-h" />
                            <span>Search Agency</span>
                          </div> */}

                    {/*box-widget*/}

                    {/*box-widget end */}
                    {/*box-widget*/}
                    <div
                      className="box-widget fl-wrap"
                    // style={{ marginTop: "70px" }}
                    >
                      <div className="banner-widget fl-wrap">
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg  "
                            data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                          />
                        </div>
                        <div className="banner-widget_content">
                          <h5>Ideas are easy. Implementation is hard</h5>
                          <a
                            href="blog"
                            className="btn float-btn color-bg small-btn"
                          >
                            Blog Listing
                          </a>
                        </div>
                      </div>
                    </div>

                    {/*box-widget end */}
                  </div>{" "}
                  <div className="col-md-8" style={{ marginTop: "20px" }}>
                    {" "}
                    <div className="dasboard-widget-title fl-wrap">
                      {" "}
                      <h5>
                        <i className="fa-solid fa-blog" />
                        Add Blogs{" "}
                      </h5>{" "}
                    </div>{" "}
                    <div className="dasboard-widget-box fl-wrap">
                      {" "}
                      <form>
                        {" "}
                        <div className="custom-form">
                          {" "}
                          <label>
                            Title{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-pen" />{" "}
                            </span>{" "}
                          </label>{" "}
                          <input
                            type="text"
                            placeholder="Enter blog title"
                            name="title"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%" }}
                          />
                          <label>
                            Category{" "}
                            {/* <span className="dec-icon">
                              <i className="fa-solid fa-pen" />
                            </span> */}
                          </label>
                          {/* <input
                            type="text"
                            placeholder="Enter blog category"
                            name="category"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%" }}
                          />{" "} */}
                          <div className="listsearch-input-item">
                            {" "}
                            <select
                              name="category"
                              value={formData.category}
                              onChange={handleChange}
                              style={{
                                height: "4vh",
                                display: "inline",

                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #808080",
                                transition: "box-shadow 0.3s ease",
                                outline: "none  ",
                                width: "780px",
                                height: "50px",
                                color: "#808080",
                                background: "#f5f7fb",
                              }}
                            >
                              <option>Select a category</option>
                              <option>Agent</option>
                              <option>Property</option>
                              <option>Agency</option>
                              <option>User</option>
                            </select>
                          </div>
                          {/* <label style={{ marginTop: "20px" }}>
                            Content{" "} */}
                          {/* <span className="dec-icon">
                              <i className="fa-solid fa-pen" />
                            </span> */}
                          {/* </label> */}
                          {/* <span>
                              {" "}
                              <i className="fa-solid fa-image" /> Blog Cover{" "}
                            </span> */}
                          <textarea
                            cols={40}
                            rows={3}
                            placeholder="Enter blog content !"
                            style={{ marginBottom: 20, width: "100%" }}
                            name="content"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <Editor
                            apiKey="et5utg7dawpzn7ytcg672kxyl722w9g8qfrvjxd52tp0fx7j"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue="<p>Typing...</p>"
                            init={{
                              height: 500,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          /> */}
                          {/* <div style={{ marginTop: "30px", zIndex:'2' }}>
                            {" "}
                            <JoditEditor
                              ref={editor}
                              value={content}
                              tabIndex={2} // tabIndex of textarea
                              onBlur={(newContent) => setContent(newContent)}
                              onChange={(newContent) => {
                                setContent(newContent);
                              }}
                            />
                            <div>{HTMLReactParser(content)}</div>
                          </div> */}
                          {/* <button onClick={log}>Log editor content</button> */}
                          {/* <button onClick={log}>Log editor content</button> */}

                          {/* <AceEditor
                            mode="javascript"
                            theme="twilight"
                            value={formData.content}
                            name="content"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            editorProps={{ $blockScrolling: true }}
                            style={{ width: "100%", height: "300px" }}
                          /> */}

                          {/* <div>

                            <JoditEditor
                              ref={editor}
                              value={content}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                              onChange={newContent => { }}
                              onChange={(newContent) => setContent(newContent)}
                            />

                            <div>{HTMLReactParser(content)}</div>

                          </div> */}
                          <input
                            type="file"
                            // className="upload"
                            name="photo"
                            onChange={(e) => {
                              const files = e.target.files;
                              if (files.length > 0) {
                                const propertyFile = files[0];
                                if (propertyFile.type.startsWith("image/")) {
                                  setBlogPhoto(propertyFile);
                                  handleChange(e);
                                }
                              }
                            }}
                            style={{
                              width: "100%",
                              marginTop: "20px",
                              marginBottom: "30px",
                              color: "#808080",
                            }}
                          />
                          {blogPhoto && (
                            <img
                              src={URL.createObjectURL(blogPhoto)}
                              alt="Selected File"
                              style={{ maxWidth: "350px" }}
                            />
                          )}
                        </div>
                        <button
                          className="btn color-bg float-btn m-0 text-white"
                          onClick={handleSubmit}
                        >
                          Add Blog
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* dasboard-wrapper end */}
            </div>
            {/* dashboard-footer */}
            <div className="dashboard-footer" style={{ left: 0 }}>
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>

    // <div>
    //   <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //   <link type="text/css" rel="stylesheet" href="css/style.css" />
    //   <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //   <link type="text/css" rel="stylesheet" href="css/color.css" />
    //   {/*=============== favicons ===============*/}
    //   <link
    //     rel="shortcut icon"
    //     href="https://hauzzi.webkype.net/images/favicon.ico"
    //   />
    //   {/*loader*/}
    //   {/* <div className="loader-wrap">
    //             <div className="loader-inner">
    //                 <svg>
    //                     <defs>
    //                         <filter id="goo">
    //                             <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //                             <feColorMatrix
    //                                 in="blur"
    //                                 values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //                                 result="gooey"
    //                             />
    //                             <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //                         </filter>
    //                     </defs>
    //                 </svg>
    //             </div>
    //         </div> */}
    //   {/*loader end*/}
    //   {/* main */}
    //   <div>
    //     {/* wrapper  */}
    //     <div id="wrapper">
    //       {/* dashbard-menu-wrap */}
    //       <div className="dashbard-menu-overlay" />
    //       {/* content */}
    //       <div className="dashboard-content pt-0">
    //         <div className="dashboard-menu-btn color-bg">
    //           <span>
    //             <i className="fas fa-bars" />
    //           </span>
    //           Dasboard Menu
    //         </div>
    //         <div className="container dasboard-container">
    //           {/* dashboard-title end */}
    //           {/* dasboard-wrapper*/}
    //           <div className="dasboard-wrapper fl-wrap no-pag">
    //             <div className="row">
    //               <div className="col-md-12">
    //                 <div className="dasboard-widget-title fl-wrap">
    //                   <h5>
    //                     <i className="fas fa-user-circle" />
    //                     Add Blogs
    //                   </h5>
    //                 </div>
    //                 <div className="dasboard-widget-box fl-wrap">
    //                   <form>
    //                     <div className="custom-form">
    //                       <label>
    //                         Title{" "}
    //                         <span className="dec-icon">
    //                           <i className="fa-solid fa-pen" />
    //                         </span>
    //                       </label>
    //                       <input
    //                         type="text"
    //                         placeholder="enter blog title"
    //                         name="title"
    //                         onChange={(e) => {
    //                           handleChange(e);
    //                         }}
    //                       />
    //                       <label>
    //                         Category{" "}
    //                         <span className="dec-icon">
    //                           <i className="fa-solid fa-pen" />
    //                         </span>
    //                       </label>
    //                       <input
    //                         type="text"
    //                         placeholder="enter blog category"
    //                         name="category"
    //                         onChange={(e) => {
    //                           handleChange(e);
    //                         }}
    //                       />
    //                       <span>
    //                         {" "}
    //                         <i className="fa-solid fa-image" /> Blog Cover{" "}
    //                       </span>
    //                       <input
    //                         type="file"
    //                         className="upload"
    //                         name="photo"
    //                         onChange={(e) => {
    //                           const files = e.target.files;
    //                           if (files.length > 0) {
    //                             const propertyFile = files[0];
    //                             if (propertyFile.type.startsWith("image/")) {
    //                               setBlogPhoto(propertyFile);
    //                               handleChange(e);
    //                             }
    //                           }
    //                         }}
    //                       />
    //                       {blogPhoto && (
    //                         <img
    //                           src={URL.createObjectURL(blogPhoto)}
    //                           alt="Selected File"
    //                           style={{ maxWidth: "350px" }}
    //                         />
    //                       )}
    //                       <label>Content </label>
    //                       <textarea
    //                         cols={40}
    //                         rows={3}
    //                         placeholder="enter blog content !"
    //                         style={{ marginBottom: 20 }}
    //                         name="content"
    //                         onChange={(e) => {
    //                           handleChange(e);
    //                         }}
    //                       />
    //                     </div>
    //                     <button
    //                       className="btn color-bg float-btn m-0 text-white"
    //                       onClick={handleSubmit}
    //                     >
    //                       Add Blog
    //                     </button>
    //                   </form>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           {/* dasboard-wrapper end */}
    //         </div>
    //         {/* dashboard-footer */}
    //         <div className="dashboard-footer" style={{ left: 0 }}>
    //           <div className="dashboard-footer-links fl-wrap">
    //             <span>Helpfull Links:</span>
    //             <ul>
    //               <li>
    //                 <a href="about.html">About</a>
    //               </li>
    //               <li>
    //                 <a href="blog.html">Blog</a>
    //               </li>
    //               <li>
    //                 <a href="pricing.html">Pricing Plans</a>
    //               </li>
    //               <li>
    //                 <a href="contacts.html">Contacts</a>
    //               </li>
    //               <li>
    //                 <a href="help.html">Help Center</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <a href="#main" className="dashbord-totop  custom-scroll-link">
    //             <i className="fa-solid fa-caret-up" />
    //           </a>
    //         </div>
    //         {/* dashboard-footer end */}
    //       </div>
    //       {/* content end */}
    //       <div className="dashbard-bg gray-bg" />
    //     </div>
    //     {/* wrapper end */}
    //   </div>
    //   {/* Main end */}
    //   {/*=============== scripts  ===============*/}
    // </div>
  );
}

export default AddBlog;
