import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import imgstafff from "..//..//assets/imgstafff.png";
import ggg from "..//..//assets/ggg.jpg";
import fff from "..//..//assets/fff.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSliders,
  faUserCheck,
  faTrash,
  faStar,
  faRotate,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";



const Staff = () => {
  const [data2, setData2] = useState([]);
  const [data1, setData1] = useState([]);


  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");
  const apiKey = process.env.REACT_APP_API_KEY;

  function handleDelete(e, id) {
    e.preventDefault()

    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/employee/delete-staff/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          window.location.reload(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/staff/getAll?userType=1`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setData1(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/staff/getAll?userType=0`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setData2(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);

  // console.log(data1);
  // console.log(data2);

  const allData = [...data1, ...data2];
  console.log(allData);
  return (
    // <>
    //     <div className="dashboard-content pt-0">
    //         <div className="dashboard-menu-btn color-bg"><span><i className="fa-solid fa-bars"></i></span>Dasboard Menu</div>
    //         <div className="container dasboard-container">

    //             <div className="dasboard-wrapper fl-wrap">
    //                 <div className="dasboard-listing-box fl-wrap">
    //                     <section className="gray-bg small-padding ">
    //                         <div className="container">
    //                             <div className="row">
    //                                 <div className="col-md-4">
    //                                     <div className="list-searh-input-wrap-title fl-wrap"><FontAwesomeIcon icon={faSliders} /><span>Search Staff</span></div>
    //                                     <div className="block-box fl-wrap search-sb" id="filters-column">
    //                                         <div className="listsearch-input-item">
    //                                             <label>Keywords</label>
    //                                             <input type="text" onclick="this.select()" placeholder="Name , agency..." value="" />
    //                                         </div>

    //                                         <div className="listsearch-input-item">
    //                                             <label>Cities</label>
    //                                             <select data-placeholder="All Cities" className="chosen-select on-radius no-search-select" style={{ display: 'none' }}>
    //                                                 <option>All Cities</option>
    //                                                 <option>New York</option>
    //                                                 <option>London</option>
    //                                                 <option>Paris</option>
    //                                                 <option>Kiev</option>
    //                                                 <option>Moscow</option>
    //                                                 <option>Dubai</option>
    //                                                 <option>Rome</option>
    //                                                 <option>Beijing</option>
    //                                             </select><div className="nice-select chosen-select on-radius no-search-select" tabindex="0"><span className="current">All Cities</span><div className="nice-select-search-box"><input type="text" className="nice-select-search" placeholder="Search..." /></div><ul className="list"><li data-value="All Cities" className="option selected">All Cities</li><li data-value="New York" className="option">New York</li><li data-value="London" className="option">London</li><li data-value="Paris" className="option">Paris</li><li data-value="Kiev" className="option">Kiev</li><li data-value="Moscow" className="option">Moscow</li><li data-value="Dubai" className="option">Dubai</li><li data-value="Rome" className="option">Rome</li><li data-value="Beijing" className="option">Beijing</li></ul></div>
    //                                         </div>

    //                                         <div className="listsearch-input-item">
    //                                             <div className="price-rage-item fl-wrap">
    //                                                 <span className="pr_title">Rating:</span>
    //                                                 <span className="irs js-irs-0"><span className="irs"><span className="irs-line" tabindex="0"><span className="irs-line-left"></span><span className="irs-line-mid"></span><span className="irs-line-right"></span></span><span className="irs-min" style={{ visibility: "hidden" }}>*1</span><span className="irs-max" style={{ visibility: "hidden" }}>*5</span><span className="irs-from" style={{ visibility: 'visible', left: '-1.34672%' }}>*1</span><span className="irs-to" style={{ visibility: 'visible', left: "91.9287%" }}>*5</span><span className="irs-single" style={{ visibility: 'hidden', left: "40.265%" }}>*1 — *5</span></span><span className="irs-grid"></span><span className="irs-bar" style={{ left: '3.24837%', width: "93.5033%" }}></span><span className="irs-shadow shadow-from" style={{ display: 'none' }}></span><span className="irs-shadow shadow-to" style={{ display: 'none' }}></span><span className="irs-slider from" style={{ left: "0%" }}></span><span className="irs-slider to type_last" style={{ left: "93.5033%" }}></span></span><input type="text" className="price-range-double irs-hidden-input" data-min="1" data-max="5" name="price-range2" data-step="1" value="1" data-prefix="*" tabindex="-1" readonly="" />
    //                                             </div>
    //                                         </div>
    //                                         <div className="msotw_footer">
    //                                             <a href="/#" className="btn small-btn float-btn color-bg">Search Staff</a>
    //                                             <div className="reset-form reset-btn"> <FontAwesomeIcon icon={faRotate} /> Reset Filters</div>
    //                                         </div>
    //                                     </div>
    //                                     <div className="box-widget fl-wrap">
    //                                         <div className="box-widget-title fl-wrap">Featured Staff</div>
    //                                         <div className="box-widget-content fl-wrap">
    //                                             <div className="widget-posts  fl-wrap">
    //                                                 <ul className="no-list-style">
    //                                                     <li>
    //                                                         <div className="widget-posts-img"><a href="agent-single.html"><img src={ggg} alt="" /></a>
    //                                                         </div>
    //                                                         <div className="widget-posts-descr agent-post_descr">
    //                                                             <h4><a href="agent-single.html">Liza Rose</a></h4>
    //                                                             <div className="agent-post_descr_counter fl-wrap"><span>21</span> Property Listings</div>
    //                                                             <div className="listing-rating card-popup-rainingvis" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                             <a href="mailto:yourmail@email.com" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Write Message"><FontAwesomeIcon icon={faEnvelope} /></a>
    //                                                             <a href="tel:123-456-7890" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Call Now"><FontAwesomeIcon icon={faPhone} /></a>
    //                                                         </div>
    //                                                     </li>
    //                                                     <li>
    //                                                         <div className="widget-posts-img"><a href="agent-single.html"><img src={fff} alt="" /></a>
    //                                                         </div>
    //                                                         <div className="widget-posts-descr agent-post_descr">
    //                                                             <h4><a href="agent-single.html">Martin Smith</a></h4>
    //                                                             <div className="agent-post_descr_counter fl-wrap"><span>5</span> Property Listings</div>
    //                                                             <div className="listing-rating card-popup-rainingvis" data-starrating2="5"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                             <a href="mailto:yourmail@email.com" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Write Message"><FontAwesomeIcon icon={faEnvelope} /></a>
    //                                                             <a href="tel:123-456-7890" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Call Now"><FontAwesomeIcon icon={faPhone} /></a>
    //                                                         </div>
    //                                                     </li>
    //                                                     <li>
    //                                                         <div className="widget-posts-img"><a href="agent-single.html"><img src={ggg} alt="" /></a>
    //                                                         </div>
    //                                                         <div className="widget-posts-descr agent-post_descr">
    //                                                             <h4><a href="agent-single.html">Andy Sposty</a></h4>
    //                                                             <div className="agent-post_descr_counter fl-wrap"><span>10</span> Property Listings</div>
    //                                                             <div className="listing-rating card-popup-rainingvis" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                             <a href="mailto:yourmail@email.com" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Write Message"><FontAwesomeIcon icon={faEnvelope} /></a>
    //                                                             <a href="tel:123-456-7890" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Call Now"><FontAwesomeIcon icon={faPhone} /></a>
    //                                                         </div>
    //                                                     </li>
    //                                                     <li>
    //                                                         <div className="widget-posts-img"><a href="agent-single.html"><img src={fff} alt="" /></a>
    //                                                         </div>
    //                                                         <div className="widget-posts-descr agent-post_descr">
    //                                                             <h4><a href="agent-single.html">Anna Lips</a></h4>
    //                                                             <div className="agent-post_descr_counter fl-wrap"><span>12</span> Property Listings</div>
    //                                                             <div className="listing-rating card-popup-rainingvis" data-starrating2="5"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                 <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                             <a href="mailto:yourmail@email.com" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Write Message"><FontAwesomeIcon icon={faEnvelope} /></a>
    //                                                             <a href="tel:123-456-7890" className="tolt ftr-btn" data-microtip-position="top-left" data-tooltip="Call Now"><FontAwesomeIcon icon={faPhone} /></a>
    //                                                         </div>
    //                                                     </li>
    //                                                 </ul>
    //                                             </div>
    //                                             <a href="listing.html" className="btn float-btn color-bg small-btn">View All Staff</a>
    //                                         </div>
    //                                     </div>

    //                                     <div className="box-widget fl-wrap">
    //                                         <div className="banner-widget fl-wrap">
    //                                             <div className="bg-wrap bg-parallax-wrap-gradien">

    //                                                 <div className="bg  " data-bg="https://hauzzi.webkype.net/admin"https://hauzzi.webkype.net/admin/images/all/blog/1.jpg" style={{ backgroundImage: `url("https://hauzzi.webkype.net/admin"https://hauzzi.webkype.net/admin/images/all/blog/1.jpg")` }}></div>
    //                                             </div>
    //                                             <div className="banner-widget_content">
    //                                                 <h5>Do you want to join our real estate network?</h5>
    //                                                 <a href="/#" className="btn float-btn color-bg small-btn">Become an Agent</a>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>

    //                                 <div className="col-md-8">
    //                                     <div className="list-main-wrap-header box-list-header fl-wrap">
    //                                         <div className="list-main-wrap-title">
    //                                             <h2>Results For : <span>New York Staff </span><strong>26</strong></h2>
    //                                         </div>

    //                                         <div className="list-main-wrap-opt">
    //                                             <div className="price-opt">
    //                                                 <span className="price-opt-title">Sort by:</span>
    //                                                 <div className="listsearch-input-item">
    //                                                     <select data-placeholder="Popularity" className="chosen-select no-search-select" style={{ display: " none" }}>
    //                                                         <option>Popularity</option>
    //                                                         <option>Average rating</option>
    //                                                         <option>Name: A-Z</option>
    //                                                         <option>Name: Z-A</option>
    //                                                     </select><div className="nice-select chosen-select no-search-select" tabindex="0"><span className="current">Popularity</span><div className="nice-select-search-box"><input type="text" className="nice-select-search" placeholder="Search..." /></div><ul className="list"><li data-value="Popularity" className="option selected">Popularity</li><li data-value="Average rating" className="option">Average rating</li><li data-value="Name: A-Z" className="option">Name: A-Z</li><li data-value="Name: Z-A" className="option">Name: Z-A</li></ul></div>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="dashboard-listings-wrap fl-wrap">
    //                                         <div className="row">
    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">

    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>

    //                                             </div>

    //                                             <div className="col-md-4">
    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>
    //                                             </div>

    //                                             <div className="col-md-4">
    //                                                 <div className="listing-item" style={{ height: "409.175px" }}>
    //                                                     <article className="geodir-category-listing fl-wrap">
    //                                                         <div className="geodir-category-img fl-wrap  agent_card">
    //                                                             <a href="/#" className="geodir-category-img_item">
    //                                                                 <img src={imgstafff} alt="" />
    //                                                             </a>

    //                                                         </div>
    //                                                         <div className="geodir-category-content fl-wrap">
    //                                                             <div className="card-verified tolt" data-microtip-position="left" data-tooltip="Verified"><FontAwesomeIcon icon={faUserCheck} /></div>
    //                                                             <div className="agent_card-title fl-wrap">
    //                                                                 <h4><a href="/#">San Dues Miller</a></h4>
    //                                                                 <h5><a href="/#">M: +91 8130352808</a></h5>
    //                                                                 <h5><a href="/#">E: peter@agency.com</a></h5>

    //                                                             </div>

    //                                                             <div className="geodir-category-footer fl-wrap">
    //                                                                 <div className="listing-rating card-popup-rainingvis tolt pull-left" data-microtip-position="top" data-tooltip="Good" data-starrating2="4"><FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", }} />
    //                                                                     <div className="card-popup-rainingvis_bg"><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><span className="card-popup-rainingvis_bg_item"></span><div></div></div></div>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Delete"><FontAwesomeIcon icon={faTrash} /></a>
    //                                                                 <a href="/#" className="tolt ftr-btn" data-microtip-position="left" data-tooltip="Verify"><FontAwesomeIcon icon={faUserCheck} /></a>
    //                                                             </div>
    //                                                         </div>
    //                                                     </article>
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="pagination">
    //                                         <a href="/#" className="prevposts-link"><i className="fa fa-caret-left"></i></a>
    //                                         <a href="/#">1</a>
    //                                         <a href="/#" className="current-page">2</a>
    //                                         <a href="/#">3</a>
    //                                         <a href="/#">4</a>
    //                                         <a href="/#" className="nextposts-link"><i className="fa fa-caret-right"></i></a>
    //                                     </div>
    //                                     {/* <!-- pagination end-->						 */}
    //                                 </div>
    //                                 {/* <!-- col-md 8 end --> */}

    //                             </div>
    //                         </div>
    //                     </section>
    //                 </div>

    //             </div>
    //         </div>
    //         {/* <!-- dashboard-footer --> */}
    //         <div className="dashboard-footer">
    //             <div className="dashboard-footer-links fl-wrap">
    //                 <span>Helpfull Links:</span>
    //                 <ul>
    //                     <li><a href="about.html">About  </a></li>
    //                     <li><a href="blog.html">Blog</a></li>
    //                     <li><a href="pricing.html">Pricing Plans</a></li>
    //                     <li><a href="contacts.html">Contacts</a></li>
    //                     <li><a href="help.html">Help Center</a></li>
    //                 </ul>
    //             </div>
    //             <a href="#main" className="dashbord-totop  custom-scroll-link"><i className="fa-solid fa-caret-up"></i></a>
    //         </div>
    //         {/* <!-- dashboard-footer end --> */}
    //     </div>
    // </>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/admin/images/favicon.ico"
      />
      {/*loader*/}

      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* dashbard-menu-wrap end  */}
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-listing-box fl-wrap">
                  {/* col-list-wrap */}
                  <section className="gray-bg small-padding ">
                    <div className="container">
                      <div className="row">
                        {/* search sidebar*/}

                        <div className="col-md-4">
                          {/*box-widget*/}

                          {/*box-widget end */}
                          {/*box-widget*/}
                          <div
                            className="box-widget fl-wrap"
                            style={{ marginTop: "70px" }}
                          >
                            <div className="banner-widget fl-wrap">
                              <div className="bg-wrap bg-parallax-wrap-gradien">
                                <div
                                  className="bg  "
                                  data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                />
                              </div>
                              <div className="banner-widget_content">
                                <h5>
                                  Do you want to join our real estate network?
                                </h5>
                                <a
                                  href="addstaff"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  Add Staff
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*box-widget end */}
                        </div>
                        {/* search sidebar end*/}
                        <div className="col-md-8">
                          {/* list-main-wrap-header*/}
                          <div className="list-main-wrap-header box-list-header fl-wrap">
                            {/* list-main-wrap-title*/}
                            <div className="list-main-wrap-title">
                              <h2 style={{ fontSize: "16px" }}>
                                <span>Staff Listing </span>
                                <strong>{allData.length}</strong>
                              </h2>
                            </div>
                            {/* list-main-wrap-title end*/}
                            {/* list-main-wrap-opt*/}
                            <div className="list-main-wrap-opt">
                              {/* price-opt*/}
                              <div className="price-opt">
                                <span className="price-opt-title">
                                  Sort by:
                                </span>
                                <div className="listsearch-input-item">
                                  <select
                                    data-placeholder="Popularity"
                                    className="chosen-select no-search-select"
                                  >
                                    <option>New Staff</option>
                                    <option>Name: A-Z</option>
                                    <option>Name: Z-A</option>
                                  </select>
                                </div>
                              </div>
                              {/* price-opt end*/}
                            </div>
                            {/* list-main-wrap-opt end*/}
                          </div>
                          {/* list-main-wrap-header end*/}
                          {/* dashboard-listings-wrap*/}
                          <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                style={{ display: "inline-grid" }}
                              >
                                {allData.length &&
                                  allData.map((item, index) => (
                                    <div
                                      className="listing-item"
                                      key={index}
                                      style={{ width: "100%" }}
                                    >
                                      <article className="geodir-category-listing fl-wrap">
                                        <div className="geodir-category-img fl-wrap">
                                          <a
                                            href="#"
                                            className="geodir-category-img_item"
                                          >
                                            {/* {item.agencyDetails.map(
                                                (agencyItem, index) => (
                                                  <img
                                                    key={index}
                                                    src={`${apiUrl}/uploads/${agencyItem.photo}`}
                                                    alt
                                                  />
                                                )
                                              )} */}
                                            {item.adminDetails.length !== 0 ? (
                                              item.adminDetails.map(
                                                (userItem, index) => (
                                                  <img
                                                    style={{
                                                      height: "128px",
                                                      width: "100px",
                                                    }}
                                                    key={index}
                                                    src={
                                                      userItem.photo == ""
                                                        ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                        : `${apiUrl}/uploads/${userItem.photo}`
                                                    }
                                                    alt
                                                  />
                                                )
                                              )
                                            ) : (
                                              <img
                                                style={{
                                                  height: "100px",
                                                  width: "100px",
                                                  borderRadius: "50%",
                                                }}
                                                key={index}
                                                src={
                                                  "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                }
                                                alt
                                              />
                                            )}
                                          </a>
                                        </div>
                                        <div className="geodir-category-content fl-wrap">
                                          <div
                                            className="card-verified tolt"
                                            data-microtip-position="left"
                                            data-tooltip="Connect to CRM"
                                          >
                                            {/* <i className="fa-solid fa-user-check" /> */}
                                            <a className="btn float-btn color-bg small-btn">
                                              {" "}
                                              CRM
                                            </a>
                                          </div>
                                          <div
                                            className="agent_card-title fl-wrap"
                                            style={{ paddingTop: "0" }}
                                          >
                                            <h4>
                                              {item.name == ""
                                                ? "Welcome Admin"
                                                : item.name}
                                            </h4>
                                            <p>{item.email}</p>
                                            <div>
                                              <ul
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "start",
                                                  gap: '5px',
                                                  color: "#808080",
                                                  borderTop:
                                                    "1px solid #808080",
                                                  margin: "3px 0px",
                                                  padding: "20px 0px",
                                                }}
                                              >
                                                {item?.permission?.map((ele, index) => <li key={index}>
                                                  {" "}
                                                  <i className="fa-solid fa-key" />{" "}
                                                  {ele}
                                                </li>
                                                )}
                                                {/* <li>
                                                  {" "}
                                                  <i className="fa-solid fa-key" />{" "}
                                                  CMS
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-key" />{" "}
                                                  Agent
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-key" />{" "}
                                                  User
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-key" />{" "}
                                                  Booking
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-key" />{" "}
                                                  Property
                                                </li> */}
                                              </ul>
                                            </div>

                                            <ul style={{
                                              display: 'flex',
                                              justifyContent: "end",
                                              alignItems: "center",
                                              gap: "30px",
                                              marginRight: "40px"
                                            }}>
                                              <li style={{ cursor: 'pointer' }}>
                                                <Link
                                                  to={`/${item.id}`}
                                                  className="tolt"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Edit"
                                                >
                                                  <i className="fa-solid fa-edit" />
                                                </Link>
                                              </li>
                                              <li style={{ cursor: 'pointer' }}>
                                                <div
                                                  onClick={(e) => {
                                                    handleDelete(e, item.id)
                                                  }}
                                                  className="tolt"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Delete"
                                                >
                                                  <i className="fa-solid fa-trash-alt" />
                                                </div>
                                              </li>
                                            </ul>



                                          </div>
                                          {/* {item.agencyDetails.map(
                                            (agencyItem, index) => (
                                              <p key={index}>
                                                {agencyItem.notes}
                                              </p>
                                            )
                                          )} */}

                                          {/* <div className="small-facts fl-wrap">
                                            <ul>
                                              <li>
                                                <i className="fa-solid fa-home" />{" "}
                                                <span>
                                                  <strong>36</strong>Listing
                                                </span>
                                              </li>
                                              <li>
                                                <i className="fa-solid fa-users-class" />{" "}
                                                <span>
                                                  <strong>10</strong>Agents
                                                </span>
                                              </li>
                                              <li>
                                                <i className="fa-solid fa-comment-alt" />{" "}
                                                <span>
                                                  <strong>56</strong>Reviews
                                                </span>
                                              </li>
                                              <li>
                                                <a
                                                  href="mailto:yourmail@email.com"
                                                  className="tolt ftr-btn mt-0"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Write Message"
                                                >
                                                  <i className="fa-solid fa-envelope" />
                                                </a>
                                              </li>
                                              <li>
                                                {" "}
                                                <a
                                                  href="tel:123-456-7890"
                                                  className="tolt ftr-btn mt-0"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Call Now"
                                                >
                                                  <i className="fa-solid fa-phone" />
                                                </a>
                                              </li>
                                            </ul>
                                          </div> */}
                                        </div>
                                      </article>
                                    </div>
                                  ))}
                              </div>

                              {/*  agecy card item */}
                            </div>
                            {/* dashboard-listings-wrap end*/}
                            {/* pagination*/}
                            {/* <div className="pagination">
                              <a href="#" className="prevposts-link">
                                <i className="fa fa-caret-left" />
                              </a>
                              <a href="#">1</a>
                              <a href="#" className="current-page">
                                2
                              </a>
                              <a href="#">3</a>
                              <a href="#">4</a>
                              <a href="#" className="nextposts-link">
                                <i className="fa fa-caret-right" />
                              </a>
                            </div> */}
                            {/* pagination end*/}
                          </div>
                          {/* col-md 8 end */}
                        </div>
                        {/* col-md 8 end */}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div >
        {/* wrapper end */}
      </div >
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div >
  );
};

export default Staff;
