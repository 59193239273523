import React from "react";
import { useState, useEffect } from "react";

import ChangeStatus from "./ChangeStatus";

const apiUrl = process.env.REACT_APP_URL;

const apiKey = process.env.REACT_APP_API_KEY;

function CompleteBooking() {
  const [status, setStatus] = useState("On Sale"); // Initial status set to 'cancelled'
  const handleChange = (e) => {
    setStatus(e.target.value); // Update status based on dropdown selection
  };
  const [data, setdata] = useState([]);
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/property/property`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  console.log(data);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}

                            {/*box-widget end */}
                            {/*box-widget*/}
                            <div
                              className="box-widget fl-wrap"
                              style={{ marginTop: "70px" }}
                            >
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  <a
                                    href="property"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Property Listing
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  <span>Complete Booking </span>
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">
                                    Sort by:
                                  </span>
                                  <div className="listsearch-input-item">
                                    <select
                                      data-placeholder="Popularity"
                                      className="chosen-select no-search-select"
                                    >
                                      <option>Popularity</option>
                                      <option>Average rating</option>
                                      <option>Name: A-Z</option>
                                      <option>Name: Z-A</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="listing-item-container fl-wrap">
                              {/* listing-item */}
                              {data.length &&
                                data.map((item, index) => (
                                  <div
                                    className="listing-item has_one_column"
                                    key={index}
                                  >
                                    <article className="geodir-category-listing fl-wrap">
                                      <div className="geodir-category-img fl-wrap">
                                        <a
                                          href={`https://hauzi-user.vercel.app/${item.slug}`}
                                          className="geodir-category-img_item"
                                        >
                                          <img
                                            src={`${apiUrl}/uploads/${item.photo1}`}
                                            alt
                                          />
                                          <div className="overlay" />
                                        </a>
                                        <div className="geodir-category-location">
                                          <a
                                            href="#1"
                                            className="map-item tolt"
                                            data-microtip-position="top-left"
                                            data-tooltip="On the map"
                                          >
                                            <i className="fa-solid fa-map-marker-alt" />{" "}
                                            {item.address1}
                                          </a>
                                        </div>
                                        <ul className="list-single-opt_header_cat">
                                          <li>
                                            <a
                                              href="#"
                                              className="cat-opt blue-bg"
                                              style={{
                                                backgroundColor: "#1f924c",
                                              }}
                                            >
                                              {status}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#"
                                              className="cat-opt color-bg"
                                            >
                                              {item.category}
                                            </a>
                                          </li>
                                        </ul>
                                        <div className="geodir-category-listing_media-list">
                                          <span>
                                            <i className="fa-solid fa-camera" />{" "}
                                            5
                                          </span>
                                        </div>
                                      </div>
                                      <div className="geodir-category-content fl-wrap">
                                        <h3>
                                          <a href="listing-single.html">
                                            {item.name}
                                          </a>
                                        </h3>
                                        <div className="geodir-category-content_price">
                                          ${item.maxPrice}
                                        </div>
                                        <p>
                                          {TruncatedParagraph(
                                            item.description,
                                            100
                                          )}
                                        </p>
                                        <div className="geodir-category-content-details">
                                          <ul>
                                            <li>
                                              <i className="fa-solid fa-bed" />
                                              <span>
                                                {" "}
                                                {item.listingDetails.Bedrooms}
                                              </span>
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-bath" />
                                              <span>
                                                {item.listingDetails.Bathroom}
                                              </span>
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-cube" />
                                              <span>{item.maxSize} sqft</span>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="geodir-category-footer fl-wrap">
                                          <a href="#" className="gcf-company">
                                            <img
                                              // src={`${apiUrl}/uploads/${
                                              //   item.Customer.userDetails[
                                              //     index
                                              //   ] == ""
                                              //     ? null
                                              //     : "item.Customer.userDetails[index].photo"
                                              //   // item.Customer.userDetails[index]
                                              //   //   .length &&
                                              //   // userDetails[index].photo
                                              // }`}
                                              src={
                                                // item.Customer.userDetails[index]
                                                //   .photo == " "
                                                //   ? "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                                //   : `${apiUrl}/uploads/${item.Customer.userDetails[index].photo}`
                                                `${apiUrl}/uploads/${item.Customer.userDetails.map(
                                                  (userItem) => userItem.photo
                                                )}`
                                              }
                                              alt
                                            />
                                            <span>{item.Customer.name}</span>
                                          </a>
                                          {/* <div
                                            className="listing-rating card-popup-rainingvis tolt"
                                            data-microtip-position="top"
                                            data-tooltip="Good"
                                            data-starrating2={4}
                                          /> */}
                                          <a
                                            className="btn float-btn color-bg small-btn tolt text-red"
                                            style={{ left: "60px" }}
                                            data-microtip-position="top-left"
                                            data-tooltip="Connect to CRM"
                                          >
                                            CRM
                                          </a>
                                        </div>
                                        <div className="dashboard-listings-item_opt btm_footer-item">
                                          <span className="viewed-counter">
                                            <i className="fa-solid fa-eye" />{" "}
                                            Viewed - {item.views}{" "}
                                          </span>
                                          <ul>
                                            <li>
                                              {/* <a
                                                href="#"
                                                className="tolt"
                                                data-microtip-position="top-left"
                                                data-tooltip="Edit"
                                              >
                                                <i className="fa-solid fa-edit" />
                                              </a> */}
                                              {/* <EditButton></EditButton> */}
                                              <a
                                                data-microtip-position="top-left"
                                                data-tooltip="Change Status"
                                              >
                                                {" "}
                                                <select
                                                  value={status}
                                                  onChange={handleChange}
                                                  style={{
                                                    width: "20px",
                                                    marginRight: "10px",
                                                    marginTop: "13px",
                                                  }}
                                                >
                                                  <option value="cancelled">
                                                    Cancelled
                                                  </option>
                                                  <option value="inprogress">
                                                    In Progress
                                                  </option>
                                                  <option value="complete">
                                                    Complete
                                                  </option>
                                                  <option value="booked">
                                                    Booked
                                                  </option>
                                                </select>
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#"
                                                className="tolt text-red"
                                                data-microtip-position="top-left"
                                                data-tooltip="View Property"
                                              >
                                                <i className="fa-solid fa-eye" />
                                              </a>
                                            </li>
                                            <li>
                                              <a
                                                href="#"
                                                className="tolt"
                                                data-microtip-position="top-left"
                                                data-tooltip="Delete"
                                              >
                                                <i className="fa-solid fa-trash-alt" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                ))}
                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                            </div>
                            {/* col-md 8 end */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default CompleteBooking;
