import React from "react";
// import dd from '..//..//assets/dd.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleUser, faKey, faUser, faEnvelope, faPhone, faLocationPin, faGlobe, faHome, faEye, faLock, faShield, faShareNodes, faCamera } from '@fortawesome/free-solid-svg-icons';
// import { FaFacebook, FaInstagram, FaTwitter, FaVk } from "react-icons/fa";

const Profile = () => {
  return (
    // <div>
    //     <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //     <link type="text/css" rel="stylesheet" href="css/style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/color.css" />
    //     {/*=============== favicons ===============*/}
    //     <link rel="shortcut icon" href="https://hauzzi.webkype.net/images/favicon.ico" />
    //     {/*loader*/}
    //     {/* <div className="loader-wrap">
    //         <div className="loader-inner">
    //             <svg>
    //                 <defs>
    //                     <filter id="goo">
    //                         <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //                         <feColorMatrix
    //                             in="blur"
    //                             values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //                             result="gooey"
    //                         />
    //                         <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //                     </filter>
    //                 </defs>
    //             </svg>
    //         </div>
    //     </div> */}
    //     {/*loader end*/}
    //     {/* main */}
    //     <div>
    //         {/* wrapper  */}
    //         <div id="wrapper">
    //             {/* dashbard-menu-wrap */}
    //             <div className="dashbard-menu-overlay" />
    //             {/* content */}
    //             <div className="dashboard-content pt-0">
    //                 <div className="dashboard-menu-btn color-bg">
    //                     <span>
    //                         <i className="fa-solid fa-bars" />
    //                     </span>
    //                     Dasboard Menu
    //                 </div>
    //                 <div className="container dasboard-container">
    //                     {/* dashboard-title end */}
    //                     {/* dasboard-wrapper*/}
    //                     <div className="dasboard-wrapper fl-wrap no-pag">
    //                         <div className="row">
    //                             <div className="col-md-7">
    //                                 <div className="dasboard-widget-title fl-wrap">
    //                                     <h5>
    //                                         <i className="fa-solid fa-user-circle" />
    //                                         Change Avatar
    //                                     </h5>
    //                                 </div>
    //                                 <div className="dasboard-widget-box nopad-dash-widget-box fl-wrap">
    //                                     <div className="edit-profile-photo">
    //                                         <img src="https://hauzzi.webkype.net/images/avatar/5.jpg" className="respimg" alt />
    //                                         <div className="change-photo-btn">
    //                                             <div className="photoUpload">
    //                                                 <span> Upload New Photo</span>
    //                                                 <input type="file" className="upload" />
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                     <div className="bg-wrap bg-parallax-wrap-gradien">
    //                                         <div className="bg" data-bg="https://hauzzi.webkype.net/images/bg/3.jpg" />
    //                                     </div>
    //                                     <div className="change-photo-btn cpb-2  ">
    //                                         <div className="photoUpload color-bg">
    //                                             <span>
    //                                                 {" "}
    //                                                 <i className="fa-solid fa-camera" /> Change Cover{" "}
    //                                             </span>
    //                                             <input type="file" className="upload" />
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                                 <div className="dasboard-widget-title fl-wrap">
    //                                     <h5>
    //                                         <i className="fa-solid fa-key" />
    //                                         Personal Info
    //                                     </h5>
    //                                 </div>
    //                                 <div className="dasboard-widget-box fl-wrap">
    //                                     <div className="custom-form">
    //                                         <label>
    //                                             First Name{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-user" />
    //                                             </span>
    //                                         </label>
    //                                         <input type="text" placeholder="Alica" defaultValue />
    //                                         <label>
    //                                             Second Name{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-user" />
    //                                             </span>
    //                                         </label>
    //                                         <input type="text" placeholder="Noory" defaultValue />
    //                                         <label>
    //                                             Email Address{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-envelope" />
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="AlicaNoory@domain.com"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Phone
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-phone" />{" "}
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="+7(123)987654"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Adress{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-map-marker" />{" "}
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="USA 27TH Brooklyn NY"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Website{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-globe" />{" "}
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="themeforest.net"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Agensy
    //                                             <span className="dec-icon">
    //                                                 <i className="fa-solid fa-home-lg-alt" />{" "}
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="Mavers RealEstate Agency"
    //                                             defaultValue
    //                                         />
    //                                         <label>Notes </label>
    //                                         <textarea
    //                                             cols={40}
    //                                             rows={3}
    //                                             placeholder="About Me"
    //                                             style={{ marginBottom: 20 }}
    //                                             defaultValue={""}
    //                                         />
    //                                     </div>
    //                                     <button className="btn color-bg float-btn m-0">
    //                                         Save Changes
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                             <div className="col-md-5">
    //                                 <div className="dasboard-widget-title dbt-mm fl-wrap">
    //                                     <h5>
    //                                         <i className="fa-solid fa-key" />
    //                                         Change Password
    //                                     </h5>
    //                                 </div>
    //                                 <div className="dasboard-widget-box fl-wrap">
    //                                     <div className="custom-form">
    //                                         <div className="pass-input-wrap fl-wrap">
    //                                             <label>
    //                                                 Current Password
    //                                                 <span className="dec-icon">
    //                                                     <i className="fa-solid fa-lock-open-alt" />
    //                                                 </span>
    //                                             </label>
    //                                             <input
    //                                                 type="password"
    //                                                 className="pass-input"
    //                                                 placeholder
    //                                                 defaultValue
    //                                             />
    //                                             <span className="eye">
    //                                                 <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
    //                                             </span>
    //                                         </div>
    //                                         <div className="pass-input-wrap fl-wrap">
    //                                             <label>
    //                                                 New Password
    //                                                 <span className="dec-icon">
    //                                                     <i className="fa-solid fa-lock-alt" />
    //                                                 </span>
    //                                             </label>
    //                                             <input
    //                                                 type="password"
    //                                                 className="pass-input"
    //                                                 placeholder
    //                                                 defaultValue
    //                                             />
    //                                             <span className="eye">
    //                                                 <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
    //                                             </span>
    //                                         </div>
    //                                         <div className="pass-input-wrap fl-wrap">
    //                                             <label>
    //                                                 Confirm New Password
    //                                                 <span className="dec-icon">
    //                                                     <i className="fa-solid fa-shield-check" />{" "}
    //                                                 </span>
    //                                             </label>
    //                                             <input
    //                                                 type="password"
    //                                                 className="pass-input"
    //                                                 placeholder
    //                                                 defaultValue
    //                                             />
    //                                             <span className="eye">
    //                                                 <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
    //                                             </span>
    //                                         </div>
    //                                         <button className="btn    color-bg  float-btn">
    //                                             Save Changes
    //                                         </button>
    //                                     </div>
    //                                 </div>
    //                                 <div
    //                                     className="dasboard-widget-title fl-wrap"
    //                                     style={{ marginTop: 30 }}
    //                                 >
    //                                     <h5>
    //                                         <i className="fa-solid fa-share-alt" />
    //                                         Your Socials
    //                                     </h5>
    //                                 </div>
    //                                 <div className="dasboard-widget-box fl-wrap">
    //                                     <div className="custom-form">
    //                                         <label>
    //                                             Facebook{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fab fa-facebook" />
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="https://www.facebook.com/"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Twitter{" "}
    //                                             <span className="dec-icon">
    //                                                 <i className="fab fa-twitter" />
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="https://twitter.com/"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Instagram
    //                                             <span className="dec-icon">
    //                                                 <i className="fab fa-instagram" />
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="https://www.instagram.com/"
    //                                             defaultValue
    //                                         />
    //                                         <label>
    //                                             Vkontakte
    //                                             <span className="dec-icon">
    //                                                 <i className="fab fa-vk" />
    //                                             </span>
    //                                         </label>
    //                                         <input
    //                                             type="text"
    //                                             placeholder="https://vk.com/"
    //                                             defaultValue
    //                                         />
    //                                     </div>
    //                                     <button className="btn color-bg float-btn">
    //                                         Save Changes
    //                                     </button>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     {/* dasboard-wrapper end */}
    //                 </div>
    //                 {/* dashboard-footer */}
    //                 <div className="dashboard-footer">
    //                     <div className="dashboard-footer-links fl-wrap">
    //                         <span>Helpfull Links:</span>
    //                         <ul>
    //                             <li>
    //                                 <a href="about.html">About</a>
    //                             </li>
    //                             <li>
    //                                 <a href="blog.html">Blog</a>
    //                             </li>
    //                             <li>
    //                                 <a href="pricing.html">Pricing Plans</a>
    //                             </li>
    //                             <li>
    //                                 <a href="contacts.html">Contacts</a>
    //                             </li>
    //                             <li>
    //                                 <a href="help.html">Help Center</a>
    //                             </li>
    //                         </ul>
    //                     </div>
    //                     <a href="#main" className="dashbord-totop  custom-scroll-link">
    //                         <i className="fa-solid fa-caret-up" />
    //                     </a>
    //                 </div>
    //                 {/* dashboard-footer end */}
    //             </div>
    //             {/* content end */}
    //             <div className="dashbard-bg gray-bg" />
    //         </div>
    //         {/* wrapper end */}
    //     </div>
    //     {/* Main end */}
    //     {/*=============== scripts  ===============*/}
    // </div>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              {/* dasboard-wrapper*/}
              <div className="dasboard-wrapper fl-wrap no-pag">
                <div className="row">
                  <div className="col-md-7">
                    <div className="dasboard-widget-title fl-wrap">
                      {" "}
                      <h5>
                        <i className="fa-solid fa-user-circle" />
                        Change Avatar{" "}
                      </h5>{" "}
                    </div>{" "}
                    <div className="dasboard-widget-box nopad-dash-widget-box fl-wrap">
                      <div className="edit-profile-photo">
                        {" "}
                        <img
                          src="https://hauzzi.webkype.net/images/avatar/5.jpg"
                          className="respimg"
                          alt
                        />{" "}
                        <div className="change-photo-btn">
                          {" "}
                          <div className="photoUpload">
                            <span> Upload New Photo</span>
                            <input type="file" className="upload" />{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="bg-wrap bg-parallax-wrap-gradien">
                        <div
                          className="bg"
                          data-bg="https://hauzzi.webkype.net/images/bg/3.jpg"
                        />{" "}
                      </div>{" "}
                      <div className="change-photo-btn cpb-2  ">
                        {" "}
                        <div className="photoUpload color-bg">
                          {" "}
                          <span>
                            <i className="fa-solid fa-edit" /> Change Cover{" "}
                          </span>
                          <input type="file" className="upload" />{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                    <div className="dasboard-widget-title fl-wrap">
                      {" "}
                      <h5>
                        <i className="fa-solid fa-key" />
                        Personal Info{" "}
                      </h5>{" "}
                    </div>{" "}
                    <div className="dasboard-widget-box fl-wrap">
                      {" "}
                      <div className="custom-form">
                        {" "}
                        <label>
                          First Name{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-user" />{" "}
                          </span>{" "}
                        </label>{" "}
                        <input
                          type="text"
                          placeholder="Alica"
                          defaultValue
                          style={{ width: "100%" }}
                        />{" "}
                        <label>
                          Second Name{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-user" />{" "}
                          </span>{" "}
                        </label>{" "}
                        <input type="text" placeholder="Noory" defaultValue />{" "}
                        <label>
                          Email Address{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-envelope" />{" "}
                          </span>{" "}
                        </label>{" "}
                        <input
                          type="text"
                          placeholder="AlicaNoory@domain.com"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Phone
                          <span className="dec-icon">
                            <i className="fa-solid fa-phone" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="+7(123)987654"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Adress{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-map-marker" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="USA 27TH Brooklyn NY"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Website{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-globe" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="themeforest.net"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Agensy
                          <span className="dec-icon">
                            <i className="fa-solid fa-home-lg-alt" />{" "}
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="Mavers RealEstate Agency"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>Notes </label>
                        <textarea
                          cols={40}
                          rows={3}
                          placeholder="About Me"
                          style={{ marginBottom: 20, width: "100%" }}
                          defaultValue={""}
                        />
                      </div>
                      <button className="btn color-bg float-btn m-0">
                        Save Changes
                      </button>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="dasboard-widget-title dbt-mm fl-wrap">
                      <h5>
                        <i className="fa-solid fa-key" />
                        Change Password
                      </h5>
                    </div>
                    <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <div className="pass-input-wrap fl-wrap">
                          <label>
                            Current Password
                            <span className="dec-icon">
                              <i className="fa-solid fa-unlock" />
                            </span>
                          </label>
                          <input
                            type="password"
                            className="pass-input"
                            placeholder
                            defaultValue
                            style={{ width: "100%" }}
                          />
                          <span className="eye">
                            <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
                          </span>
                        </div>
                        <div className="pass-input-wrap fl-wrap">
                          <label>
                            New Password
                            <span className="dec-icon">
                              <i className="fa-solid fa-lock" />
                            </span>
                          </label>
                          <input
                            type="password"
                            className="pass-input"
                            placeholder
                            defaultValue
                            style={{ width: "100%" }}
                          />
                          <span className="eye">
                            <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
                          </span>
                        </div>
                        <div className="pass-input-wrap fl-wrap">
                          <label>
                            Confirm New Password
                            <span className="dec-icon">
                              <i className="fa-solid fa-user-shield" />{" "}
                            </span>
                          </label>
                          <input
                            type="password"
                            className="pass-input"
                            placeholder
                            style={{ width: "100%" }}
                            defaultValue
                          />
                          <span className="eye">
                            <i className="fa-solid fa-eye" aria-hidden="true" />{" "}
                          </span>
                        </div>
                        <button className="btn    color-bg  float-btn">
                          Save Changes
                        </button>
                      </div>
                    </div>
                    {/* <div
                      className="dasboard-widget-title fl-wrap"
                      style={{ marginTop: 30 }}
                    >
                      <h5>
                        <i className="fa-solid fa-share-alt" />
                        Your Socials
                      </h5>
                    </div> */}
                    {/* <div className="dasboard-widget-box fl-wrap">
                      <div className="custom-form">
                        <label>
                          Facebook{" "}
                          <span className="dec-icon">
                            <i className="fab fa-facebook" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://www.facebook.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Twitter{" "}
                          <span className="dec-icon">
                            <i className="fab fa-twitter" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://twitter.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Instagram
                          <span className="dec-icon">
                            <i className="fab fa-instagram" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://www.instagram.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                        <label>
                          Vkontakte
                          <span className="dec-icon">
                            <i className="fab fa-vk" />
                          </span>
                        </label>
                        <input
                          type="text"
                          placeholder="https://vk.com/"
                          defaultValue
                          style={{ width: "100%" }}
                        />
                      </div>
                      <button className="btn color-bg float-btn">
                        Save Changes
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* dasboard-wrapper end */}
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
};

export default Profile;
