import React, { useState, useEffect } from "react";
import CanvasJSReact from "./canvasjs.react"; // Assuming you've downloaded CanvasJSReact from the official website and imported it accordingly

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DynamicChart = () => {
  const [dps, setDps] = useState([]);
  const [xVal, setXVal] = useState(0);
  const [yVal, setYVal] = useState(100);
  const updateInterval = 1000;
  const dataLength = 20;

  useEffect(() => {
    const updateChart = (count) => {
      count = count || 1;

      for (let j = 0; j < count; j++) {
        setYVal(
          (prevYVal) => prevYVal + Math.round(5 + Math.random() * (-5 - 5))
        );
        setDps((prevDps) => [...prevDps, { x: xVal, y: yVal }]);
        setXVal((prevXVal) => prevXVal + 1);
      }

      if (dps.length > dataLength) {
        setDps((prevDps) => prevDps.slice(1));
      }
    };

    const interval = setInterval(() => {
      updateChart();
    }, updateInterval);

    return () => clearInterval(interval);
  }, [dps, xVal, yVal]);

  const options = {
    title: {
      text: "Hauzzi Data",
    },
    data: [
      {
        type: "line",
        dataPoints: dps,
      },
    ],
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default DynamicChart;
