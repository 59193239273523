import React, { useEffect, useState } from "react";
import cc from "..//..//assets/cc.jpg";
import { FaRegCalendarCheck } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faMessage,
  faReply,
  faThumbsUp,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import userEvent from "@testing-library/user-event";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
const apiKey = process.env.REACT_APP_API_KEY;

console.log(apiUrl);

const Review = () => {
  // const [enqData, setEnqData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  // const [data, setdata] = useState([]);
  // const [profileData, setProfileData] = useState({});
  const [data, setdata] = useState([]);
  useEffect(() => {
    const reviewData = async () => {
      try {
        const response = await fetch(`${apiUrl}/review/getReview`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log("Data Fetch Successfully...", resData.message);
          setReviewData(resData.data);
        } else {
          console.log("Failed To Get Data");
        }
      } catch (error) {
        throw new Error("Failed to Fetch Data", error.message);
      }
    };
    reviewData();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=0`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData.data);
          setdata(resData.data);
          // if (userTypeId === 0 || userTypeId === 1 || userTypeId === 2) {
          //   // console.log(...resData.data);
          //   // alldata.push(...resData.data);
          // }
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    // async function fetchData() {
    //   await Promise.all([getData(0), getData(1), getData(2)]);

    //   console.log("All data fetched:", alldata);
    //   setdata(alldata);
    // }

    getData();
  }, []);

  console.log(data);
  console.log(reviewData);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }

  // useEffect(() => {
  //   async function GetVerifyData() {
  //     try {
  //       const response = await fetch(`${apiUrl}/employee/verify-customer`, {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${Token}`,
  //         },
  //       });
  //       const resData = await response.json();

  //       if (resData.status !== "error") {
  //         setProfileData(resData.data);
  //         console.log(resData.data);
  //       }
  //     } catch (error) {
  //       throw new Error("Failed to Post Data", error.message);
  //     }
  //   }
  //   GetVerifyData();
  // }, []);

  // useEffect(() => {
  //   async function getData() {
  //     try {
  //       const response = await fetch(
  //         `${apiUrl}/profile/getAll?userType=${userTypeId}`,
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${apiKey}`,
  //           },
  //         }
  //       );
  //       const resData = await response.json();
  //       if (resData.status === "success") {
  //         console.log(resData);
  //         setdata(resData.data);
  //       }
  //     } catch (error) {
  //       throw new Error("Failed to fetch Data ", error.message);
  //     }
  //   }
  //   getData();
  // }, []);
  // console.log(data);

  // console.log(reviewData);

  // useEffect(() => {
  //   async function getTableData() {
  //     try {
  //       const response1 = await fetch(
  //         "https://pwipapi.webkype.co/enquiry/getAll",
  //         {
  //           method: "GET",
  //           headers: {
  //             Authorization: `Bearer ${Token}`,
  //           },
  //         }
  //       );
  //       const resData1 = await response1.json();

  //       if (resData1 && Array.isArray(resData1.data)) {
  //         setEnqData(resData1.data);
  //       } else {
  //         console.log("Response does not contain any array data", resData1);
  //       }
  //     } catch (error) {
  //       throw new Error("Failed to fetch Data", error.message);
  //     }
  //   }
  //   getTableData();
  // }, []);

  // useEffect(() => {
  //   fetch("https://pwipapi.webkype.co/enquiry/getAll", {
  //     method: "GET",
  //     headers: {
  //       Authorization: `Bearer ${Token}`,
  //     },
  //   })
  //     .then((response1) => response1.json())
  //     .then((resData1) => setEnqData(resData1.data));

  //   // setEnqData(resData1););
  // }, []);

  return (
    // <div>
    //   <div id="wrapper">
    //     <div className="dashboard-content pt-0">
    //       <div className="dashboard-menu-btn color-bg">
    //         <span>
    //           <i className="fas fa-bars"></i>
    //         </span>
    //         Dasboard Menu
    //       </div>
    //       <div className="container dasboard-container">
    //         <div className="dasboard-wrapper fl-wrap">
    //           <div className="dasboard-widget-title fl-wrap">
    //             <h5>
    //               <FontAwesomeIcon
    //                 icon={faMessage}
    //                 style={{ color: "black" }}
    //               />
    //               Last Reviews <span> ( +2 New ) </span>
    //             </h5>
    //             <a
    //               href="/#"
    //               className="mark-btn  tolt"
    //               data-microtip-position="bottom"
    //               data-tooltip="Mark all as read"
    //             >
    //               <FontAwesomeIcon icon={faMessage} />{" "}
    //             </a>
    //           </div>
    //           <div className="dasboard-widget-box fl-wrap">
    //             <div className="dasboard-opt fl-wrap">
    //               <div className="price-opt">
    //                 <span className="price-opt-title">Sort by:</span>
    //                 <div className="listsearch-input-item">
    //                   <select
    //                     data-placeholder="Lastes"
    //                     className="chosen-select no-search-select"
    //                     style={{ display: " none" }}
    //                   >
    //                     <option>Lastes</option>
    //                     <option>Oldes</option>
    //                     <option>Average rating</option>
    //                   </select>
    //                   <div
    //                     className="nice-select chosen-select no-search-select"
    //                     tabindex="0"
    //                   >
    //                     <span className="current">Lastes</span>
    //                     <div className="nice-select-search-box">
    //                       <input
    //                         type="text"
    //                         className="nice-select-search"
    //                         placeholder="Search..."
    //                       />
    //                     </div>
    //                     <ul className="list">
    //                       <li data-value="Lastes" className="option selected">
    //                         Lastes
    //                       </li>
    //                       <li data-value="Oldes" className="option">
    //                         Oldes
    //                       </li>
    //                       <li data-value="Average rating" className="option">
    //                         Average rating
    //                       </li>
    //                     </ul>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>

    //             {enqData.length > 0 &&
    //               reviewData.map((item, index) => (
    //                 <div className="reviews-comments-item">
    //                   <div className="review-comments-avatar">
    //                     <img src={cc} alt="" />
    //                     <div className="review-notifer">New</div>
    //                   </div>
    //                   <div className="reviews-comments-item-text smpar">
    //                     <div className="box-widget-menu-btn smact">
    //                       <FontAwesomeIcon icon={faEllipsis} />
    //                     </div>
    //                     <div className="show-more-snopt-tooltip bxwt">
    //                       <a href="/#">
    //                         {" "}
    //                         <FontAwesomeIcon icon={faReply} /> Reply
    //                       </a>
    //                       <a href="/#">
    //                         {" "}
    //                         <FontAwesomeIcon
    //                           icon={faTriangleExclamation}
    //                         />{" "}
    //                         Report{" "}
    //                       </a>
    //                     </div>
    //                     {enqData[index] && <h4>{enqData[index].name}</h4>}

    //                     <div
    //                       className="listing-rating card-popup-rainingvis"
    //                       data-starrating2="3"
    //                     >
    //                       <i className="fas fa-star"></i>
    //                       <i className="fas fa-star"></i>
    //                       <i className="fas fa-star"></i>
    //                       <span className="re_stars-title">Average</span>
    //                       <div className="card-popup-rainingvis_bg">
    //                         <span className="card-popup-rainingvis_bg_item"></span>
    //                         <span className="card-popup-rainingvis_bg_item"></span>
    //                         <span className="card-popup-rainingvis_bg_item"></span>
    //                         <span className="card-popup-rainingvis_bg_item"></span>
    //                         <span className="card-popup-rainingvis_bg_item"></span>
    //                         <div></div>
    //                       </div>
    //                     </div>
    //                     <div className="clearfix"></div>
    //                     <p>
    //                       {/* " Donec quam felis, ultricies nec, pellentesque eu,
    //                      pretium quis, sem. Nulla consequat massa quis enim. Donec
    //                      pede justo, fringilla vel, aliquet nec, vulputate eget,
    //                      arcu. In enim justo, rhoncus ut, imperdiet a, venenatis
    //                      vitae, justo. Nullam dictum felis eu pede mollis pretium.
    //                      " */}
    //                       {item.content}
    //                     </p>
    //                     <div className="reviews-comments-item-date">
    //                       {enqData[index] && (
    //                         <span className="reviews-comments-item-date-item">
    //                           <FaRegCalendarCheck style={{ color: "black" }} />{" "}
    //                           {enqData[index].email}
    //                         </span>
    //                       )}
    //                       <a href="/#" className="rate-review">
    //                         <FontAwesomeIcon icon={faThumbsUp} /> Helpful Review{" "}
    //                         <span>6</span>{" "}
    //                       </a>
    //                     </div>
    //                   </div>
    //                 </div>
    //               ))}

    //             {/* <div className="reviews-comments-item-text smpar">
    //               <div className="box-widget-menu-btn smact">
    //                 <FontAwesomeIcon icon={faEllipsis} />
    //               </div>
    //               <div className="show-more-snopt-tooltip bxwt">
    //                 <a href="/#">
    //                   {" "}
    //                   <FontAwesomeIcon icon={faReply} /> Reply
    //                 </a>
    //                 <a href="/#">
    //                   {" "}
    //                   <FontAwesomeIcon
    //                     icon={faTriangleExclamation}
    //                   /> Report{" "}
    //                 </a>
    //               </div>
    //               <h4>
    //                 <a href="/#">
    //                   Liza Rose <span>For Luxury Family Home </span>
    //                 </a>
    //               </h4>
    //               <div
    //                 className="listing-rating card-popup-rainingvis"
    //                 data-starrating2="3"
    //               >
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <i className="fas fa-star"></i>
    //                 <span className="re_stars-title">Average</span>
    //                 <div className="card-popup-rainingvis_bg">
    //                   <span className="card-popup-rainingvis_bg_item"></span>
    //                   <span className="card-popup-rainingvis_bg_item"></span>
    //                   <span className="card-popup-rainingvis_bg_item"></span>
    //                   <span className="card-popup-rainingvis_bg_item"></span>
    //                   <span className="card-popup-rainingvis_bg_item"></span>
    //                   <div></div>
    //                 </div>
    //               </div>
    //               <div className="clearfix"></div>
    //               <p>
    //                 " Donec quam felis, ultricies nec, pellentesque eu, pretium
    //                 quis, sem. Nulla consequat massa quis enim. Donec pede
    //                 justo, fringilla vel, aliquet nec, vulputate eget, arcu. In
    //                 enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
    //                 Nullam dictum felis eu pede mollis pretium. "
    //               </p>
    //               <div className="reviews-comments-item-date">
    //                 <span className="reviews-comments-item-date-item">
    //                   <FaRegCalendarCheck style={{ color: "black" }} />
    //                   12 April 2020
    //                 </span>
    //                 <a href="/#" className="rate-review">
    //                   <FontAwesomeIcon icon={faThumbsUp} /> Helpful Review{" "}
    //                   <span>6</span>{" "}
    //                 </a>
    //               </div>
    //             </div> */}
    //           </div>
    //           {/* <!-- pagination--> */}
    //           <div className="pagination float-pagination">
    //             <a href="/#" className="prevposts-link">
    //               <i className="fa fa-caret-left"></i>
    //             </a>
    //             <a href="/#">1</a>
    //             <a href="/#" className="current-page">
    //               2
    //             </a>
    //             <a href="/#">3</a>
    //             <a href="/#">4</a>
    //             <a href="/#" className="nextposts-link">
    //               <i className="fa fa-caret-right"></i>
    //             </a>
    //           </div>
    //           {/* <!-- pagination end-->	 */}
    //         </div>
    //       </div>
    //       {/* <!-- dashboard-footer --> */}
    //       <div className="dashboard-footer">
    //         <div className="dashboard-footer-links fl-wrap">
    //           <span>Helpfull Links:</span>
    //           <ul>
    //             <li>
    //               <a href="about.html">About </a>
    //             </li>
    //             <li>
    //               <a href="blog.html">Blog</a>
    //             </li>
    //             <li>
    //               <a href="pricing.html">Pricing Plans</a>
    //             </li>
    //             <li>
    //               <a href="contacts.html">Contacts</a>
    //             </li>
    //             <li>
    //               <a href="help.html">Help Center</a>
    //             </li>
    //           </ul>
    //         </div>
    //         <a href="#main" className="dashbord-totop  custom-scroll-link">
    //           <i className="fas fa-caret-up"></i>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link rel="shortcut icon" href="images/favicon.ico" />
      {/*loader*/}
      {/* <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fas fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-widget-title fl-wrap">
                  <h5>
                    {/* <FontAwesomeIcon
                      icon={faMessage}
                      style={{ color: "black" }}
                    /> */}
                    <i className="fa-solid fa-message" />
                    Last Reviews <span> ( +2 New ) </span>
                  </h5>
                  <a
                    href="/#"
                    className="mark-btn  tolt"
                    data-microtip-position="bottom"
                    data-tooltip="Mark all as read"
                  >
                    <FontAwesomeIcon icon={faMessage} />{" "}
                  </a>
                </div>
                <div className="dasboard-widget-box fl-wrap">
                  <div className="dasboard-opt fl-wrap">
                    {/* <div className="price-opt">
                      <span className="price-opt-title">Sort by:</span>
                      <div className="listsearch-input-item">
                        <select
                          data-placeholder="Lastes"
                          className="chosen-select no-search-select"
                          style={{ display: " none" }}
                        >
                          <option>Lastes</option>
                          <option>Oldes</option>
                          <option>Average rating</option>
                        </select>
                        <div
                          className="nice-select chosen-select no-search-select"
                          tabindex="0"
                        >
                          <span className="current">Lastes</span>
                          <div className="nice-select-search-box">
                            <input
                              type="text"
                              className="nice-select-search"
                              placeholder="Search..."
                            />
                          </div>
                          <ul className="list">
                            <li data-value="Lastes" className="option selected">
                              Lastes
                            </li>
                            <li data-value="Oldes" className="option">
                              Oldes
                            </li>
                            <li data-value="Average rating" className="option">
                              Average rating
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>

                  {reviewData.length > 0 &&
                    reviewData.map((item, index) => (
                      <div className="reviews-comments-item " key={index}>
                        <div className="review-comments-avatar">
                          <img src={cc} alt="" />
                          <div className="review-notifer">
                            {data.length && data[index]?.name}
                          </div>
                        </div>
                        <div className="reviews-comments-item-text smpar">
                          <div className="box-widget-menu-btn smact">
                            <FontAwesomeIcon icon={faEllipsis} />
                          </div>
                          <div className="show-more-snopt-tooltip bxwt">
                            <a href="/#">
                              {" "}
                              <FontAwesomeIcon icon={faReply} /> Reply
                            </a>
                            <a href="/#">
                              {" "}
                              <FontAwesomeIcon
                                icon={faTriangleExclamation}
                              />{" "}
                              Report{" "}
                            </a>
                          </div>

                          <h4
                            style={{
                              marginTop: "5px",
                              paddingLeft: "5px",
                            }}
                          >
                            {/* {data.length &&
                              data.filter((itemdata) => {
                                return itemdata.id === item.customerId;
                              })[0].name}{" "} */}
                            <span style={{ color: "#000" }}>
                              {/* {data.length && data[index].name} */}
                              {data.length && data[index]?.name}
                            </span>
                            {"  "}
                            Reviewed To :{" "}
                            <span style={{ color: "#000" }}>
                              {item.propertyId != null
                                ? item.property.name
                                : item.agentId != null
                                ? item.property.postedBy
                                : item.property.postedBy
                                ? item.property.postedBy
                                : item.property.name}
                            </span>
                          </h4>
                          <div
                            className="listing-rating card-popup-rainingvis"
                            data-starrating2="3"
                          >
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <i className="fa-solid fa-star"></i>
                            <span className="re_stars-title">{}</span>
                            <div className="card-popup-rainingvis_bg">
                              <span className="card-popup-rainingvis_bg_item"></span>
                              <span className="card-popup-rainingvis_bg_item"></span>
                              <span className="card-popup-rainingvis_bg_item"></span>
                              <span className="card-popup-rainingvis_bg_item"></span>
                              <span className="card-popup-rainingvis_bg_item"></span>
                              <div></div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <p>{item.content}</p>
                          <div className="reviews-comments-item-date">
                            <span
                              class="reviews-comments-item-date-item"
                              style={{ marginRight: "7px" }}
                            >
                              <i class="fa-solid fa-calendar-check"></i>
                              {formatDate(item.createdAt)}
                            </span>
                            {"   "}
                            <span class="reviews-comments-item-date-item">
                              <i class="fa-solid fa-envelope"></i>
                              Email:{" "}
                              {/* {data.length &&
                                data.filter((itemdata) => {
                                  return itemdata.id === item.customerId;
                                })[0].email}{" "} */}
                              {data.length && data[index]?.email}
                            </span>
                            <a href="/#" className="rate-review">
                              {item.propertyId !== null
                                ? "Property"
                                : item.agentId !== null
                                ? "Agent"
                                : "Agency"}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}

                  {/* <div className="reviews-comments-item-text smpar">
                  <div className="box-widget-menu-btn smact">
                    <FontAwesomeIcon icon={faEllipsis} />
                  </div>
                  <div className="show-more-snopt-tooltip bxwt">
                    <a href="/#">
                      {" "}
                      <FontAwesomeIcon icon={faReply} /> Reply
                    </a>
                    <a href="/#">
                      {" "}
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                      /> Report{" "}
                    </a>
                  </div>
                  <h4>
                    <a href="/#">
                      Liza Rose <span>For Luxury Family Home </span>
                    </a>
                  </h4>
                  <div
                    className="listing-rating card-popup-rainingvis"
                    data-starrating2="3"
                  >
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <span className="re_stars-title">Average</span>
                    <div className="card-popup-rainingvis_bg">
                      <span className="card-popup-rainingvis_bg_item"></span>
                      <span className="card-popup-rainingvis_bg_item"></span>
                      <span className="card-popup-rainingvis_bg_item"></span>
                      <span className="card-popup-rainingvis_bg_item"></span>
                      <span className="card-popup-rainingvis_bg_item"></span>
                      <div></div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <p>
                    " Donec quam felis, ultricies nec, pellentesque eu, pretium
                    quis, sem. Nulla consequat massa quis enim. Donec pede
                    justo, fringilla vel, aliquet nec, vulputate eget, arcu. In
                    enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
                    Nullam dictum felis eu pede mollis pretium. "
                  </p>
                  <div className="reviews-comments-item-date">
                    <span className="reviews-comments-item-date-item">
                      <FaRegCalendarCheck style={{ color: "black" }} />
                      12 April 2020
                    </span>
                    <a href="/#" className="rate-review">
                      <FontAwesomeIcon icon={faThumbsUp} /> Helpful Review{" "}
                      <span>6</span>{" "}
                    </a>
                  </div>
                </div> */}
                </div>
                {/* <!-- pagination--> */}
                {/* <div className="pagination float-pagination">
                  <a href="/#" className="prevposts-link">
                    <i className="fa fa-caret-left"></i>
                  </a>
                  <a href="/#">1</a>
                  <a href="/#" className="current-page">
                    2
                  </a>
                  <a href="/#">3</a>
                  <a href="/#">4</a>
                  <a href="/#" className="nextposts-link">
                    <i className="fa fa-caret-right"></i>
                  </a>
                </div> */}
                {/* <!-- pagination end-->	 */}
              </div>
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
};

export default Review;
