import React from "react";
import { useState, useEffect } from "react";
import ReactStarRatings from "react-star-ratings";

const apiUrl = process.env.REACT_APP_URL;

const apiKey = process.env.REACT_APP_API_KEY;

function Agency() {
  const [data, setdata] = useState([]);
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/testimonial/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  console.log(data.length);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}

                            {/*box-widget end */}
                            {/*box-widget*/}
                            <div className="box-widget fl-wrap">
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  <a
                                    href="add-testimonials"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Add Testimonials
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  <span>Testimonials List </span>
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">
                                    Sort by:
                                  </span>
                                  <div className="listsearch-input-item">
                                    <select
                                      data-placeholder="Popularity"
                                      className="chosen-select no-search-select"
                                    >
                                      <option>Popularity</option>
                                      <option>Average rating</option>
                                      <option>Name: A-Z</option>
                                      <option>Name: Z-A</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ display: "inline-grid" }}
                                >
                                  {data.length &&
                                    data.map((item, index) => (
                                      <div
                                        className="listing-item"
                                        key={index}
                                        style={{ width: "100%" }}
                                      >
                                        <article className="geodir-category-listing fl-wrap">
                                          <div className="geodir-category-img fl-wrap">
                                            <a
                                              href="#"
                                              className="geodir-category-img_item"
                                            >
                                              <img
                                                style={{
                                                  height: "128px",
                                                  width: "100px",
                                                }}
                                                key={index}
                                                src={`${apiUrl}/uploads/${item.photo}`}
                                                alt
                                              />
                                              {/* {item.agencyDetails.map(
                                                (agencyItem, index) => (
                                                  <img
                                                    key={index}
                                                    src={`${apiUrl}/uploads/${agencyItem.photo}`}
                                                    alt
                                                  />
                                                )
                                              )} */}
                                              {/* {item.length !== 0 ? (
                                                item.agencyDetails.map(
                                                  (userItem, index) => (
                                                    <img
                                                      style={{
                                                        height: "128px",
                                                        width: "100px",
                                                      }}
                                                      key={index}
                                                      src={
                                                        userItem.photo == ""
                                                          ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                          : `${apiUrl}/uploads/${userItem.photo}`
                                                      }
                                                      alt
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <img
                                                  style={{
                                                    height: "128px",
                                                    width: "100px",
                                                  }}
                                                  key={index}
                                                  src={
                                                    "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                  }
                                                  alt
                                                />
                                              )} */}
                                            </a>
                                          </div>
                                          <div className="geodir-category-content fl-wrap">
                                            {/* <div
                                              className="card-verified tolt"
                                              data-microtip-position="left"
                                              data-tooltip="Verified"
                                            >
                                              <a
                                                className="btn float-btn color-bg small-btn"
                                                style={{
                                                  marginTop: "0",
                                                  height: "35px",
                                                }}
                                              >
                                                CRM
                                              </a>
                                            </div> */}
                                            <div
                                              className="agent_card-title fl-wrap"
                                              style={{
                                                paddingTop: "0",
                                                marginBottom: "0",
                                                height: "20px",
                                              }}
                                            >
                                              <h4>{item.name},</h4>
                                              <div className="geodir-category-location fl-wrap">
                                                {/* {item.agencyDetails.map(
                                                  (agencyItem, index) => (
                                                    <a key={index} href="#">
                                                      <i className="fa-solid fa-map-marker-alt" />{" "}
                                                      {agencyItem.address}
                                                    </a>
                                                  )
                                                )} */}

                                                {/* <div
                                                  className="listing-rating card-popup-rainingvis"
                                                  data-starrating2={4}
                                                >
                                                  <span className="re_stars-title">
                                                    Average
                                                  </span>
                                                </div> */}
                                              </div>
                                            </div>
                                            <div>
                                              <ReactStarRatings
                                                rating={3} // Initial rating value
                                                starRatedColor="#ffc107" // Color of filled stars
                                                starEmptyColor="#e4e5e9" // Color of empty stars
                                                changeRating={(newRating) =>
                                                  console.log(newRating)
                                                } // Callback function for when the rating changes
                                                numberOfStars={5} // Total number of stars
                                                name="rating" // Name of the rating input field (for accessibility)
                                                starDimension="15px"
                                                style={{ left: "-220px" }}
                                                // Size of stars
                                              />
                                            </div>
                                            {/* {item.agencyDetails.map(
                                              (agencyItem, index) => (
                                                <p key={index}>
                                                  "{agencyItem.notes}"
                                                </p>
                                              )
                                            )} */}
                                            <p key={index}>
                                              {" "}
                                              {TruncatedParagraph(
                                                item.content,
                                                300
                                              )}
                                            </p>

                                            {/* <div className="small-facts fl-wrap">
                                              <ul>
                                                <li>
                                                  <i className="fa-solid fa-home" />{" "}
                                                  <span>
                                                    <strong>36</strong>Listing
                                                  </span>
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-users-class" />{" "}
                                                  <span>
                                                    <strong>10</strong>Agents
                                                  </span>
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-comment-alt" />{" "}
                                                  <span>
                                                    <strong>56</strong>Reviews
                                                  </span>
                                                </li>
                                              </ul>
                                            </div> */}
                                          </div>
                                        </article>
                                      </div>
                                    ))}
                                </div>
                                {/*  agecy card item */}
                              </div>
                              {/* dashboard-listings-wrap end*/}
                              {/* pagination*/}

                              {/* pagination end*/}
                            </div>
                            {/* col-md 8 end */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-180px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default Agency;
