import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { toast } from "react-toastify";
import "../../index.css";
function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const apiUrl = process.env.REACT_APP_URL;
  console.log(apiUrl);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setError("Please enter both email and password.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/employee/Employeelogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const responseData = await response.json();

      if (responseData.status === "success") {
        console.log("Login successful");

        toast.success("Login successful");
        localStorage.setItem("Token", responseData.data.Token);
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      } else {
        throw new Error(responseData.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    // <div className="page main-signin-wrapper">
    //   <div className="row signpages text-center">
    //     <div className="col-md-12">
    //       <div style={{ marginTop: "10vw" }}>
    //         <div className="row row-sm">
    //           <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-white">
    //             <div className="mt-5 pt-4 p-2 pos-absolute">
    //               <img
    //                 src={logo}
    //                 className="d-lg-none header-brand-img text-start float-start mb-4 error-logo-light"
    //                 alt="logo"
    //               />
    //               <div className="clearfix"></div>
    //               <img
    //                 src={logo}
    //                 className=" mb-0"
    //                 alt="user"
    //                 style={{ width: "50%" }}
    //               />
    //               <h5 className="mt-4 txt-blue">Create Your Account</h5>
    //               <span className="txt-blue tx-13 mb-5 mt-xl-0">
    //                 Signup to create, discover, and connect with the global
    //                 community
    //               </span>
    //             </div>
    //           </div>
    //           <div className="col-lg-6 col-xl-6 col-xs-12 col-sm-12 login_form">
    //             <div
    //               className="card rounded shadow"
    //               style={{ background: "#F0EEEE66", padding: "1em" }}
    //             >
    //               <div className="row row-sm">
    //                 <div className="card-body mt-2 mb-2">
    //                   <form onSubmit={handleLogin}>
    //                     <h5 style={{ fontSize: "2rem", marginBottom: "3rem" }}>
    //                       Sign in to Your Account
    //                     </h5>
    //                     {/* <p style={{fontSize:'1.2rem' , marginLeft:'1rem'}}>
    //                       Sign in to create, discover, and connect with the
    //                       global community
    //                     </p> */}
    //                     <div className="custom-form">
    //                       <label style={{ marginLeft: "25%" }}>
    //                         email or username *
    //                       </label>
    //                       <input
    //                         style={{ height: "30px", marginLeft: "25%" }}
    //                         placeholder="Enter your email"
    //                         type="text"
    //                         value={email}
    //                         onChange={(e) => setEmail(e.target.value)}
    //                       />
    //                     </div>
    //                     {error && <div style={{ color: "red" }}>{error}</div>}
    //                     <div
    //                       className="custom-form"
    //                       style={{ marginTop: "5px" }}
    //                     >
    //                       <label style={{ marginLeft: "25%" }}>
    //                         password *
    //                       </label>
    //                       <input
    //                         style={{ height: "30px", marginLeft: "25%" }}
    //                         placeholder="Enter your password"
    //                         type="password"
    //                         value={password}
    //                         onChange={(e) => setPassword(e.target.value)}
    //                       />
    //                     </div>
    //                     <button
    //                       type="submit"
    //                       className="btn text-white"
    //                       style={{ height: "50px", backgroundColor: "black" }}
    //                     >
    //                       Sign In
    //                     </button>
    //                     <button
    //                       onClick={(e) => {
    //                         navigate("/reset-password");
    //                       }}
    //                       className="btn text-white"
    //                       style={{
    //                         height: "50px",
    //                         backgroundColor: "black",
    //                         marginLeft: "20px",
    //                       }}
    //                     >
    //                       Forgot Password
    //                     </button>
    //                   </form>
    //                   {/* <div className="text-start mt-3 ms-0">
    //                     <div className="mb-1"><a href="forgot.html">Forgot password?</a></div>
    //                     <div>Don't have an account? <Link to="/signup">Register Here</Link></div>
    //                   </div> */}
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
        <div className="loader-inner">
          <svg>
            <defs>
              <filter id="goo">
                <feGaussianBlur
                  in="SourceGraphic"
                  stdDeviation={2}
                  result="blur"
                />
                <feColorMatrix
                  in="blur"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                  result="gooey"
                />
                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
              </filter>
            </defs>
          </svg>
        </div>
      </div> */}
      {/*loader end*/}
      {/* main */}
      <div
        id="main"
        style={{
          opacity: "1",
          backgroundColor: "#f4f4f4",
          backgroundPositionY: "60px",
        }}
      >
        {/* header */}
        {/*register form */}
        <div className="main-register-holder tabs-act" style={{}}>
          <div className="main-register-wrapper  fl-wrap">
            <div className="main-register-header color-bg">
              <div className="main-register-logo fl-wrap">
                <img
                  src="https://hauzzi.webkype.net/images/white-logo.png"
                  alt
                />
                <h4 style={{ color: "#fff", marginTop: 10 }}>Admin Login</h4>
              </div>
              <div
                className="main-register-bg"
                style={{
                  background:
                    "url(https://hauzzi.webkype.net/images/city-bg.png)",
                }}
              >
                <div className="mrb_pin" />
                <div className="mrb_pin mrb_pin2" />
              </div>
              <div className="mrb_dec" />
              <div className="mrb_dec mrb_dec2" />
            </div>
            <div
              className="main-register"
              style={{ paddingTop: "60px", paddingBottom: "60px" }}
            >
              {/*tabs */}
              <div className="tabs-container">
                <div className="tab">
                  {/*tab */}
                  <div id="tab-1" className="tab-content first-tab">
                    <div className="custom-form">
                      <form
                        method="post"
                        name="registerform"
                        onSubmit={handleLogin}
                      >
                        <label>
                          Username or Email Address *{" "}
                          <span className="dec-icon">
                            <i className="fa-solid fa-user" />
                          </span>
                        </label>
                        <input
                          style={{ width: "100%" }}
                          name="email"
                          placeholder="Enter your email"
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {error && <div style={{ color: "red" }}>{error}</div>}
                        <div className="pass-input-wrap fl-wrap">
                          <label>
                            Password *{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-key" />
                            </span>
                          </label>
                          <input
                            style={{ width: "100%" }}
                            name="password"
                            type="password"
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span className="eye">
                            <i className="fa-solid fa-eye" />{" "}
                          </span>
                        </div>
                        <div className="filter-tags">
                          {/* <input id="check-a3" type="checkbox" name="check" />
                          <label htmlFor="check-a3">Remember me</label> */}
                        </div>
                        <div className="clearfix" />
                        <button
                          type="submit"
                          className="log_btn color-bg"
                          href="dashboard.html"
                        >
                          {" "}
                          LogIn{" "}
                        </button>
                        {/* <button
                          onClick={(e) => {
                            navigate("/reset-password");
                          }}
                          className="btn text-white"
                          style={{
                            height: "45px",
                            backgroundColor: "black",
                            marginLeft: "50px",
                            marginTop: "5px",
                          }}
                        >
                          Forgot Password
                        </button> */}
                      </form>
                    </div>
                  </div>
                  {/*tab end */}
                </div>
                {/*tabs end */}
              </div>
            </div>
          </div>
        </div>
        {/*register form end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
}

export default LoginForm;
