import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const AddMasterValue = () => {
  const initialFormData = {
    name: "",
    mastersId: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [masters, setMasters] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [newMasterData, setNewMasterData] = useState([]);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  const Token = localStorage.getItem("Token");
  const apiKey = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMaster`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setMasters(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${apiUrl}/master/getAllMasterData`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setMasterData(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [newMasterData]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/master/addMasterData`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      setNewMasterData(formData);
      setFormData(initialFormData);
      // navigate("/add-master");
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const deletecontent = (id) => {
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/master/deleteMasterData/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          setNewMasterData(...newMasterData);
          // window.location.reload(false)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    // <>

    //     <div className="page" style={{ marginTop: '13rem' }}>
    //         {/* Main Content*/}
    //         <div className="main-content  pt-0">
    //             <div className="main-container container-fluid">
    //                 <div className="inner-body">

    //                     {/* End Row */}
    //                     {/* Row */}
    //                     <div className="row row-sm">
    //                         <div className="col-lg-4 col-md-4">
    //                             <div>
    //                                 <div className="card rounded shadow" style={{ background: '#F0EEEE', height: '30vh', padding: '1em' }}>
    //                                     <div>
    //                                         <h2>
    //                                             Add Master Value
    //                                         </h2>
    //                                         {/* <ol className="breadcrumb">
    //                                             <li className="breadcrumb-item">
    //                                             <h2 style={{ fontSize: '30px', margin: '2rem' }}>Internal Staff </h2>
    //                                             </li>
    //                                             </ol> */}
    //                                     </div>
    //                                     <hr />
    //                                     <div className="row row-sm">
    //                                         <div className="form-group" style={{ justifyContent: 'center' }}>
    //                                             <select
    //                                                 style={{ height: '4vh', display: 'inline' }}
    //                                                 name="mastersId"
    //                                                 value={formData.mastersId}
    //                                                 onChange={handleChange}
    //                                             >
    //                                                 <option>Select Master</option>
    //                                                 {masters.map((department) => (
    //                                                     <option key={department.id} value={department.id}>
    //                                                         {department.name}
    //                                                     </option>
    //                                                 ))}
    //                                             </select>
    //                                             <input
    //                                                 type="text"
    //                                                 className="col"
    //                                                 style={{ height: '4vh', display: 'inline', marginLeft: '30px' }}
    //                                                 placeholder="Enter name"
    //                                                 name="name"
    //                                                 value={formData.name}
    //                                                 onChange={handleChange}
    //                                             />
    //                                             <button
    //                                                 className="btn text-white"
    //                                                 onClick={handleSubmit}
    //                                                 style={{ height: '6vh', borderRadius: '10px', backgroundColor: 'black', cursor: 'pointer', marginLeft: '3rem' }}
    //                                             >
    //                                                 Submit
    //                                             </button>
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>
    //                         </div>

    //                         <div className="col-8">
    //                             <div className="table-responsive">
    //                                 <table className="table table-striped table-bordered text-nowrap mb-0 text-center shadow">
    //                                     <thead>
    //                                         <tr>

    //                                             <th>ID</th>
    //                                             <th>Name</th>
    //                                             <th>Master Name</th>
    //                                             <th>Action</th>

    //                                         </tr>
    //                                     </thead>
    //                                     <tbody>
    //                                         {masterData.map((user) => (
    //                                             <tr key={user.id}>
    //                                                 <td>
    //                                                     {user.id}
    //                                                 </td>
    //                                                 <td>

    //                                                     {user.name}
    //                                                     <br />

    //                                                     {/* Include your modal trigger button here */}
    //                                                 </td> <td>

    //                                                     {user.masterName}
    //                                                     <br />

    //                                                     {/* Include your modal trigger button here */}
    //                                                 </td>
    //                                                 <td>
    //                                                     <button
    //                                                         // onClick={() => loadcontent(user.id)}
    //                                                         className="btn-sm rounded"
    //                                                         style={{ backgroundColor: 'black', cursor: 'pointer', marginLeft: '3rem', color: 'white' }}>
    //                                                         Edit
    //                                                     </button>
    //                                                     <button onClick={() => deletecontent(user.id)}
    //                                                         className="btn-sm rounded"
    //                                                         style={{ backgroundColor: 'black', cursor: 'pointer', marginLeft: '3rem', color: 'white' }}>
    //                                                         Delete
    //                                                     </button>
    //                                                 </td>

    //                                             </tr>
    //                                         ))}

    //                                     </tbody>
    //                                 </table>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     {/* End Row */}
    //                     {/* Row */}
    //                 </div>
    //             </div>
    //         </div>

    //     </div>
    // </>
    //   <div>
    //     {/*=============== basic  ===============*/}
    //     <meta charSet="UTF-8" />
    //     <title>Hauzzi Beta Website UI/UX design by Webkype </title>
    //     <meta name="robots" content="index, follow" />
    //     <meta name="keywords" content />
    //     <meta name="description" content />
    //     {/*=============== css  ===============*/}
    //     <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //     <link type="text/css" rel="stylesheet" href="css/style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/color.css" />
    //     {/*=============== favicons ===============*/}
    //     <link rel="shortcut icon" href="images/favicon.ico" />
    //     {/*loader*/}
    //     {/* <div className="loader-wrap">
    //   <div className="loader-inner">
    //     <svg>
    //       <defs>
    //         <filter id="goo">
    //           <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //           <feColorMatrix
    //             in="blur"
    //             values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //             result="gooey"
    //           />
    //           <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //         </filter>
    //       </defs>
    //     </svg>
    //   </div>
    // </div> */}
    //     {/*loader end*/}
    //     {/* main */}
    //     <div id="main">
    //       {/* header */}

    //       {/* header end  */}
    //       {/* header end  */}

    //       {/* wrapper  */}
    //       <div id="wrapper">
    //         {/* dashbard-menu-wrap */}
    //         <div className="dashbard-menu-overlay" />
    //         {/* content */}

    //         <div className="dashboard-content pt-0">
    //           <div className="dashboard-menu-btn color-bg">
    //             <span>
    //               <i className="fas fa-bars" />
    //             </span>
    //             Dasboard Menu{" "}
    //           </div>{" "}
    //           <div className="container dasboard-container">
    //             {/* dashboard-title end */}
    //             <div style={{ width: "100%" }}>
    //               <div style={{ marginTop: "100px" }}>
    //                 <div>
    //                   <div
    //                     //   className="card rounded shadow"
    //                     style={{
    //                       background: "#f6f6f6",
    //                       display: "flex",
    //                       justifyContent: "space-around",
    //                       padding: "1em",

    //                       position: "relative",
    //                       top: "200px !important",
    //                     }}
    //                   >
    //                     <div style={{ fontSize: "24px", fontWeight: "700" }}>
    //                       <h2>Add Master Value</h2>
    //                     </div>

    //                     <div
    //                       className="form-group"
    //                       style={{
    //                         justifyContent: "center",
    //                       }}
    //                     >
    //                       <select
    //                         style={{
    //                           height: "4vh",
    //                           display: "inline",
    //                           padding: "10px",
    //                           borderRadius: "5px",
    //                           border: "2px solid #ccc",
    //                           boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    //                           transition: "box-shadow 0.3s ease",
    //                           outline: "none",
    //                           width: "240px",
    //                           height: "40px",
    //                         }}
    //                       >
    //                         name="mastersId" value={formData.mastersId}
    //                         onChange={handleChange}
    //                         <option>Select Master</option>
    //                         {masters.map((department) => (
    //                           <option key={department.id} value={department.id}>
    //                             {department.name}
    //                           </option>
    //                         ))}
    //                       </select>
    //                     </div>
    //                     <div>
    //                       {" "}
    //                       <input
    //                         type="text"
    //                         className="col"
    //                         style={{
    //                           height: "4vh",
    //                           display: "inline",
    //                           marginLeft: "10px",
    //                           padding: "10px",
    //                           borderRadius: "5px",
    //                           border: "2px solid #ccc",
    //                           boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    //                           transition: "box-shadow 0.3s ease",
    //                           outline: "none",
    //                           width: "240px",
    //                           height: "40px",
    //                         }}
    //                         name="name"
    //                         value={formData.name}
    //                         onChange={handleChange}
    //                       />
    //                     </div>
    //                     <div>
    //                       {" "}
    //                       <button
    //                         className="btn mt-0"
    //                         onClick={handleSubmit}
    //                         style={{
    //                           backgroundColor: "black",
    //                           cursor: "pointer",
    //                           marginLeft: "3rem",
    //                           color: "white",
    //                           height: "6vh",
    //                           marginTop: 0,
    //                           paddingBottom: "27px",
    //                         }}
    //                       >
    //                         Submit
    //                       </button>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               {/* dasboard-wrapper*/}
    //               <div
    //                 className="col-8"
    //                 style={{ width: "100%", marginTop: "40px" }}
    //               >
    //                 {" "}
    //                 <div
    //                   className="table-responsive"
    //                   style={{
    //                     height: "72vh",
    //                     overflow: "scroll",
    //                     scrollbarWidth: "none",
    //                   }}
    //                 >
    //                   <table className="table table-striped table-bordered text-nowrap mb-0 text-center">
    //                     <thead>
    //                       <tr>
    //                         <th>ID</th>
    //                         <th>Name</th>
    //                         <th>Master Name</th>
    //                         <th>Action</th>
    //                       </tr>
    //                     </thead>
    //                     <tbody>
    //                       {masterData.map((user) => (
    //                         <tr key={user.id}>
    //                           <td>{user.id}</td>
    //                           <td>
    //                             {user.name}
    //                             <br />

    //                             {/* Include your modal trigger button here */}
    //                           </td>{" "}
    //                           <td>
    //                             {user.masterName}
    //                             <br />

    //                             {/* Include your modal trigger button here */}
    //                           </td>
    //                           <td>
    //                             <button
    //                               // onClick={() => loadcontent(user.id)}
    //                               className="btn-sm rounded"
    //                               style={{
    //                                 backgroundColor: "black",
    //                                 cursor: "pointer",
    //                                 marginLeft: "3rem",
    //                                 color: "white",
    //                               }}
    //                             >
    //                               Edit
    //                             </button>
    //                             <button
    //                               onClick={() => deletecontent(user.id)}
    //                               className="btn-sm rounded"
    //                               style={{
    //                                 backgroundColor: "black",
    //                                 cursor: "pointer",
    //                                 marginLeft: "3rem",
    //                                 color: "white",
    //                               }}
    //                             >
    //                               Delete
    //                             </button>
    //                           </td>
    //                         </tr>
    //                       ))}
    //                     </tbody>
    //                   </table>
    //                 </div>
    //               </div>
    //               {/* dasboard-wrapper end */}
    //             </div>
    //             {/* dashboard-footer */}
    //             <div
    //               className="dashboard-footer"
    //               style={{ left: "-100px", bottom: "-50px", right: "-100px" }}
    //             >
    //               <div className="dashboard-footer-links fl-wrap">
    //                 <span>Helpfull Links:</span>
    //                 <ul>
    //                   <li>
    //                     <a href="about.html">About</a>
    //                   </li>
    //                   <li>
    //                     <a href="blog.html">Blog</a>
    //                   </li>
    //                   <li>
    //                     <a href="pricing.html">Pricing Plans</a>
    //                   </li>
    //                   <li>
    //                     <a href="contacts.html">Contacts</a>
    //                   </li>
    //                   <li>
    //                     <a href="help.html">Help Center</a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <a href="#main" className="dashbord-totop  custom-scroll-link">
    //                 <i className="fa-solid fa-caret-up" />
    //               </a>
    //             </div>
    //             {/* dashboard-footer end */}
    //           </div>
    //           {/* content end */}
    //           <div className="dashbard-bg gray-bg" />
    //         </div>
    //         {/* wrapper end */}
    //       </div>
    //       {/* Main end */}
    //       {/*=============== scripts  ===============*/}
    //     </div>
    //   </div>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link rel="shortcut icon" href="images/favicon.ico" />
      {/*loader*/}
      {/* <div className="loader-wrap">
<div className="loader-inner">
<svg>
<defs>
<filter id="goo">
<feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
<feColorMatrix
in="blur"
values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
result="gooey"
/>
<feComposite in="SourceGraphic" in2="gooey" operator="atop" />
</filter>
</defs>
</svg>
</div>
</div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* content */}

          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fas fa-bars" />
              </span>
              Dasboard Menu{" "}
            </div>{" "}
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              {/* dasboard-wrapper*/}{" "}
              <div className="dasboard-wrapper fl-wrap no-pag">
                {" "}
                <div className="row">
                  <div className="col-md-4">
                    {/* <div className="list-searh-input-wrap-title fl-wrap">
          <i className="fa-solid fa-sliders-h" />
          <span>Search Agency</span>
        </div> */}

                    {/*box-widget*/}

                    {/*box-widget end */}
                    {/*box-widget*/}
                    <div className="list-searh-input-wrap-title fl-wrap">
                      <i className="fa-solid fa-list" />
                      <span>Add Master Value</span>
                    </div>
                    <div
                      className="block-box fl-wrap search-sb"
                      id="filters-column"
                    >
                      {/* listsearch-input-item */}

                      <div
                        className="listsearch-input-item"
                        style={{ marginTop: "20px" }}
                      >
                        {/* <select
                          style={{
                            height: "4vh",
                            display: "inline",

                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #808080",
                            transition: "box-shadow 0.3s ease",
                            outline: "none  ",
                            width: "345px",
                            height: "50px",
                            color: "#808080",
                            background: "#f5f7fb",
                          }}
                          name="type"
                          value={formData.type}
                          onChange={handleChange}
                        >
                          <option>Select Type</option>
                          <option>Single Select</option>
                          <option>Multiple Select</option>
                        </select> */}
                        <select
                          style={{
                            height: "4vh",
                            display: "inline",

                            padding: "10px",
                            borderRadius: "5px",
                            border: "1px solid #808080",
                            transition: "box-shadow 0.3s ease",
                            outline: "none  ",
                            width: "100%",
                            height: "50px",
                            color: "#808080",
                            background: "#f5f7fb",
                          }}
                          name="mastersId"
                          value={formData.mastersId}
                          onChange={handleChange}
                        >
                          <option>Select Master</option>
                          {masters.map((department) => (
                            <option key={department.id} value={department.id}>
                              {department.name}
                            </option>
                          ))}
                        </select>
                        {/* <input
            type="text"
            onclick="this.select()"
            placeholder="Name , agency..."
            defaultValue
          /> */}
                      </div>
                      <div className="listsearch-input-item">
                        <label> Master Name</label>
                        {/* <input
            type="text"
            onclick="this.select()"
            placeholder="Name , agency..."
            defaultValue
          /> */}
                        <input
                          type="text"
                          className="col"
                          placeholder="Type..."
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          style={{ width: "100%" }}
                        />
                      </div>
                      {/* listsearch-input-item end*/}
                      {/* listsearch-input-item */}

                      {/* listsearch-input-item end*/}
                      {/* listsearch-input-item */}

                      {/* listsearch-input-item end*/}

                      {/* <div className="checkbox-container">
              {masters.map((department, index) => (
                <div key={index} className="checkbox-item">
                  <input
                    id={`check-${department.id}`}
                    type="checkbox"
                    name="masters"
                    value={department.id}
                    onChange={(e) => {
                      let data = linkedMasters;
                      if (e.target.checked) {
                        if (data !== "") {
                          setLinkedMasters(
                            data + "," + e.target.value
                          );
                        } else {
                          setLinkedMasters(e.target.value);
                        }
                      } else {
                        data = data.split(",");
                        data = data.filter(
                          (d) => d !== e.target.value
                        );
                        setLinkedMasters(data.join(","));
                      }
                    }}
                  />
                  <label htmlFor={`check-${department.id}`}>
                    {department.name}
                  </label>
                </div>
              ))}
            </div> */}

                      <div className="msotw_footer">
                        <a
                          onClick={handleSubmit}
                          className="btn small-btn float-btn color-bg"
                        >
                          Add Master
                        </a>
                        {/* <div className="reset-form reset-btn">
            {" "}
            <i className="far fa-sync-alt" /> Reset Filters
          </div> */}
                      </div>
                    </div>
                    {/* <div
            className=" add-list-tags fl-wrap"
            style={{ padding: "0px 80px", paddingRight: 0 }}
          >
            <ul className="fl-wrap no-list-style ds-tg d-flex flex-wrap">
              {masters.map((department) => (
                <li>
                  <input
                    id="check-aaa5"
                    type="checkbox"
                    name="masters"
                    className="m-2"
                    value={department.id}
                    onChange={(e) => {
                      let data = linkedMasters;
                      if (e.target.checked) {
                        if (data !== "") {
                          setLinkedMasters(
                            data + "," + e.target.value
                          );
                        } else {
                          setLinkedMasters(e.target.value);
                        }
                      } else {
                        data = data.split(",");
                        data = data.filter(
                          (d) => d !== e.target.value
                        );
                        setLinkedMasters(data.join(","));
                      }
                    }}
                  />
                  <label htmlFor="check-aaa5">
                    {department.name}
                  </label>
                </li>
              ))}
            </ul>
          </div> */}

                    <div
                      className="box-widget fl-wrap"
                      // style={{ marginTop: "70px" }}
                    >
                      <div className="banner-widget fl-wrap">
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg  "
                            data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                          />
                        </div>
                        <div className="banner-widget_content">
                          <h5>Ideas are easy. Implementation is hard</h5>
                          <a
                            href="blog"
                            className="btn float-btn color-bg small-btn"
                          >
                            Blog Listing
                          </a>
                        </div>
                      </div>
                    </div>
                    {/*box-widget end */}
                  </div>{" "}
                  <div className="col-8" style={{ marginTop: "40px" }}>
                    {" "}
                    <div
                      className="table-responsive"
                      style={{
                        height: "72vh",
                        overflow: "scroll",
                        scrollbarWidth: "none",
                      }}
                    >
                      <table className="table table-striped table-bordered text-nowrap mb-0 text-center">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {masterData.map((user) => (
                            <tr key={user.id}>
                              <td>{user.id}</td>
                              <td>
                                {user.name}
                                <br />

                                {/* Include your modal trigger button here */}
                              </td>{" "}
                              <td>
                                {user.masterName}
                                <br />

                                {/* Include your modal trigger button here */}
                              </td>
                              <td>
                                {/* <button
                                  // onClick={() => loadcontent(user.id)}
                                  className="btn-sm rounded"
                                  style={{
                                    backgroundColor: "black",
                                    cursor: "pointer",
                                    marginLeft: "3rem",
                                    color: "white",
                                  }}
                                >
                                  Edit
                                </button> */}
                                {/* <button
                                  onClick={() => deletecontent(user.id)}
                                  className="btn-sm rounded"
                                  style={{
                                    backgroundColor: "black",
                                    cursor: "pointer",
                                    marginLeft: "3rem",
                                    color: "white",
                                  }}
                                >
                                  Delete
                                </button> */}
                                {/* <a
                                  onClick={() => loadcontent(user.id)}
                                  className="btn-sm rounded"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <i class="fa-solid fa-pen-to-square"></i>
                                </a> */}
                                <a
                                  onClick={() => deletecontent(user.id)}
                                  className="btn-sm rounded"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  <i class="fa-solid fa-trash"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* dasboard-wrapper end */}
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
};

export default AddMasterValue;
