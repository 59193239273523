import React from "react";

import cc from "..//assets/cc.jpg";
import dd from "..//assets/dd.jpg";
import ee from "..//assets/ee.jpg";
import ff from "..//assets/ff.jpg";
import gg from "..//assets/gg.jpg";
import hh from "..//assets/hh.jpg";
import aaa from "..//assets/aaa.jpg";

import { FaRegMessage, FaMessage } from "react-icons/fa6";
// import { FaMapLocation } from "react-icons/fa6";
import { FaChartBar, FaCheckSquare, FaRegHeart } from "react-icons/fa";
// import { FaRegHeart } from "react-icons/fa";
import { LuMessagesSquare } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { BsGraphUpArrow } from "react-icons/bs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import DynamicChart from "./page/DynamicChart";

const apiUrl = process.env.REACT_APP_URL;

const apiKey = process.env.REACT_APP_API_KEY;

console.log(apiUrl);
const Dashboard = () => {
  const [data, setdata] = useState([]);
  const [dataAgent, setdataAgent] = useState([]);
  const [dataAgency, setdataAgency] = useState([]);
  const [dataUser, setdataUser] = useState([]);

  const [enqData, setEnqData] = useState([]);
  const token = localStorage.getItem("Token");
  const [reviewData, setReviewData] = useState([]);
  // const [data, setdata] = useState([]);
  // const [profileData, setProfileData] = useState({});

  useEffect(() => {
    const reviewData = async () => {
      try {
        const response = await fetch(`${apiUrl}/review/getReview`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log("Data Fetch Successfully...", resData.message);
          setReviewData(resData.data);
        } else {
          console.log("Failed To Get Data");
        }
      } catch (error) {
        throw new Error("Failed to Fetch Data", error.message);
      }
    };
    reviewData();
  }, []);
  console.log(reviewData);

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/enquiry/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setEnqData(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);
  console.log(enqData);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/property/property`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=1`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdataAgent(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=2`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdataAgency(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=0`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdataUser(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  console.log(dataUser);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap no-pag">
                  <div className="dashboard-stats-container fl-wrap">
                    <div className="row">
                      {/*dashboard-stats*/}
                      <div className="col-md-3">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-map-marked" />
                          <h4>Property Listings</h4>
                          <div className="dashboard-stats-count">
                            {data.length}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                      {/*dashboard-stats*/}
                      <div className="col-md-3">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-chart-bar" />
                          <h4>Agent Listing </h4>
                          <div className="dashboard-stats-count">
                            {dataAgent.length}
                            {/* <span>
                              (<strong>+356</strong> this week)
                            </span> */}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                      {/*dashboard-stats*/}
                      <div className="col-md-3">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-list" />
                          <h4>Agency Listing</h4>
                          <div className="dashboard-stats-count">
                            {dataAgency.length}
                            {/* <span>
                              (<strong>+12</strong> this week)
                            </span> */}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                      {/*dashboard-stats*/}
                      <div className="col-md-3">
                        <div className="dashboard-stats fl-wrap">
                          <i className="fa-solid fa-user" />
                          <h4>User Listing</h4>
                          <div className="dashboard-stats-count">
                            {dataUser.length}
                            {/* <span>
                              (<strong>+234</strong> this week)
                            </span> */}
                          </div>
                        </div>
                      </div>
                      {/* dashboard-stats end */}
                    </div>
                  </div>
                  <div className="clearfix" />
                  <div className="row">
                    <div className="col-md-8">
                      <div className="notification success-notif  fl-wrap">
                        <p>
                          Your listing <a href="#">Family house in Brooklyn</a>{" "}
                          has been approved!
                        </p>
                        <a className="notification-close" href="#">
                          <i className="fa-solid fa-times" />
                        </a>
                      </div>
                      <div className="dashboard-widget-title fl-wrap">
                        Your Statistic
                      </div>
                      <div className="dasboard-content fl-wrap">
                        {/* chart-wra*/}
                        <div className="chart-wrap   fl-wrap">
                          {/* <div className="chart-header fl-wrap">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Week"
                                className="chosen-select no-search-select"
                              >
                                <option>Week</option>
                                <option>Month</option>
                                <option>Year</option>
                              </select>
                            </div>
                            <div id="myChartLegend" />
                          </div> */}
                          <DynamicChart></DynamicChart>
                        </div>
                        {/*chart-wrap end*/}
                      </div>
                      <div className="dashboard-widget-title fl-wrap">
                        Latest Enquiry
                      </div>
                      <div className="dashboard-list-box  fl-wrap">
                        {/* dashboard-list end*/}
                        {enqData.length &&
                          enqData.slice(0, 3).map((item, index) => (
                            <div className="dashboard-list fl-wrap" key={index}>
                              <div className="dashboard-message">
                                <span className="close-dashboard-item color-bg">
                                  <i className="fa-solid fa-eye" />
                                </span>
                                <div className="main-dashboard-message-icon color-bg">
                                  <i className="fa-solid fa-check" />
                                </div>
                                <div className="main-dashboard-message-text">
                                  <p>
                                    {item.property == null
                                      ? "Loading..."
                                      : `${item.property.name}`}
                                  </p>
                                </div>
                                <div className="main-dashboard-message-time">
                                  <i class="fa-solid fa-calendar-days"></i> Date
                                  of Enquiry : {formatDate(item.timeOfEnquiry)}
                                </div>
                              </div>
                            </div>
                          ))}
                        {/* dashboard-list end*/}
                        {/* dashboard-list end*/}

                        {/* dashboard-list end*/}
                        {/* dashboard-list end*/}

                        {/* dashboard-list end*/}
                      </div>
                    </div>
                    <div className="col-md-4">
                      {/*box-widget*/}
                      <div className="dasboard-widget fl-wrap">
                        <div className="dasboard-widget-title fl-wrap">
                          <h5>
                            <i className="fa-solid fa-comment-alt" />
                            Recent Reviews
                          </h5>
                        </div>
                        <div className="chat-contacts fl-wrap">
                          {/* chat-contacts-item*/}
                          {reviewData.length &&
                            reviewData.slice(0, 5).map((item, index) => (
                              <a
                                className="chat-contacts-item"
                                href="#"
                                key={index}
                              >
                                <div className="dashboard-message-avatar">
                                  {/* <img
                                    src="https://hauzzi.webkype.net/images/avatar/2.jpg"
                                    alt
                                  />
                                  <div className="message-counter">2</div> */}
                                  {item.propertyId !== null ? (
                                    <img
                                      src="https://i.pinimg.com/736x/d4/20/ac/d420ac751563a3d498bd84cdf69386bb.jpg"
                                      alt
                                    />
                                  ) : item.agentId !== null ? (
                                    <img
                                      src="https://w7.pngwing.com/pngs/620/1022/png-transparent-person-in-necktie-and-jacket-art-computer-icons-avatar-business-agent-icon-service-people-logo.png"
                                      alt
                                    />
                                  ) : (
                                    <img
                                      src="https://cdn.iconscout.com/icon/premium/png-256-thumb/agency-2118767-1783026.png"
                                      alt
                                    />
                                  )}
                                </div>
                                <div className="chat-contacts-item-text">
                                  <h4>{item.property.name}</h4>
                                  <span> {formatDate(item.createdAt)} </span>
                                  <p>{item.content}</p>
                                </div>
                              </a>
                            ))}{" "}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* chat-contacts-item */}
                          {/* chat-contacts-item*/}
                          {/* chat-contacts-item */}
                        </div>
                      </div>
                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div className="box-widget fl-wrap">
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg  "
                              data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                            />
                          </div>
                          <div className="banner-widget_content">
                            <h5>
                              Participate in our loyalty program. Refer a friend
                              and get a discount.
                            </h5>
                            <a
                              href="#"
                              className="btn float-btn color-bg small-btn"
                            >
                              Read More
                            </a>
                          </div>
                        </div>
                      </div>
                      {/*box-widget end */}
                    </div>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-20px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
};

export default Dashboard;
