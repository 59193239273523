import React, { useEffect, useState } from "react";


function ContactEnquiry() {
    const [contactData, setcontactData] = useState([])



    const apiUrl = process.env.REACT_APP_URL;
    // const apiKey = process.env.REACT_APP_API_KEY;
    const Token = localStorage.getItem("Token");


    function handleDelete(e, id) {

        if (window.confirm("Do you want to delete")) {
            fetch(`${apiUrl}/admin-enquiry/delete/` + id, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            }).then((res) => {

                alert('Record Deleted');
                window.location.reload()
            }).catch((err) => {
                console.log(err);
            })
        }

    }


    useEffect(() => {
        async function getUser() {
            const url = `${apiUrl}/employee/verify-customer`;

            let response = await fetch(url, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            });
            response = await response.json();

        }
        getUser();
    }, [apiUrl, Token]);


    useEffect(() => {

        async function getContact() {
            const resData = await fetch(`${apiUrl}/admin-enquiry/getAll`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${Token}`
                }
            })

            const response = await resData.json();

            if (response.status !== "error") {
                console.log('response->', response.data)
                setcontactData(response.data)
                // let newArr = sentTo
                // for (const data of response.data) {
                //     newArr = [...newArr, data.customer]
                // }
            }

        }


        getContact()


    }, [apiUrl, Token])





    return (
        <>
            <div>
                {/*=============== basic  ===============*/}
                <meta charSet="UTF-8" />
                {/*=============== css  ===============*/}
                <link type="text/css" rel="stylesheet" href="css/plugins.css" />
                <link type="text/css" rel="stylesheet" href="css/style.css" />
                <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
                <link type="text/css" rel="stylesheet" href="css/color.css" />
                {/*=============== favicons ===============*/}
                <link rel="shortcut icon" href="images/favicon.ico" />
                {/*loader*/}
                <div className="loader-wrap">
                    <div className="loader-inner">
                        <svg>
                            <defs>
                                <filter id="goo">
                                    <feGaussianBlur
                                        in="SourceGraphic"
                                        stdDeviation={2}
                                        result="blur"
                                    />
                                    <feColorMatrix
                                        in="blur"
                                        values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                                        result="gooey"
                                    />
                                    <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
                {/*loader end*/}
                {/* main */}
                <div id="main">
                    {/* header */}

                    {/* header end  */}
                    {/* wrapper  */}
                    <div id="wrapper">
                        {/* dashbard-menu-wrap */}
                        <div className="dashbard-menu-overlay" />

                        {/* dashbard-menu-wrap end  */}
                        {/* content */}
                        <div className="dashboard-content">
                            <div className="dashboard-menu-btn color-bg">
                                <span>
                                    <i className="fa-solid fa-bars" />
                                </span>
                                Dasboard Menu
                            </div>
                            <div className="container dasboard-container">
                                {/* dashboard-title */}
                                <div className="dashboard-title fl-wrap">
                                    <div className="dashboard-title-item">
                                        <span>Profile Enquiry</span>
                                    </div>



                                </div>
                                {/* dashboard-title end */}
                                <div className="dasboard-wrapper fl-wrap no-pag">
                                    {/* dashboard-list-box*/}
                                    <div className="dashboard-list-box fl-wrap">
                                        <div className="dasboard-widget-title fl-wrap">
                                            <h5>
                                                <i className="fa-solid fa-comment-alt" />
                                                Last Enquiry
                                                {/* <span> ( +3 New ) </span> */}
                                            </h5>
                                            <a
                                                href="#"
                                                className="mark-btn  tolt"
                                                data-microtip-position="bottom"
                                                data-tooltip="Mark all as read"
                                            >
                                                <i className="fa-solid fa-comments" />{" "}
                                            </a>
                                        </div>
                                        <div className="chat-wrapper fl-wrap">
                                            {/* chat-contacts*/}
                                            <div className="chat-contacts" style={{ right: '0px', width: '100%' }}>
                                                {/* chat-contacts-item*/}
                                                {contactData.length === 0 &&
                                                    <p style={{ textAlign: 'center' }}>No Enquiry</p>
                                                }
                                                {contactData?.map((data, index) => (

                                                    <a
                                                        className="chat-contacts-item"
                                                        href="#1"
                                                        style={{ marginBottom: '5px', cursor: 'default' }}
                                                    >
                                                        <div className="chat-contacts-item-text" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '19rem', marginLeft:'4rem' }}>
                                                            {/* {user?.userType == 0 && <h4>{data.customer.name}</h4>} */}
                                                            <div>
                                                                <h4>Name - {data.name.toUpperCase()}</h4>
                                                                <p style={{ width: '200px' }}>Email - {data.email} </p>
                                                                <p style={{ width: '150px' }}>Phone - {data.phone}</p>
                                                            </div>
                                                            <div >
                                                                <h4 style={{ marginBottom: "8px" }}>Message</h4>
                                                                <p>
                                                                    {data.message}
                                                                </p>
                                                            </div>

                                                            <span>{new Date(data.createdAt).toDateString()} </span>
                                                            <span
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    handleDelete(e, data.id)
                                                                }}

                                                                style={{ marginTop: "50px", cursor: 'pointer', zIndex: '2', fontSize: '10px', color: 'red' }}>
                                                                <i className="fa-solid fa-trash-alt" />
                                                            </span>


                                                        </div>
                                                    </a>
                                                ))}

                                            </div>
                                            {/* chat-contacts end*/}
                                            {/* chat-box*/}
                                            <div className="chat-box fl-wrap" style={{ zIndex: '0' }}>
                                                <div
                                                    className="chat-box-scroll fl-wrap full-height"
                                                    data-simplebar="init"
                                                >

                                                </div>
                                            </div>

                                            {/* chat-box end*/}
                                        </div>
                                        {/* dashboard-list-box end*/}
                                    </div>
                                </div>
                            </div>
                            {/* dashboard-footer */}

                            {/* dashboard-footer end */}
                        </div>
                        {/* content end */}
                        <div className="dashbard-bg gray-bg" />
                    </div>
                    {/* wrapper end */}
                </div>
                {/* Main end */}
                {/*=============== scripts  ===============*/}
            </div>
            ;
        </>
    );
}

export default ContactEnquiry;
