import React from "react";
import { useState, useEffect } from "react";

const apiUrl = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const Token = localStorage.getItem("Token");

function Agency() {
  const initialFormData = {
    state: "",
    city: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const [data, setdata] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateId, setStateId] = useState("");

  function handleDelete(e, id) {
    e.preventDefault()
    // console.log('id=>', id)
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/employee/employeeDelete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          window.location.reload(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }


  }



  useEffect(() => {
    fetch(`${apiUrl}/employee/allStates/207`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          console.log("dataaaaaaaaaaa", data.data);
          setStateOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [apiKey, apiUrl]);

  useEffect(() => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          console.log("dataaaaaaaaaaa", data.data);
          setCityOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [stateId, apiKey, apiUrl]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/profile/getAll?userType=2`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setdata(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  console.log(data);
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}
                            <div className="list-searh-input-wrap-title fl-wrap">
                              <i className="fa-solid fa-sliders-h" />
                              <span>Search Agency</span>
                            </div>
                            <div
                              className="block-box fl-wrap search-sb"
                              id="filters-column"
                            >
                              {/* listsearch-input-item */}
                              {/* <div className="listsearch-input-item">
                                <label>Keywords</label>
                                <input
                                  type="text"
                                  onclick="this.select()"
                                  placeholder="Name , agency..."
                                  defaultValue
                                  style={{ width: "100%" }}
                                />
                              </div> */}
                              {/* listsearch-input-item end*/}
                              {/* listsearch-input-item */}
                              <div className="listsearch-input-item">
                                <label>Country</label>
                                <select className="selectBox">
                                  <option>Spain</option>
                                </select>
                              </div>
                              <div className="listsearch-input-item">
                                <label>State</label>

                                <select
                                  className="selectBox"
                                  name="state"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setStateId(e.target.value);
                                  }}
                                >
                                  <option>Select States</option>
                                  {stateOptions.map((department) => (
                                    <option
                                      key={department.id}
                                      value={department.id}
                                    >
                                      {department.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="listsearch-input-item">
                                <label>Cities</label>
                                <select
                                  className="selectBox"
                                  name="city"
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                >
                                  <option>Select Cities</option>
                                  {cityOptions.map((department) => (
                                    <option
                                      key={department.id}
                                      value={department.id}
                                    >
                                      {department.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/* listsearch-input-item end*/}
                              {/* listsearch-input-item */}
                              {/* <div className="listsearch-input-item">
                                <div className="price-rage-item fl-wrap">
                                  <span className="pr_title">Rating:</span>
                                  <input
                                    type="text"
                                    className="price-range-double"
                                    data-min={1}
                                    data-max={5}
                                    name="price-range2"
                                    data-step={1}
                                    defaultValue={1}
                                    data-prefix="*"
                                  />
                                </div>
                              </div> */}
                              {/* listsearch-input-item end*/}
                              <div className="msotw_footer">
                                <a
                                  href="#"
                                  className="btn small-btn float-btn color-bg"
                                >
                                  Search Agent
                                </a>
                                <div className="reset-form reset-btn">
                                  {" "}
                                  <i className="fa-solid fa-sync-alt" /> Reset
                                  Filters
                                </div>
                              </div>
                            </div>
                            {/*box-widget*/}
                            <div
                              className="box-widget fl-wrap"
                              style={{ marginTop: "70px" }}
                            >
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  <a
                                    href="property"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Property Listing
                                  </a>
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  <span>All Agency </span>
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">
                                    Sort by:
                                  </span>
                                  <div className="listsearch-input-item">
                                    <select
                                      className="selectBox"
                                      style={{ backgroundColor: "#fff" }}
                                    >
                                      <option>New Agency</option>
                                      <option>Name: A-Z</option>
                                      <option>Name: Z-A</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                              <div className="row">
                                <div
                                  className="col-sm-12"
                                  style={{ display: "inline-grid" }}
                                >
                                  {data.length &&
                                    data.map((item, index) => (
                                      <div
                                        className="listing-item"
                                        key={index}
                                        style={{ width: "100%" }}
                                      >
                                        <article className="geodir-category-listing fl-wrap">
                                          <div className="geodir-category-img fl-wrap">
                                            <a
                                              href="#"
                                              className="geodir-category-img_item"
                                            >
                                              {/* {item.agencyDetails.map(
                                                (agencyItem, index) => (
                                                  <img
                                                    key={index}
                                                    src={`${apiUrl}/uploads/${agencyItem.photo}`}
                                                    alt
                                                  />
                                                )
                                              )} */}
                                              {item.agencyDetails.length !==
                                                0 ? (
                                                item.agencyDetails.map(
                                                  (userItem, index) => (
                                                    <img
                                                      style={{
                                                        height: "100px",
                                                        width: "100px",
                                                      }}
                                                      key={index}
                                                      src={
                                                        userItem.photo == ""
                                                          ? "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                          : `${apiUrl}/uploads/${userItem.photo}`
                                                      }
                                                      alt
                                                    />
                                                  )
                                                )
                                              ) : (
                                                <img
                                                  style={{
                                                    height: "100px",
                                                    width: "100px",
                                                    objectFit: 'cover'
                                                  }}
                                                  key={index}
                                                  src={
                                                    "https://graphicsfamily.com/wp-content/uploads/edd/2020/04/Realstate-and-construction-logo-999x999.jpg"
                                                  }
                                                  alt
                                                />
                                              )}
                                            </a>
                                          </div>
                                          <div className="geodir-category-content fl-wrap">
                                            <div
                                              className="card-verified tolt"
                                              data-microtip-position="left"
                                              data-tooltip="Connect to CRM"
                                            >
                                              {/* <a
                                                className="btn float-btn color-bg small-btn"
                                                style={{
                                                  marginTop: "0",
                                                  height: "35px",
                                                }}
                                              >
                                                CRM
                                              </a> */}
                                            </div>
                                            <div
                                              className="agent_card-title fl-wrap"
                                              style={{ paddingTop: "0" }}
                                            >
                                              <h4>
                                                <a >
                                                  {item.name}
                                                </a>
                                              </h4>
                                              <div className="geodir-category-location fl-wrap">
                                                {item.agencyDetails.map(
                                                  (agencyItem, index) => (
                                                    <a key={index} href="#">
                                                      <i className="fa-solid fa-map-marker-alt" />{" "}
                                                      {agencyItem.address}
                                                    </a>
                                                  )
                                                )}

                                                {/* <div
                                                  className="listing-rating card-popup-rainingvis"
                                                  data-starrating2={4}
                                                >
                                                  <span className="re_stars-title">
                                                    Good
                                                  </span>
                                                </div> */}
                                              </div>
                                            </div>
                                            {item.agencyDetails.map(
                                              (agencyItem, index) => (
                                                <p key={index}>                                                  
                                                  {TruncatedParagraph(
                                                    agencyItem.notes ,
                                                    200
                                                  )}
                                                </p>
                                              )
                                            )}

                                            <div className="small-facts fl-wrap">
                                              <ul>
                                                <li>
                                                  <i className="fa-solid fa-phone" />{" "}
                                                  <span>
                                                    <strong>
                                                      {
                                                        item.agencyDetails[
                                                          0
                                                        ]?.phone
                                                      }
                                                    </strong>
                                                  </span>
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-link" />{" "}
                                                  <span>
                                                    <strong>
                                                      {
                                                        item.agencyDetails[
                                                          0
                                                        ]?.website
                                                      }
                                                    </strong>
                                                  </span>
                                                </li>
                                                <li>
                                                  <i className="fa-solid fa-calendar" />{" "}
                                                  <span>
                                                    <strong>
                                                      {formatDate(
                                                        item.createdAt
                                                      )}
                                                    </strong>
                                                  </span>
                                                </li>
                                                {/* <li>
                                                  <a
                                                    href="mailto:yourmail@email.com"
                                                    className="tolt ftr-btn mt-0"
                                                    data-microtip-position="top-left"
                                                    data-tooltip="Write Message"
                                                  >
                                                    <i className="fa-solid fa-envelope" />
                                                  </a>
                                                </li> */}
                                                {/* <li>
                                                  {" "}
                                                  <a
                                                    href="tel:123-456-7890"
                                                    className="tolt ftr-btn mt-0"
                                                    data-microtip-position="top-left"
                                                    data-tooltip="Call Now"
                                                  >
                                                    <i className="fa-solid fa-phone" />
                                                  </a>
                                                </li> */}
                                                <a
                                                  onClick={(e) => {
                                                    handleDelete(e, item.id)
                                                  }}
                                                  className="tolt ftr-btn"
                                                  data-microtip-position="top"
                                                  data-tooltip="Delete"
                                                >
                                                  <i className="fa-solid fa-trash" />
                                                </a>
                                              </ul>
                                            </div>
                                          </div>
                                        </article>
                                      </div>
                                    ))}
                                </div>
                                {/*  agecy card item */}
                              </div>
                              {/* dashboard-listings-wrap end*/}
                              {/* pagination*/}
                              {/* <div className="pagination">
                                <a href="#" className="prevposts-link">
                                  <i className="fa fa-caret-left" />
                                </a>
                                <a href="#">1</a>
                                <a href="#" className="current-page">
                                  2
                                </a>
                                <a href="#">3</a>
                                <a href="#">4</a>
                                <a href="#" className="nextposts-link">
                                  <i className="fa fa-caret-right" />
                                </a>
                              </div> */}
                              {/* pagination end*/}
                            </div>
                            {/* col-md 8 end */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default Agency;
