import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Faq() {
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };

  function handleChange(e) {
    const { value } = e.target;

    const filteredData = data1.filter((ele) => ele.category === value)

    setData(filteredData)
  }

  function handleDelete(e, id) {
    e.preventDefault()

    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/faq/delete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          window.location.reload(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }

  }

  useEffect(() => {
    fetch(`${apiUrl}/faq/getAll`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setData(data.data);
          setData1(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, []);
  console.log(data);
  return (
    // <div>
    //   <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //   <link type="text/css" rel="stylesheet" href="css/style.css" />
    //   <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //   <link type="text/css" rel="stylesheet" href="css/color.css" />
    //   {/*=============== favicons ===============*/}
    //   <link
    //     rel="shortcut icon"
    //     href="https://hauzzi.webkype.net/images/favicon.ico"
    //   />
    //   {/*loader*/}
    //   {/* <div className="loader-wrap">
    //             <div className="loader-inner">
    //                 <svg>
    //                     <defs>
    //                         <filter id="goo">
    //                             <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //                             <feColorMatrix
    //                                 in="blur"
    //                                 values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //                                 result="gooey"
    //                             />
    //                             <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //                         </filter>
    //                     </defs>
    //                 </svg>
    //             </div>
    //         </div> */}
    //   {/*loader end*/}
    //   {/* main */}
    //   <div>
    //     {/* wrapper  */}
    //     <div id="wrapper">
    //       {/* content */}
    //       <div className="dashboard-content pt-0">
    //         <div className="container">
    //           {/* dashboard-title end */}
    //           {/* dasboard-wrapper*/}
    //           <div className="dasboard-wrapper fl-wrap no-pag">
    //             <p className="h1 text-center d-inline">FAQs</p>
    //             <button
    //               className="btn-sm col"
    //               style={{
    //                 backgroundColor: "black",
    //                 cursor: "pointer",
    //                 color: "white",
    //                 marginLeft: "75vw",
    //               }}
    //               onClick={() => {
    //                 navigate("/addfaq");
    //               }}
    //             >
    //               Add
    //             </button>
    //             <div className="row">
    //               <div className="col-sm-12">
    //                 <div
    //                   className="table-responsive"
    //                   style={{
    //                     height: "55vh",
    //                     overflow: "scroll",
    //                     scrollbarWidth: "none",
    //                   }}
    //                 >
    //                   <table className="table table-striped table-bordered text-nowrap mb-0 text-center shadow">
    //                     <thead>
    //                       <tr className="d-sticky">
    //                         <th>ID</th>
    //                         <th>Question</th>
    //                         <th>Category</th>
    //                         <th>Action</th>
    //                       </tr>
    //                     </thead>
    //                     <tbody>
    //                       {masterData.map((user) => (
    //                         <tr key={user.id}>
    //                           <td>{user.id}</td>
    //                           <td>{user.query}</td>
    //                           <td>{user.category}</td>
    //                           <td>
    //                             <button
    //                               // onClick={() => loadcontent(user.id)}
    //                               className="btn-sm rounded"
    //                               style={{
    //                                 backgroundColor: "black",
    //                                 cursor: "pointer",
    //                                 marginLeft: "3rem",
    //                                 color: "white",
    //                               }}
    //                             >
    //                               Edit
    //                             </button>
    //                             {/* <button onClick={() => deletecontent(user.id)}
    //                                                                 className="btn-sm rounded"
    //                                                                 style={{ backgroundColor: 'black', cursor: 'pointer', marginLeft: '3rem', color: 'white' }}>
    //                                                                 Delete
    //                                                             </button> */}
    //                           </td>
    //                         </tr>
    //                       ))}
    //                     </tbody>
    //                   </table>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           {/* dasboard-wrapper end */}
    //         </div>
    //         {/* dashboard-footer */}
    //         <div
    //           className="dashboard-footer"
    //           style={{ left: 0, bottom: "-50px" }}
    //         >
    //           <div className="dashboard-footer-links fl-wrap">
    //             <span>Helpfull Links:</span>
    //             <ul>
    //               <li>
    //                 <a href="about.html">About</a>
    //               </li>
    //               <li>
    //                 <a href="blog.html">Blog</a>
    //               </li>
    //               <li>
    //                 <a href="pricing.html">Pricing Plans</a>
    //               </li>
    //               <li>
    //                 <a href="contacts.html">Contacts</a>
    //               </li>
    //               <li>
    //                 <a href="help.html">Help Center</a>
    //               </li>
    //             </ul>
    //           </div>
    //           <a href="#main" className="dashbord-totop  custom-scroll-link">
    //             <i className="fa-solid fa-caret-up" />
    //           </a>
    //         </div>
    //         {/* dashboard-footer end */}
    //       </div>
    //     </div>
    //     {/* wrapper end */}
    //   </div>
    //   {/* Main end */}
    //   {/*=============== scripts  ===============*/}
    // </div>
    //     <div>
    //       {/*=============== basic  ===============*/}
    //       <meta charSet="UTF-8" />
    //       <title>Hauzzi Beta Website UI/UX design by Webkype </title>
    //       <meta name="robots" content="index, follow" />
    //       <meta name="keywords" content />
    //       <meta name="description" content />
    //       {/*=============== css  ===============*/}
    //       <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //       <link type="text/css" rel="stylesheet" href="css/style.css" />
    //       <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //       <link type="text/css" rel="stylesheet" href="css/color.css" />
    //       {/*=============== favicons ===============*/}
    //       <link rel="shortcut icon" href="images/favicon.ico" />
    //       {/*loader*/}
    //       {/* <div className="loader-wrap">
    //   <div className="loader-inner">
    //     <svg>
    //       <defs>
    //         <filter id="goo">
    //           <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //           <feColorMatrix
    //             in="blur"
    //             values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //             result="gooey"
    //           />
    //           <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //         </filter>
    //       </defs>
    //     </svg>
    //   </div>
    // </div> */}
    //       {/*loader end*/}
    //       {/* main */}
    //       <div id="main">
    //         {/* header */}

    //         {/* header end  */}
    //         {/* header end  */}

    //         {/* wrapper  */}
    //         <div id="wrapper">
    //           {/* content */}
    //           <div className="dashboard-content pt-0">
    //             <div className="container">
    //               {/* dashboard-title end */}
    //               {/* dasboard-wrapper*/}
    //               <div className="dasboard-wrapper fl-wrap no-pag">
    //                 <p className="h1 text-center d-inline">FAQs</p>
    //                 <button
    //                   className="btn-sm col"
    //                   style={{
    //                     backgroundColor: "black",
    //                     cursor: "pointer",
    //                     color: "white",
    //                     marginLeft: "75vw",
    //                   }}
    //                   onClick={() => {
    //                     navigate("/addfaq");
    //                   }}
    //                 >
    //                   Add
    //                 </button>
    //                 <div className="row">
    //                   <div className="col-sm-12">
    //                     <div
    //                       className="table-responsive"
    //                       style={{
    //                         height: "55vh",
    //                         overflow: "scroll",
    //                         scrollbarWidth: "none",
    //                       }}
    //                     >
    //                       <table className="table table-striped table-bordered text-nowrap mb-0 text-center shadow">
    //                         <thead>
    //                           <tr className="d-sticky">
    //                             <th>ID</th>
    //                             <th>Question</th>
    //                             <th>Category</th>
    //                             <th>Action</th>
    //                           </tr>
    //                         </thead>
    //                         <tbody>
    //                           {masterData.map((user) => (
    //                             <tr key={user.id}>
    //                               <td>{user.id}</td>
    //                               <td>{user.query}</td>
    //                               <td>{user.category}</td>
    //                               <td>
    //                                 <button
    //                                   // onClick={() => loadcontent(user.id)}
    //                                   className="btn-sm rounded"
    //                                   style={{
    //                                     backgroundColor: "black",
    //                                     cursor: "pointer",
    //                                     marginLeft: "3rem",
    //                                     color: "white",
    //                                   }}
    //                                 >
    //                                   Edit
    //                                 </button>
    //                                 {/* <button onClick={() => deletecontent(user.id)}
    //                                                                     className="btn-sm rounded"
    //                                                                     style={{ backgroundColor: 'black', cursor: 'pointer', marginLeft: '3rem', color: 'white' }}>
    //                                                                     Delete
    //                                                                 </button> */}
    //                               </td>
    //                             </tr>
    //                           ))}
    //                         </tbody>
    //                       </table>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //               {/* dasboard-wrapper end */}
    //               <div />
    //               {/* wrapper end */}
    //             </div>
    //             <div className="dashboard-footer" style={{ left: 0 }}>
    //               <div className="dashboard-footer-links fl-wrap">
    //                 <span>Helpfull Links:</span>
    //                 <ul>
    //                   <li>
    //                     <a href="about.html">About</a>
    //                   </li>
    //                   <li>
    //                     <a href="blog.html">Blog</a>
    //                   </li>
    //                   <li>
    //                     <a href="pricing.html">Pricing Plans</a>
    //                   </li>
    //                   <li>
    //                     <a href="contacts.html">Contacts</a>
    //                   </li>
    //                   <li>
    //                     <a href="help.html">Help Center</a>
    //                   </li>
    //                 </ul>
    //               </div>
    //               <a href="#main" className="dashbord-totop  custom-scroll-link">
    //                 <i className="fa-solid fa-caret-up" />
    //               </a>
    //             </div>
    //           </div>

    //           <div className="dashbard-bg gray-bg" />
    //         </div>
    //       </div>
    //     </div>
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link
        rel="shortcut icon"
        href="https://hauzzi.webkype.net/images/favicon.ico"
      />
      {/*loader*/}
      {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* dashbard-menu-wrap end  */}
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fa-solid fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              <div className="dasboard-wrapper fl-wrap">
                <div className="dasboard-listing-box fl-wrap">
                  {/* col-list-wrap */}
                  <section className="gray-bg small-padding ">
                    <div className="container">
                      <div className="row">
                        {/* search sidebar*/}
                        <div className="col-md-4">
                          {/* <div className="list-searh-input-wrap-title fl-wrap">
                            <i className="fa-solid fa-sliders-h" />
                            <span>Search FAQ</span>
                          </div> */}
                          {/* <div
                            className="block-box fl-wrap search-sb"
                            id="filters-column"
                          >
                            <div className="listsearch-input-item">
                              <label>Keywords</label>
                              <input
                                type="text"
                                onclick="this.select()"
                                placeholder="Name , agency..."
                                defaultValue
                              />
                            </div>

                            <div className="listsearch-input-item">
                              <label>Cities</label>
                              <select
                                data-placeholder="All Cities"
                                className="chosen-select on-radius no-search-select"
                              >
                                <option>All Cities</option>
                                <option>New York</option>
                                <option>London</option>
                                <option>Paris</option>
                                <option>Kiev</option>
                                <option>Moscow</option>
                                <option>Dubai</option>
                                <option>Rome</option>
                                <option>Beijing</option>
                              </select>
                            </div>

                            <div className="listsearch-input-item">
                              <div className="price-rage-item fl-wrap">
                                <span className="pr_title">Rating:</span>
                                <input
                                  type="text"
                                  className="price-range-double"
                                  data-min={1}
                                  data-max={5}
                                  name="price-range2"
                                  data-step={1}
                                  defaultValue={1}
                                  data-prefix="*"
                                />
                              </div>
                            </div>
                           
                            <div className="msotw_footer">
                              <a
                                href="#"
                                className="btn small-btn float-btn color-bg"
                              >
                                Search FAQ
                              </a>
                              <div className="reset-form reset-btn">
                                {" "}
                                <i className="fa-solid fa-sync-alt" /> Reset
                                Filters
                              </div>
                            </div>
                          </div> */}
                          <div
                            className="box-widget fl-wrap"
                            style={{ marginTop: "85px" }}
                          >
                            <div className="banner-widget fl-wrap">
                              <div className="bg-wrap bg-parallax-wrap-gradien">
                                <div
                                  className="bg  "
                                  data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                                />
                              </div>
                              <div className="banner-widget_content">
                                <h5>
                                  Add answer questions that customers often ask
                                </h5>
                                <a
                                  href="addfaq"
                                  className="btn float-btn color-bg small-btn"
                                >
                                  Add FAQs
                                </a>
                              </div>
                            </div>
                          </div>
                          {/*box-widget*/}

                          {/*box-widget end */}
                          {/*box-widget*/}

                          {/*box-widget end */}
                        </div>
                        {/* search sidebar end*/}
                        <div className="col-md-8">
                          {/* list-main-wrap-header*/}
                          <div className="list-main-wrap-header box-list-header fl-wrap">
                            {/* list-main-wrap-title*/}
                            <div className="list-main-wrap-title">
                              <h2>
                                All FAQs
                                <strong>{data.length}</strong>
                              </h2>
                            </div>
                            <div className="list-main-wrap-title">
                              <h2>
                                {/* <div
                                  className="reset-form reset-btn"
                                  onClick={() => {
                                    navigate("/addfaq");
                                  }}
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "0",

                                    color: "#34495E  ",
                                  }}
                                >
                                  Edit <i className="fa-solid fa-pen" />
                                </div> */}
                                {/* <div
                                  className="reset-form reset-btn"
                                  onClick={() => {
                                    navigate("/addfaq");
                                  }}
                                  style={{ marginTop: "0", color: "#34495E  " }}
                                >
                                  Add FAQ{" "}
                                  <i className="fa-solid fa-circle-plus" />
                                </div> */}
                                {/* <button
                                  type="button"
                                  class="btn btn-outline-secondary"
                                  style={{
                                    color: "#34495E  ",
                                    marginTop: "-6px",
                                  }}
                                >
                                  Add FAQ
                                </button> */}
                              </h2>
                            </div>
                            {/* list-main-wrap-title end*/}
                            {/* list-main-wrap-opt*/}
                            <div className="list-main-wrap-opt">
                              {/* price-opt*/}
                              <div className="price-opt">
                                <span className="price-opt-title">
                                  Category:
                                </span>
                                <div className="listsearch-input-item">
                                  <select
                                    onChange={(e) => {
                                      handleChange(e)
                                    }}
                                    style={{
                                      height: "4vh",
                                      display: "inline",
                                      padding: "10px",
                                      borderRadius: "5px",
                                      border: "1px solid #808080",
                                      transition: "box-shadow 0.3s ease",
                                      outline: "none  ",
                                      width: "100%",
                                      height: "50px",
                                      color: "#808080",
                                      background: "#f5f7fb",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <option >Select Category</option>
                                    <option>Buyer</option>
                                    <option>Seller</option>
                                    <option>Agency</option>
                                    <option>Agent</option>
                                    <option>Listing</option>
                                  </select>
                                </div>
                              </div>
                              {/* price-opt end*/}
                            </div>
                            {/* list-main-wrap-opt end*/}
                          </div>
                          {/* list-main-wrap-header end*/}
                          {/* dashboard-listings-wrap*/}
                          <div className="listing-item-container one-column-grid-wrap  box-list_ic agency-list fl-wrap  dashboard-agency-list">
                            <div className="row">
                              <div
                                className="col-sm-12"
                                style={{
                                  display: "inline-grid",
                                  marginTop: "15px",
                                }}
                              >
                                {data.length &&
                                  data.map((item, index) => (
                                    <div
                                      className="listing-item"
                                      key={index}
                                      style={{ width: "100%" }}
                                    >
                                      <article className="geodir-category-listing fl-wrap">
                                        <div className="geodir-category-img fl-wrap">
                                          <a className="geodir-category-img_item">
                                            {item.category}
                                          </a>
                                        </div>
                                        <div className="geodir-category-content fl-wrap">
                                          <div
                                            className="card-verified tolt"
                                            data-microtip-position="left"
                                            data-tooltip="Delete"
                                            style={{ fontSize: "16px", cursor: 'pointer' }}
                                            onClick={(e) => { handleDelete(e, item.id) }}
                                          >
                                            <i className="fa-solid fa-trash" />
                                          </div>
                                          <div className="agent_card-title fl-wrap">
                                            <h4>
                                              <a key={index} href="#">
                                                {item.query}
                                              </a>
                                            </h4>
                                            <div className="geodir-category-location fl-wrap">
                                              {/* <a key={index} href="#">
                                                <i className="fa-solid fa-map-marker-alt" />{" "}
                                                {item.answer}
                                              </a> */}
                                            </div>
                                          </div>
                                          <p key={index}>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: TruncatedParagraph(
                                                  item.answer,
                                                  200
                                                ),
                                              }}
                                            />
                                          </p>

                                          {/* <div className="small-facts fl-wrap">
                                            <ul>
                                              <li>
                                                <i className="fa-solid fa-home" />{" "}
                                                <span>
                                                  <strong>36</strong>Listing
                                                </span>
                                              </li>
                                              <li>
                                                <i className="fa-solid fa-users-class" />{" "}
                                                <span>
                                                  <strong>10</strong>Agents
                                                </span>
                                              </li>
                                              <li>
                                                <i className="fa-solid fa-comment-alt" />{" "}
                                                <span>
                                                  <strong>56</strong>Reviews
                                                </span>
                                              </li>
                                              <li>
                                                <a
                                                  href="mailto:yourmail@email.com"
                                                  className="tolt ftr-btn mt-0"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Write Message"
                                                >
                                                  <i className="fa-solid fa-envelope" />
                                                </a>
                                              </li>
                                              <li>
                                                {" "}
                                                <a
                                                  href="tel:123-456-7890"
                                                  className="tolt ftr-btn mt-0"
                                                  data-microtip-position="top-left"
                                                  data-tooltip="Call Now"
                                                >
                                                  <i className="fa-solid fa-phone" />
                                                </a>
                                              </li>
                                            </ul>
                                          </div> */}
                                        </div>
                                      </article>
                                    </div>
                                  ))}
                              </div>
                              {/*  agecy card item */}
                            </div>
                            {/* dashboard-listings-wrap end*/}
                            {/* pagination*/}

                            {/* pagination end*/}
                          </div>
                          {/* col-md 8 end */}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            {/* dashboard-footer */}
            <div className="dashboard-footer" style={{ left: 0 }}>
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
}

export default Faq;
