import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "..//..//assets/logo.png";
import bb from "..//..//assets/bb.jpg";
import { IoPower } from "react-icons/io5";
import { FaRegStar } from "react-icons/fa";
import { FaRegMessage } from "react-icons/fa6";
import { GoBell } from "react-icons/go";
import { FaRegCalendarAlt } from "react-icons/fa";
// import { FaRegHeart } from "react-icons/fa";

import { MdOutlineMail, MdOutlineArrowDropDown } from "react-icons/md";
import { BsGraphUpArrow, BsFileEarmarkPlus } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import { toast } from "react-toastify";
import "../header/header.css";
import { useState, useEffect } from "react";

// const Header = () => {

//     const navigate = useNavigate();

//     const handleLogOut = () => {
//         localStorage.removeItem('Token')
//         window.location.href = '/admin-login'
//         toast.success('Logged Out !!')
//     }
//     return (
//         <>
//             <header className="main-header"><div className="logo-holder m-0">
//                 <img
//                     onClick={() => {
//                         navigate('/')
//                     }}
//                     src={logo}
//                     alt="logo"
//                     role='button'
//                 />
//             </div>

//                 <div className="nav-button-wrap color-bg nvminit">
//                     <div className="nav-button">
//                         <span></span><span></span><span></span>
//                     </div>
//                 </div>

//                 <p className="header-opt_btn tolt" data-tooltip="Log Out" onClick={handleLogOut}>
//                     <span><IoPower /></span>
//                 </p>

//                 <p className="header-opt_btn tolt" data-tooltip="Starred">
//                     <span><FaRegStar /></span>
//                 </p>

//                 <p className="header-opt_btn tolt" data-tooltip="Messages">
//                     <span><FaRegMessage /></span>
//                 </p>

//                 <p className="cart-btn tolt show-header-modal" data-microtip-position="bottom" data-tooltip="Your Notifications">
//                     <GoBell />
//                     <span className="cart-btn_counter color-bg">5</span>
//                 </p>

//                 <span className="show-reg-form">
//                     <img src={bb} alt="admin" /> Welcome Admin
//                 </span>

//             </header>

//             <div className="dashbaord_menu main-header" style={{ marginTop: '4rem' }} >

//                 <div className="nav-holder main-menu" style={{ zIndex: 30, backgroundColor: '#000000', width: '100%' }}>
//                     <nav>
//                         <ul className="no-list-style">
//                             <li>
//                                 <a
//                                     style={{ fontSize: '15px', color: 'white' }}
//                                     onClick={() => {
//                                         navigate('/')
//                                     }}
//                                     role='button'
//                                 >
//                                     <BsGraphUpArrow />
//                                     Dashboard
//                                 </a>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><MdOutlineMail /> Masters <MdOutlineArrowDropDown />
//                             </a>
//                                 <ul>

//                                     <li
//                                         onClick={() => {
//                                             navigate('Profile')
//                                         }}
//                                         role='button'
//                                     ><a>Hauzzi Profile</a>
//                                     </li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('add-category')
//                                         }}
//                                         role='button'><a>Property Category</a></li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('category-data')
//                                         }}
//                                         role='button'><a>Sub Category</a></li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('add-master')
//                                         }}
//                                         role='button'><a>Master</a></li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('master-data')
//                                         }}
//                                         role='button'><a>Master Value</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><MdOutlineMail /> CMS <MdOutlineArrowDropDown /> </a>
//                                 <ul>

//                                     <li
//                                         onClick={() => {
//                                             navigate('faq')
//                                         }}
//                                         role='button'
//                                     >
//                                         <a>FAQs</a>
//                                     </li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('blog')
//                                         }}
//                                         role='button'
//                                     >
//                                         <a>Blogs</a>
//                                     </li>
//                                     <li
//                                         onClick={() => {
//                                             navigate('Review')
//                                         }}
//                                         role='button'
//                                     >
//                                         <a>Reviews
//                                         </a>
//                                     </li>
//                                     <li><a>Menu Manager</a></li>
//                                     <li><a>Pages Manager</a></li>
//                                     <li><a>Ads & Banners</a></li>
//                                     <li><a>Notifications</a></li>
//                                     <li><a>Messages</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><HiOutlineUserGroup /> Staff <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><Link to='/Staff'>Active Staff</Link></li>
//                                     <li><a>In Active Staff</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><HiOutlineUserGroup /> Owners <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><a>New Owners</a></li>
//                                     <li><a>Approved Owners</a></li>
//                                     <li><a>Blocked Owners</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><HiOutlineUserGroup /> Agents <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><a>New Agents</a></li>
//                                     <li><a>Approved Agents</a></li>
//                                     <li><a>Blocked Agents</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><HiOutlineUserGroup /> Buyers <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><a>New Buyers</a></li>
//                                     <li><a>Approved Buyers</a></li>
//                                     <li><a>Blocked Buyers</a></li>
//                                     <li><a>Actual Buyers</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}><HiOutlineUserGroup /> Agency <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><a>New Agency</a></li>
//                                     <li><a>Approved Agency</a></li>
//                                     <li><a>Blocked Agency</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}> <FaRegCalendarAlt /> Properties <MdOutlineArrowDropDown /> </a>
//                                 <ul>
//                                     <li><a>New Properties</a></li>
//                                     <li><a>Approved Properties</a></li>
//                                     <li><a>Blocked Properties</a></li>
//                                     <li><a>Rejected Properties</a></li>
//                                     <li><a>Live Properties</a></li>
//                                     <li><a>Sold Properties</a></li>
//                                 </ul>
//                             </li>
//                             <li><a style={{ fontSize: '15px', color: 'white' }}> <FaRegCalendarAlt /> Bookings <MdOutlineArrowDropDown /> </a>

//                                 <ul>
//                                     <li><a>New Bookings</a></li>
//                                     <li><a>Approved Bookings</a></li>
//                                     <li><a>Assigned Bookings</a></li>
//                                     <li><a>In Process Bookings</a></li>
//                                     <li><a>Cancelled Bookings</a></li>
//                                     <li><a>Closed Bookings</a></li>
//                                 </ul></li>

//                             <li><a style={{ fontSize: '15px', color: 'white' }}><BsFileEarmarkPlus /> Reports <MdOutlineArrowDropDown /></a>
//                                 <ul>
//                                     <li><a>Staff Reports</a></li>
//                                     <li><a>Agent Reports</a></li>
//                                     <li><a>Owner Reports</a></li>
//                                     <li><a>Agency Reports</a></li>
//                                     <li><a>Buyer Reports</a></li>
//                                     <li><a>Properties Report</a></li>
//                                     <li><a>Booking Reports</a></li>
//                                     <li><a>Sales Report</a></li>
//                                 </ul>
//                             </li>

//                         </ul>
//                     </nav>
//                 </div>

//                 <Outlet />

//             </div>
//         </>
//     )
// }
function Header({ permission }) {
  const apiUrl = process.env.REACT_APP_URL;
  const [enqData, setEnqData] = useState([]);
  const [contact, setContact] = useState([])
  const token = localStorage.getItem("Token");

  // console.log('permission =>', permission)

  useEffect(() => {

    async function getEnquiry() {
      try {
        const url = `${apiUrl}/admin-enquiry/getAll`
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token
          }
        })
        const resData = await response.json();

        if (resData && Array.isArray(resData.data)) {
          setContact(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }

      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }

    getEnquiry()

  }, [apiUrl, token])

  useEffect(() => {
    async function getTableData() {
      try {
        const response = await fetch(`${apiUrl}/enquiry/getAll`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const resData = await response.json();
        if (resData && Array.isArray(resData.data)) {
          setEnqData(resData.data);
        } else {
          console.log("Response does not contain any array data", resData);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data", error.message);
      }
    }
    getTableData();
  }, []);
  // console.log(enqData);
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.removeItem("Token");
    window.location.href = "/admin-login";
    toast.success("Logged Out !!");
  };
  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}

        {/* header */}
        {/* header */}
        <header className="main-header">
          {/*  logo  */}
          <div className="logo-holder">
            <a>
              <img
                src="https://hauzzi.webkype.net/images/logo.png"
                alt
                onClick={() => {
                  navigate("/");
                }}
              />
            </a>
          </div>
          {/* logo end  */}
          {/* nav-button-wrap*/}
          <div className="nav-button-wrap color-bg nvminit">
            <div className="nav-button">
              <span />
              <span />
              <span />
            </div>
          </div>
          {/* nav-button-wrap end*/}
          {/*  header-opt_btn */}
          <a
            data-microtip-position="top-left"
            className="header-opt_btn tolt text-red"
            data-tooltip="logout"
          >
            <span onClick={handleLogOut}>
              <i className="fa-solid fa-power-off" />{" "}
            </span>
          </a>
          {/*  header-opt_btn end */}
          {/*  header-opt_btn */}
          <a
            // onClick={() => {
            //   navigate("review");
            // }}
            href="review"
            role="button"
            className="header-opt_btn tolt"
            data-tooltip="Messsages"
          >
            <span>
              <i className="fa-solid fa-star" />
            </span>
          </a>
          {/*  header-opt_btn end */}
          {/*  header-opt_btn */}
          <a
            href="property"
            className="header-opt_btn tolt"
            data-tooltip="Property"
          >
            <span>
              <i class="fa-solid fa-building"></i>
            </span>
          </a>
          {/*  header-opt_btn end */}
          {/*  cart-btn   */}
          <a
            href="enquiry"
            className="cart-btn  tolt show-header-modal"
            data-microtip-position="bottom"
            data-tooltip="Enquiry"
          >
            <i className="fa-solid fa-bell" />
            <span className="cart-btn_counter color-bg">{enqData.length}</span>
          </a>
          {/*  cart-btn end */}
          {/*  cart-btn   */}
          <a
            href="contact-enquiry"
            className="cart-btn  tolt show-header-modal"
            data-microtip-position="bottom"
            data-tooltip="Contact Enquiry"
          >
            <i className="fa-solid fa-envelope" />
            <span className="cart-btn_counter color-bg">{contact.length}</span>
          </a>
          {/*  cart-btn end */}
          {/*  login btn */}
          <a
            className="show-reg-form "
            // onClick={() => {
            //   navigate("profile");
            // }}
            href="profile"
          >
            <img src="https://hauzzi.webkype.net/images/avatar/5.jpg" alt />{" "}
            Welcome Admin
          </a>
          {/*  login btn  end */}
        </header>
        {/* header end  */}
        {/* header end  */}
        <div
          className="dashbaord_menu"
          style={{
            position: "fixed",
            top: "70px",
            right: 0,
            zIndex: "1000 !important",
          }}
        >
          {/*  navigation */}
          <div className="nav-holder main-menu ">
            <nav>
              <ul className="no-list-style">
                <li>
                  <a
                    // onClick={() => {
                    //   navigate("/");
                    // }}
                    href="/"
                    className="user-profile-act"
                    style={{ color: "#fff", cursor: "pointer" }}
                  >
                    <i className="fa-solid fa-chart-line" /> Dashboard
                  </a>
                </li>
                {permission.includes('Master') &&
                  <li style={{ color: "#fff" }}>
                    <a>
                      <i
                        className="fa-solid fa-envelope"
                        style={{ color: "#fff" }}
                      />{" "}
                      Masters{" "}
                      <i
                        className="fa-solid fa-caret-down"
                        style={{ color: "#fff" }}
                      />{" "}
                    </a>
                    <ul>
                      <li>
                        <a
                          // onClick={() => {
                          //   navigate("profile");
                          // }}
                          href="profile"
                          style={{ cursor: "pointer" }}
                        >
                          Hauzzi Profile
                        </a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("add-category");
                        // }}

                        role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="add-category">Property Category</a>
                      </li>
                      <li>
                        <a
                          // onClick={() => {
                          //   navigate("category-data");
                          // }}
                          href="category-data"
                          role="button"
                          style={{ cursor: "pointer" }}
                        >
                          Sub Category
                        </a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("add-master");
                        // }}

                        role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="add-master">Master</a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("master-data");
                        // }}
                        role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="master-data">Master Value</a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("enquiry");
                        // }}
                        role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="enquiry">Enquiry</a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("enquiry");
                        // }}
                        role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="addstaff">Add Staff</a>
                      </li>
                    </ul>
                  </li>

                }
                {permission.includes('CMS') &&
                  <li style={{ color: "#fff" }}>
                    <a>
                      <i className="fa-solid fa-envelope" /> CMS{" "}
                      <i className="fa-solid fa-caret-down" />{" "}
                    </a>
                    <ul>
                      <li>
                        <a href="faq">FAQ</a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("blog");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="blog">Blogs</a>
                      </li>
                      <li
                        // onClick={() => {
                        //   navigate("review");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="review">Reviews</a>
                      </li>
                      <li>
                        <a href="city">Add City</a>
                      </li>
                      <li>
                        <a href="city-listing">City Listing</a>
                      </li>
                      <li>
                        <a href="banners">Banners</a>
                      </li>
                      <li>
                        <a href="testimonials">Testimonials</a>
                      </li>
                      <li>
                        <a href="add-testimonials">Add Testimonials</a>
                      </li>
                      <li>
                        <a href="mortgage">Mortgage Query</a>
                      </li>
                    </ul>
                  </li>
                }

                {permission.includes('Staff') &&
                  <li>
                    <a style={{ color: "#fff" }}>
                      <i className="fa-solid fa-users" /> Staff{" "}
                      <i className="fa-solid fa-caret-down" />
                    </a>
                    <ul>
                      <li>
                        <a href="staff">Active Staff</a>{" "}
                      </li>
                      <li>
                        <a>Inactive Staff</a>
                      </li>
                    </ul>
                  </li>
                }

                {/* <li
                  // onClick={() => {
                  //   navigate("city");
                  // }}
                  role="button"
                  style={{ cursor: "pointer" }}
                >
                  <a style={{ color: "#fff" }}>
                    <i className="fa-solid fa-city" /> City{" "}
                    <i className="fa-solid fa-caret-down" />
                  </a>
                  <ul>
                    <li>
                      <a href="city">Add City</a>
                    </li>
                    <li>
                      <a href="city-listing"> City Listing</a>
                    </li>
                    <li>
                      <a >Blocked City</a>
                    </li>
                  </ul>
                </li> */}
                {permission.includes('Agent') &&
                  <li>
                    <a style={{ color: "#fff" }}>
                      <i className="fa-solid fa-users" /> Agents{" "}
                      <i className="fa-solid fa-caret-down" />
                    </a>
                    <ul>
                      <li
                        // onClick={() => {
                        //   navigate("agent");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="agent">New Agents</a>
                      </li>
                      <li>
                        <a>Approved Agents</a>
                      </li>
                      <li>
                        <a>Blocked Agents</a>
                      </li>
                    </ul>
                  </li>
                }

                {permission.includes('User') &&
                  <li>
                    <a style={{ color: "#fff" }}>
                      <i className="fa-solid fa-users" /> Users{" "}
                      <i className="fa-solid fa-caret-down" />
                    </a>
                    <ul>
                      <li
                        // onClick={() => {
                        //   navigate("owner");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="owner">New User</a>
                      </li>
                      <li>
                        <a>Approved User</a>
                      </li>
                      <li>
                        <a>Blocked User</a>
                      </li>
                      <li>
                        <a>Actual User</a>
                      </li>
                    </ul>
                  </li>
                }
                {permission.includes('Agency') &&
                  <li>
                    <a style={{ color: "#fff" }}>
                      <i className="fa-solid fa-users" /> Agency{" "}
                      <i className="fa-solid fa-caret-down" />
                    </a>
                    <ul>
                      <li
                        // onClick={() => {
                        //   navigate("agency");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="agency">New Agency</a>
                      </li>
                      <li>
                        <a>Approved Agency</a>
                      </li>
                      <li>
                        <a>Blocked Agency</a>
                      </li>
                    </ul>
                  </li>
                }

                {permission.includes('Property') &&
                  <li>
                    <a href style={{ color: "#fff" }}>
                      {" "}
                      <i className="fa-solid fa-calendar-check" /> Properties{" "}
                      <i className="fa-solid fa-caret-down" />{" "}
                    </a>
                    <ul>
                      <li
                        // onClick={() => {
                        //   navigate("property");
                        // }}
                        // role="button"
                        style={{ cursor: "pointer" }}
                      >
                        <a href="property">New Properties</a>
                      </li>
                      <li>
                        <a href>Approved Properties</a>
                      </li>
                      <li>
                        <a href>Blocked Properties</a>
                      </li>
                      <li>
                        <a href>Rejected Properties</a>
                      </li>
                      <li>
                        <a href>Live Properties</a>
                      </li>
                      <li>
                        <a href>Sold Properties</a>
                      </li>
                    </ul>
                  </li>
                }
                {permission.includes('Booking') &&
                  <li>
                    <a hr style={{ color: "#fff" }}>
                      {" "}
                      <i className="fa-solid fa-calendar-check" /> Bookings{" "}
                      <i className="fa-solid fa-caret-down" />{" "}
                    </a>
                    <ul>
                      <li>
                        <a href="booking">New Bookings</a>
                      </li>
                      <li>
                        <a href="booked-booking">Approved Bookings</a>
                      </li>

                      <li>
                        <a href="inprogress-booking">In Process Bookings</a>
                      </li>
                      <li>
                        <a href="cancelled-booking">Cancelled Bookings</a>
                      </li>
                      <li>
                        <a href="complete-booking">Complete Bookings</a>
                      </li>
                    </ul>
                  </li>
                }

                {permission.includes('Reports') &&
                  <li>
                    <a style={{ color: "#fff" }}>
                      <i className="fa-solid fa-plus" /> Reports{" "}
                      <i className="fa-solid fa-caret-down" />
                    </a>
                    <ul>
                      <li>
                        <a href="staff">Staff Reports</a>
                      </li>
                      <li>
                        <a href="agent">Agent Reports</a>
                      </li>
                      <li>
                        <a href="owner">User Reports</a>
                      </li>
                      <li>
                        <a href="agency">Agency Reports</a>
                      </li>

                      <li>
                        <a href="property">Properties Report</a>
                      </li>
                      <li>
                        <a href="booking">Booking Reports</a>
                      </li>
                    </ul>
                  </li>
                }




              </ul>
            </nav>
          </div>
          {/* navigation  end */}
        </div>
        {/* wrapper  */}

        {/* wrapper end */}

        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default Header;
