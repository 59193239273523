import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_URL;
const apiKey = process.env.REACT_APP_API_KEY;
const Token = localStorage.getItem("Token");

function AddTestinomials() {
  const initialFormData = {
    name: "",
    content: "",
    source: "",
    photo: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [blogPhoto, setBlogPhoto] = useState(null);
  const navigate = useNavigate();

  // A function to handle the change of the input fields

  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      setFormData((prev) => ({ ...prev, [name]: files[0] }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(formData, "formData======???>>");

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }

      const response = await fetch(`${apiUrl}/testimonial/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // window.location.reload()
      navigate("/testimonials");
    } catch (error) {
      toast.error(error.message);
    }
  };
  console.log(formData);
  return (
    <>
      {" "}
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link rel="shortcut icon" href="images/favicon.ico" />
        {/*loader*/}
        {/* <div className="loader-wrap">
<div className="loader-inner">
  <svg>
    <defs>
      <filter id="goo">
        <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
        <feColorMatrix
          in="blur"
          values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
          result="gooey"
        />
        <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
      </filter>
    </defs>
  </svg>
</div>
</div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* content */}

            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fas fa-bars" />
                </span>
                Dasboard Menu{" "}
              </div>{" "}
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                {/* dasboard-wrapper*/}{" "}
                <div className="dasboard-wrapper fl-wrap no-pag">
                  {" "}
                  <div className="row">
                    <div className="col-md-4">
                      {/* <div className="list-searh-input-wrap-title fl-wrap">
                        <i className="fa-solid fa-sliders-h" />
                        <span>Search Agency</span>
                      </div> */}

                      {/*box-widget*/}

                      {/*box-widget end */}
                      {/*box-widget*/}
                      <div
                        className="box-widget fl-wrap"
                        // style={{ marginTop: "70px" }}
                      >
                        <div className="banner-widget fl-wrap">
                          <div className="bg-wrap bg-parallax-wrap-gradien">
                            <div
                              className="bg  "
                              data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                            />
                          </div>
                          <div className="banner-widget_content">
                            <h5>Ideas are easy. Implementation is hard</h5>
                            <a
                              href="testimonials"
                              className="btn float-btn color-bg small-btn"
                            >
                              Testimonials Listing
                            </a>
                          </div>
                        </div>
                      </div>

                      {/*box-widget end */}
                    </div>{" "}
                    <div className="col-md-8" style={{ marginTop: "20px" }}>
                      {" "}
                      <div className="dasboard-widget-title fl-wrap">
                        {" "}
                        <h5>
                          <i className="fa-solid fa-blog" />
                          Add Testimonials{" "}
                        </h5>{" "}
                      </div>{" "}
                      <div className="dasboard-widget-box fl-wrap">
                        {" "}
                        <form>
                          {" "}
                          <div className="custom-form">
                            {" "}
                            <label>
                              Name{" "}
                              <span className="dec-icon">
                                <i className="fa-solid fa-pen" />{" "}
                              </span>{" "}
                            </label>{" "}
                            <input
                              type="text"
                              placeholder="Your Name"
                              name="name"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{ width: "100%" }}
                            />
                            <label>
                              Rating
                              {/* <span className="dec-icon">
                          <i className="fa-solid fa-pen" />
                        </span> */}
                            </label>
                            <div className="rating" style={{ float: "left" }}>
                              {[1, 2, 3, 4, 5].map((star) => (
                                <span
                                  key={star}
                                  className={
                                    star <= rating ? "filled" : "empty"
                                  }
                                  onClick={() => handleRatingChange(star)}
                                  style={{ fontSize: "20px", padding: "5px" }}
                                >
                                  ★
                                </span>
                              ))}
                            </div>
                            <label>
                              Source
                              <span className="dec-icon">
                                <i className="fa-solid fa-pen" />{" "}
                              </span>{" "}
                            </label>{" "}
                            <input
                              type="text"
                              placeholder="Source"
                              name="source"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              style={{ width: "100%" }}
                            />
                            {/* <input
                        type="text"
                        placeholder="Enter blog category"
                        name="category"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        style={{ width: "100%" }}
                      />{" "} */}
                            <div className="listsearch-input-item">
                              {" "}
                              {/* <select
                                name="category"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                style={{ width: "100%" }}
                                className="chosen-select no-search-select"
                              >
                                <option value="agent">Select a category</option>
                                <option value="agent">Agent</option>
                                <option value="property">Property</option>
                                <option value="agency">Agency</option>
                                <option value="user">User</option>
                              </select> */}
                              {/* <div className="rating" style={{ float: "left" }}>
                                {[1, 2, 3, 4, 5].map((star) => (
                                  <span
                                    key={star}
                                    className={
                                      star <= rating ? "filled" : "empty"
                                    }
                                    onClick={() => handleRatingChange(star)}
                                    style={{ fontSize: "20px", padding: "5px" }}
                                  >
                                    ★
                                  </span>
                                ))}
                              </div> */}
                            </div>
                            {/* <span>
                          {" "}
                          <i className="fa-solid fa-image" /> Blog Cover{" "}
                        </span> */}
                            <label style={{ marginTop: "20px" }}>
                              Content{" "}
                            </label>
                            <textarea
                              cols={40}
                              rows={3}
                              placeholder=" Type..."
                              style={{ marginBottom: 20, width: "100%" }}
                              name="content"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <input
                              type="file"
                              // className="upload"
                              name="photo"
                              onChange={(e) => {
                                const files = e.target.files;
                                if (files.length > 0) {
                                  const propertyFile = files[0];
                                  if (propertyFile.type.startsWith("image/")) {
                                    setBlogPhoto(propertyFile);
                                    handleChange(e);
                                  }
                                }
                              }}
                              style={{
                                width: "100%",
                                marginTop: "20px",
                                marginBottom: "30px",
                                color: "#808080",
                              }}
                            />
                            {blogPhoto && (
                              <img
                                src={URL.createObjectURL(blogPhoto)}
                                alt="Selected File"
                                style={{ maxWidth: "350px" }}
                              />
                            )}
                          </div>
                          <button
                            className="btn color-bg float-btn m-0 text-white"
                            onClick={handleSubmit}
                          >
                            Add Testimonials
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* dasboard-wrapper end */}
              </div>
              {/* dashboard-footer */}
              {/* <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="about.html">About</a>
                    </li>
                    <li>
                      <a href="blog.html">Blog</a>
                    </li>
                    <li>
                      <a href="pricing.html">Pricing Plans</a>
                    </li>
                    <li>
                      <a href="contacts.html">Contacts</a>
                    </li>
                    <li>
                      <a href="help.html">Help Center</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div> */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* <footerComp /> */}
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
    </>
  );
}

export default AddTestinomials;
