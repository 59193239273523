import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

function AddFaq() {
  const initialFormData = {
    query: "",
    // answer: "",
    category: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const navigate = useNavigate();
  const editorRef = useRef(null);

  const apiUrl = process.env.REACT_APP_URL;
  const apiKey = process.env.REACT_APP_API_KEY;
  const Token = localStorage.getItem("Token");

  // A function to handle the change of the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // A function to handle the submit of the property form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const answer = editorRef.current.value
    console.log("answer =>", answer);
    // setFormData((prev) => ({ ...prev, content: content }));
    // console.log(formData, "formData======???>>");

    // const parser = new DOMParser();
    // const doc = parser.parseFromString(answer, 'text/html');
    // const plainText = doc.body.textContent || "";

    // setFormData((prev) => ({ ...prev, answer: plainText }));

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (formData[key] !== null) {
          formDataToSend.append(key, formData[key]);
        }
      }
      // formDataToSend.append("answer", `${answer}`);
      const response = await fetch(`${apiUrl}/faq/add`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
        body: formDataToSend,
      });

      const response2 = await response.json();
      if (response2.status === "error") {
        throw new Error(response2.message);
      }
      toast.success(response2.message);
      // window.location.reload()
      navigate("/faq");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    // <div>
    //     <link type="text/css" rel="stylesheet" href="css/plugins.css" />
    //     <link type="text/css" rel="stylesheet" href="css/style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
    //     <link type="text/css" rel="stylesheet" href="css/color.css" />
    //     {/*=============== favicons ===============*/}
    //     <link rel="shortcut icon" href="images/favicon.ico" />
    //     {/*loader*/}
    //     {/* <div className="loader-wrap">
    //         <div className="loader-inner">
    //             <svg>
    //                 <defs>
    //                     <filter id="goo">
    //                         <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
    //                         <feColorMatrix
    //                             in="blur"
    //                             values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
    //                             result="gooey"
    //                         />
    //                         <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
    //                     </filter>
    //                 </defs>
    //             </svg>
    //         </div>
    //     </div> */}
    //     {/*loader end*/}
    //     {/* main */}
    //     <div>
    //         {/* wrapper  */}
    //         <div id="wrapper">
    //             {/* dashbard-menu-wrap */}
    //             <div className="dashbard-menu-overlay" />
    //             {/* content */}
    //             <div className="dashboard-content pt-0">
    //                 <div className="dashboard-menu-btn color-bg">
    //                     <span>
    //                         <i className="fas fa-bars" />
    //                     </span>
    //                     Dasboard Menu
    //                 </div>
    //                 <div className="container dasboard-container">
    //                     {/* dashboard-title end */}
    //                     {/* dasboard-wrapper*/}
    //                     <div className="dasboard-wrapper fl-wrap no-pag">
    //                         <div className="row">
    //                             <div className="col-md-12">
    //                                 <div className="dasboard-widget-title fl-wrap">
    //                                     <h5>
    //                                         <i className="fas fa-user-circle" />
    //                                         Add FAQs
    //                                     </h5>
    //                                 </div>
    //                                 <div className="dasboard-widget-box fl-wrap">
    //                                     <form>
    //                                         <div className="custom-form">
    //                                             <label>
    //                                                 Question{" "}
    //                                                 <span className="dec-icon">
    //                                                     <i className="fa-solid fa-pen" />
    //                                                 </span>
    //                                             </label>
    //                                             <input
    //                                                 type="text"
    //                                                 placeholder="enter faq"
    //                                                 name="query"
    //                                                 onChange={(e) => {
    //                                                     handleChange(e)
    //                                                 }}
    //                                             />
    //                                             <label>
    //                                                 Category{" "}
    //                                                 <span className="dec-icon">
    //                                                     <i className="fa-solid fa-pen" />
    //                                                 </span>
    //                                             </label>
    //                                             <input
    //                                                 type="text"
    //                                                 placeholder="enter faq category"
    //                                                 name="category"
    //                                                 onChange={(e) => {
    //                                                     handleChange(e)
    //                                                 }}
    //                                             />
    //                                             <label>Answer </label>
    //                                             <textarea
    //                                                 cols={40}
    //                                                 rows={3}
    //                                                 placeholder="enter answer !"
    //                                                 style={{ marginBottom: 20 }}
    //                                                 name='answer'
    //                                                 onChange={(e) => {
    //                                                     handleChange(e)
    //                                                 }}
    //                                             />
    //                                         </div>
    //                                         <button
    //                                             className="btn color-bg float-btn m-0 text-white"
    //                                             onClick={handleSubmit}
    //                                         >
    //                                             Add FAQ
    //                                         </button>
    //                                     </form>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>
    //                     {/* dasboard-wrapper end */}
    //                 </div>
    //                 {/* dashboard-footer */}
    //                 <div className="footer m-0">
    //                     <div className="dashboard-footer-links fl-wrap">
    //                         <span>Helpfull Links:</span>
    //                         <ul>
    //                             <li>
    //                                 <a href="/about">About</a>
    //                             </li>
    //                             <li>
    //                                 <a href="/blog">Blog</a>
    //                             </li>
    //                             <li>
    //                                 <a href="/pricing">Pricing Plans</a>
    //                             </li>
    //                             <li>
    //                                 <a href="/contacts">Contacts</a>
    //                             </li>
    //                             <li>
    //                                 <a href="/faq">Help Center</a>
    //                             </li>
    //                         </ul>
    //                     </div>
    //                     <a href="#main" className="dashbord-totop  custom-scroll-link">
    //                         <i className="fas fa-caret-up" />
    //                     </a>
    //                 </div>
    //                 {/* dashboard-footer end */}
    //             </div>
    //             {/* content end */}
    //             <div className="dashbard-bg gray-bg" />
    //         </div>
    //         {/* wrapper end */}
    //     </div>
    //     {/* Main end */}
    //     {/*=============== scripts  ===============*/}
    // </div >
    <div>
      {/*=============== basic  ===============*/}
      <meta charSet="UTF-8" />
      <title>Hauzzi Beta Website UI/UX design by Webkype </title>
      <meta name="robots" content="index, follow" />
      <meta name="keywords" content />
      <meta name="description" content />
      {/*=============== css  ===============*/}
      <link type="text/css" rel="stylesheet" href="css/plugins.css" />
      <link type="text/css" rel="stylesheet" href="css/style.css" />
      <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
      <link type="text/css" rel="stylesheet" href="css/color.css" />
      {/*=============== favicons ===============*/}
      <link rel="shortcut icon" href="images/favicon.ico" />
      {/*loader*/}
      {/* <div className="loader-wrap">
      <div className="loader-inner">
        <svg>
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation={2} result="blur" />
              <feColorMatrix
                in="blur"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                result="gooey"
              />
              <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </div> */}
      {/*loader end*/}
      {/* main */}
      <div id="main">
        {/* header */}

        {/* header end  */}
        {/* header end  */}

        {/* wrapper  */}
        <div id="wrapper">
          {/* dashbard-menu-wrap */}
          <div className="dashbard-menu-overlay" />
          {/* content */}
          <div className="dashboard-content pt-0">
            <div className="dashboard-menu-btn color-bg">
              <span>
                <i className="fas fa-bars" />
              </span>
              Dasboard Menu
            </div>
            <div className="container dasboard-container">
              {/* dashboard-title end */}
              {/* dasboard-wrapper*/}
              <div className="dasboard-wrapper fl-wrap no-pag">
                <div className="row">
                  <div className="col-md-4">
                    {/* <div className="list-searh-input-wrap-title fl-wrap">
                            <i className="fa-solid fa-sliders-h" />
                            <span>Search Agency</span>
                          </div> */}

                    {/*box-widget*/}

                    {/*box-widget end */}
                    {/*box-widget*/}
                    <div
                      className="box-widget fl-wrap"
                    // style={{ marginTop: "70px" }}
                    >
                      <div className="banner-widget fl-wrap">
                        <div className="bg-wrap bg-parallax-wrap-gradien">
                          <div
                            className="bg  "
                            data-bg="https://hauzzi.webkype.net/admin/images/all/blog/1.jpg"
                          />
                        </div>
                        <div className="banner-widget_content">
                          <h5>Ideas are easy. Implementation is hard</h5>
                          <a
                            href="faq"
                            className="btn float-btn color-bg small-btn"
                          >
                            FAQs Listing
                          </a>
                        </div>
                      </div>
                    </div>

                    {/*box-widget end */}
                  </div>{" "}
                  <div className="col-md-8" style={{ marginTop: "20px" }}>
                    <div className="dasboard-widget-title fl-wrap">
                      <h5>
                        <i class="fa-solid fa-question"></i>
                        Add FAQs
                      </h5>
                    </div>
                    <div className="dasboard-widget-box fl-wrap">
                      <form>
                        <div className="custom-form">
                          <label>
                            Question{" "}
                            <span className="dec-icon">
                              <i className="fa-solid fa-pen" />
                            </span>
                          </label>
                          <input
                            type="text"
                            placeholder="enter faq"
                            name="query"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%" }}
                          />
                          <label>
                            Category{" "}
                            {/* <span className="dec-icon">
                              <i className="fa-solid fa-pen" />
                            </span> */}
                          </label>
                          {/* <input
                            type="text"
                            placeholder="enter faq category"
                            name="category"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            style={{ width: "100%" }}
                          /> */}
                          <div className="listsearch-input-item">
                            {" "}
                            {/* <select
                              name="category"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={formData.category}
                              style={{ width: "100%" }}
                              className="chosen-select no-search-select"
                            >
                              <option>Select a category</option>
                              <option>Buyer</option>
                              <option>Seller</option>
                              <option>Agency</option>
                              <option>Agent</option>
                              <option>Listing</option>
                              <option>About Us</option>
                              <option>FAQs</option>
                            </select> */}
                            <select
                              style={{
                                height: "4vh",
                                display: "inline",

                                padding: "10px",
                                borderRadius: "5px",
                                border: "1px solid #808080",
                                transition: "box-shadow 0.3s ease",
                                outline: "none  ",
                                width: "780px",
                                height: "50px",
                                color: "#808080",
                                background: "#f5f7fb",
                              }}
                              name="category"
                              value={formData.category}
                              onChange={handleChange}
                            >
                              <option>Select a category</option>
                              <option>Buyer</option>
                              <option>Seller</option>
                              <option>Agency</option>
                              <option>Agent</option>
                              <option>Listing</option>
                              <option>AboutUs</option>
                              <option>FAQ</option>
                            </select>
                          </div>
                          <label style={{ marginTop: "20px" }}>Answer </label>
                          <textarea
                            ref={editorRef}
                            cols={40}
                            rows={3}
                            placeholder="enter answer !"
                            style={{ marginBottom: 20, width: "100%" }}
                            name="answer"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          {/* <Editor
                            apiKey="et5utg7dawpzn7ytcg672kxyl722w9g8qfrvjxd52tp0fx7j"
                            onInit={(evt, editor) =>
                              (editorRef.current = editor)
                            }
                            initialValue="<p>Typing...</p>"
                            init={{
                              height: 300,
                              menubar: false,
                              plugins: [
                                "advlist",
                                "autolink",
                                "lists",
                                "link",
                                "image",
                                "charmap",
                                "preview",
                                "anchor",
                                "searchreplace",
                                "visualblocks",
                                "code",
                                "fullscreen",
                                "insertdatetime",
                                "media",
                                "table",
                                "code",
                                "help",
                                "wordcount",
                              ],
                              toolbar:
                                "undo redo | blocks | " +
                                "bold italic forecolor | alignleft aligncenter " +
                                "alignright alignjustify | bullist numlist outdent indent | " +
                                "removeformat | help",
                              content_style:
                                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                            }}
                          /> */}
                        </div>
                        <button
                          className="btn color-bg float-btn m-0 text-white"
                          onClick={handleSubmit}
                        >
                          Add FAQ
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* dasboard-wrapper end */}
            </div>
            {/* dashboard-footer */}
            <div
              className="dashboard-footer"
              style={{ left: 0, bottom: "-50px" }}
            >
              <div className="dashboard-footer-links fl-wrap">
                <span>Helpfull Links:</span>
                <ul>
                  <li>
                    <a href="https://hauzi-user.vercel.app/about">About</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/pricing">
                      Pricing Plans
                    </a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/contact">Contacts</a>
                  </li>
                  <li>
                    <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                  </li>
                </ul>
              </div>
              <a href="#main" className="dashbord-totop  custom-scroll-link">
                <i className="fa-solid fa-caret-up" />
              </a>
            </div>
            {/* dashboard-footer end */}
          </div>
          {/* content end */}
          <div className="dashbard-bg gray-bg" />
        </div>
        {/* wrapper end */}
      </div>
      {/* Main end */}
      {/*=============== scripts  ===============*/}
    </div>
  );
}

export default AddFaq;
