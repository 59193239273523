import React from "react";
import { useState, useEffect } from "react";

const apiUrl = process.env.REACT_APP_URL;
const Token = localStorage.getItem("Token");
const apiKey = process.env.REACT_APP_API_KEY;

const purposeList = [
  { id: 1, name: 'Buy (Sale)', purpose: 'wantToSell' },
  { id: 2, name: 'Rent', purpose: 'wantToRent' },
  { id: 3, name: 'Both', purpose: 'bothSellRent' },
]

function Property() {
  const initialFormData = {
    state: "",
    city: "",
  };
  const [data, setData] = useState([]);
  const [minValue, setMinValue] = useState(1000);
  const [maxValue, setMaxValue] = useState(100);
  const [category, setCategory] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [stateId, setStateId] = useState("");
  const [formData, setFormData] = useState(initialFormData);
  const [countryId, setCountryId] = useState([])
  const [typeId, setTypeId] = useState(1)
  const [typeOptions, setTypeOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [categoryId, setCategoryId] = useState(1)
  const [advancedFilterByObj, setAdvancedFilterByObj] = useState({})
  const [filterByObj, setFilterByObj] = useState({
    type: '',
    category: '',
    purpose: '',
    id: '',
    state: '',
    city: '',
    price: '',
    to: '',
    from: '',
    search: '',
    size: "",
  })



  function handleDelete(e, id) {
    e.preventDefault()
    // console.log('id=>', id)
    if (window.confirm("Do you want to delete")) {
      fetch(`${apiUrl}/employee/employeeDelete/` + id, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      })
        .then((res) => {
          alert("Record Deleted");
          window.location.reload(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }


  }

  function handleChangeProperty(e) {

    let value = e.target.value

    if (value === 'increase') {

      setData((prevPropertyList) => {

        let newPropertyList = [...prevPropertyList];

        const sortedProperties = newPropertyList.sort((a, b) => {
          // Convert maxPrice to numbers for comparison
          const priceA = parseFloat(a.maxPrice);
          const priceB = parseFloat(b.maxPrice);

          // Handle the case where maxPrice is not a number (e.g., "0")
          if (isNaN(priceA)) return 1;
          if (isNaN(priceB)) return -1;

          return priceA - priceB;
        });

        return sortedProperties

      })
    }

    if (value === 'decrease') {

      setData((prevPropertyList) => {

        let newPropertyList = [...prevPropertyList];

        const sortedProperties = newPropertyList.sort((a, b) => {

          // Convert maxPrice to numbers for comparison
          const priceA = parseFloat(a.maxPrice);
          const priceB = parseFloat(b.maxPrice);

          // Handle the case where maxPrice is not a number (e.g., "0")
          if (isNaN(priceA)) return 1;
          if (isNaN(priceB)) return -1;

          return priceB - priceA;
        });

        return sortedProperties

      })
    }

  }


  // A function to handle the change of the input fields
  const handleChange = (e) => {
    e.preventDefault()
    const { name, value } = e.target;
    console.log(name, value)
    setFilterByObj((prev) => ({ ...prev, [name]: value }));

    if (name === 'type') {
      const selectedDataKey = e.target.options[e.target.selectedIndex].getAttribute('dataKey')
      setTypeId(selectedDataKey)
    }


    if (name === 'category') {
      let linkedId = e.target.options[e.target.selectedIndex].getAttribute('linkedMasters')
      setCategoryId(linkedId)
    }


    // if (user?.id && window.location.href.split('?')?.[1]) {
    //     const queryString = window.location.href.split('?')?.[1]
    //     if (!queryString) {
    //         console.log("No query parameters found in the URL.");
    //         return {};
    //     }

    //     const paramsArray = queryString.split('&');

    //     paramsArray.forEach((param) => {
    //         const [key, value] = param.split('=');
    //         console.log(key, value, "query parameters found in the URL.");
    //         filterByObj[key] = value
    //     })
    // }
  };


  useEffect(() => {

    if (window.location.href.split('?')?.[1]) {
        const queryString = window.location.href.split('?')?.[1]
        if (!queryString) {
            console.log("No query parameters found in the URL.");
            return {};
        }

        const paramsArray = queryString.split('&');

        paramsArray.forEach((param) => {
            const [key, value] = param.split('=');
            console.log(key, value, "query parameters found in the URL.");
            filterByObj[key] = value
        })
    }

    fetch(`${apiUrl}/property/property?purpose=${filterByObj.purpose}&type=${filterByObj.type}&state=${filterByObj.state}&city=${filterByObj.city}&price=${filterByObj.price}&category=${filterByObj.category}&size=${filterByObj.size}&search=${filterByObj.search}&advancedFilter=${JSON.stringify(advancedFilterByObj)}`, {
        headers: {
            'Authorization': `Bearer ${apiKey}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data && Array.isArray(data.data)) {
              setData(data.data)
                console.log('fetched data =>', data.data)
                let count = []
                for (let j = 0; j < data.data.length; j++) {
                    let temp = 0;
                    for (let i = 0; i < 5; i++) {
                        if (data.data[j][`photo${i + 1}`]) {
                            temp++;
                        }
                    }
                    count = [...count, temp]
                }
                console.log(count, 'count=======', advancedFilterByObj)
                // setPhotoCount(count)
            } else {
                console.error('API response does not contain an array:', data);
            }
        })
        .catch(error => {
            console.error('Error fetching masters data:', error);
        });
}, [apiKey, apiUrl, filterByObj])


  useEffect(() => {
    fetch(`${apiUrl}/category/getAllCategory`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setTypeOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [apiUrl, apiKey])


  useEffect(() => {
    fetch(`${apiUrl}/category/getAllCategoryData/${typeId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data && Array.isArray(data.data)) {
          console.log('dataaaaaaaaaaa', data.data)
          setCategoryOptions(data.data);
        } else {
          console.error('API response does not contain an array:', data);
        }
      })
      .catch(error => {
        console.error('Error fetching masters data:', error);
      });
  }, [typeId, apiKey, apiUrl])

  useEffect(() => {
    fetch(`${apiUrl}/employee/allCountry`, {
        headers: {
            'Authorization': `Bearer ${apiKey}`,
        }
    })
        .then(response => response.json())
        .then(data => {
            if (data && Array.isArray(data.data)) {
                console.log('dataaaaaaaaaaa', data.data)
                setCountryOptions(data.data);
            } else {
                console.error('API response does not contain an array:', data);
            }
        })
        .catch(error => {
            console.error('Error fetching masters data:', error);
        });
}, [apiKey, apiUrl])


useEffect(() => {
  if (countryId) {
      fetch(`${apiUrl}/employee/allStates/${countryId}`, {
          headers: {
              'Authorization': `Bearer ${apiKey}`,
          }
      })
          .then(response => response.json())
          .then(data => {
              if (data && Array.isArray(data.data)) {
                  console.log('dataaaaaaaaaaa =>', data.data)
                  setStateOptions(data.data);
              } else {
                  console.error('API response does not contain an array:', data);
              }
          })
          .catch(error => {
              console.error('Error fetching masters data:', error);
          });
  }
}, [apiKey, apiUrl, countryId])

  useEffect(() => {
    fetch(`${apiUrl}/employee/allcities/${stateId}`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          console.log("dataaaaaaaaaaa", data.data);
          setCityOptions(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, [stateId, apiKey, apiUrl]);

  // const handleChange = (e) => {
  //   const { name, value, type, files } = e.target;
  //   if (type === "file") {
  //     setFormData((prev) => ({ ...prev, [name]: files[0] }));
  //   } else {
  //     setFormData((prev) => ({ ...prev, [name]: value }));
  //   }
  // };
  useEffect(() => {
    fetch(`${apiUrl}/category/getAllCategory`, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data.data)) {
          setCategory(data.data);
        } else {
          console.error("API response does not contain an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching masters data:", error);
      });
  }, []);

  const handleMaxChange = (event) => {
    setMaxValue(parseInt(event.target.value));
  };
  const TruncatedParagraph = (text, maxLength) => {
    const truncatedText =
      text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

    return truncatedText;
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }
  useEffect(() => {
    async function getData() {
      try {
        const response = await fetch(`${apiUrl}/property/property`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${apiKey}`,
          },
        });
        const resData = await response.json();
        if (resData.status === "success") {
          console.log(resData);
          setData(resData.data);
        }
      } catch (error) {
        throw new Error("Failed to fetch Data ", error.message);
      }
    }
    getData();
  }, []);
  console.log(data);

  return (
    <>
      <div>
        {/*=============== basic  ===============*/}
        <meta charSet="UTF-8" />
        <title>Hauzzi Beta Website UI/UX design by Webkype </title>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content />
        <meta name="description" content />
        {/*=============== css  ===============*/}
        <link type="text/css" rel="stylesheet" href="css/plugins.css" />
        <link type="text/css" rel="stylesheet" href="css/style.css" />
        <link type="text/css" rel="stylesheet" href="css/dashboard-style.css" />
        <link type="text/css" rel="stylesheet" href="css/color.css" />
        {/*=============== favicons ===============*/}
        <link
          rel="shortcut icon"
          href="https://hauzzi.webkype.net/images/favicon.ico"
        />
        {/*loader*/}
        {/* <div className="loader-wrap">
          <div className="loader-inner">
            <svg>
              <defs>
                <filter id="goo">
                  <feGaussianBlur
                    in="SourceGraphic"
                    stdDeviation={2}
                    result="blur"
                  />
                  <feColorMatrix
                    in="blur"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2"
                    result="gooey"
                  />
                  <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                </filter>
              </defs>
            </svg>
          </div>
        </div> */}
        {/*loader end*/}
        {/* main */}
        <div id="main">
          {/* header */}
          {/* header */}

          {/* header end  */}
          {/* header end  */}

          {/* wrapper  */}
          <div id="wrapper">
            {/* dashbard-menu-wrap */}
            <div className="dashbard-menu-overlay" />
            {/* dashbard-menu-wrap end  */}
            {/* content */}
            <div className="dashboard-content pt-0">
              <div className="dashboard-menu-btn color-bg">
                <span>
                  <i className="fa-solid fa-bars" />
                </span>
                Dasboard Menu
              </div>
              <div className="container dasboard-container">
                {/* dashboard-title end */}
                <div className="dasboard-wrapper fl-wrap">
                  <div className="dasboard-listing-box fl-wrap">
                    <div
                      className="top-search-content"
                      style={{ top: "120px", zIndex: "60" }}
                    >
                      <div
                        className="top-search-dec color-bg"
                        style={{ backgroundColor: "#213431 " }}
                      >
                        <i className="fa-solid fa-sliders-h" />
                      </div>
                      <div className="top-search-content-title">Search:</div>
                      {/* <div className="close_sb-filter">
                        <i className="fa-slolid fa-times" />
                      </div> */}
                      <div className="custom-form fl-wrap">


                        <div className="row">
                          {/* listsearch-input-item */}
                          {/* <div className="col-sm-3">
                                        <div className="listsearch-input-item">
                                            <input
                                                type="text"
                                                onclick="this.select()"
                                                placeholder="Address , Street , State..."
                                                defaultValue
                                            />
                                        </div>
                                    </div> */}
                          {/* listsearch-input-item */}
                          {/* listsearch-input-item */}




                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Categories"
                                className="form-select form-control"
                                name='type'
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option>All Categories</option>
                                {typeOptions.map((department) => (
                                  <option key={department.id} value={department.name} dataKey={department.id}>
                                    {department.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* listsearch-input-item */}
                          {/* listsearch-input-item */}
                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Sub Category"
                                className="form-select"
                                name='category'
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option value="">All Sub Category</option>
                                {categoryOptions.map((department) => (
                                  <option key={department.id} value={department.name} dataKey={department.id}>
                                    {department.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* listsearch-input-item */}
                          {/* listsearch-input-item */}
                          {/* <div className="col-sm-2 mt-2">
                                        <div className="listsearch-input-item">
                                            <select
                                                data-placeholder="Status"
                                                className="form-select"
                                                name='purpose'
                                                onChange={(e) => {
                                                    handleChange(e)
                                                }}
                                            >
                                                <option value="">All Availability</option>
                                                <option value="wantToRent">For Rent</option>
                                                <option value="wantToSell">For Sale</option>
                                            </select>
                                        </div>
                                    </div> */}
                          {/* listsearch-input-item */}
                          {/* listsearch-input-item */}

                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="All Countries"
                                name='country'
                                className="form-select"
                                onChange={(e) => {
                                  setCountryId(e.target.value)
                                  handleChange(e)
                                }}
                              >
                                <option>All Countries</option>
                                {countryOptions.map((department) => (
                                  <option
                                    key={department.id}
                                    value={department.id}
                                 
                                  >
                                    {department.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>


                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="All States"
                                name='state'
                                className="form-select"
                                onChange={(e) => {
                                  setStateId(e.target.value)
                                  handleChange(e)
                                }}
                              >
                                <option>All States</option>
                                {stateOptions.map((department) => (
                                  <option
                                    key={department.id}
                                    value={department.id}
                                  >
                                    {department.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* listsearch-input-item */}
                          {/* listsearch-input-item */}
                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="All Cities"
                                className="form-select"
                                name='city'
                                onChange={(e) => {
                                  handleChange(e)
                                }}
                              >
                                <option value={""}>All Cities</option>
                                {cityOptions.map((department) => (
                                  <option key={department.id} value={department.id}>
                                    {department.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          
                          {/* listsearch-input-item */}


                          <div className="col-sm-2 mt-2">
                            <div className="listsearch-input-item">
                              <select
                                data-placeholder="Purpose"
                                className="form-select"
                                name='purpose'
                                onChange={(e) => {
                                  const value = e.target.value
                                  setFilterByObj((prev) => ({ ...prev, 'purpose': value }));
                                }}
                              >
                                <option value={""}>All Availability</option>
                                {purposeList.map((purpose) => (
                                  <option key={purpose.id} value={purpose.purpose}>
                                    {purpose.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>



                          {/* listsearch-input-item */}
                          {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-rage-item fl-wrap">
                                                <span className="pr_title">Price: <br />$ 0 to {filterByObj.price ? filterByObj.price : 'max'}</span>
                                                <input
                                                    type="range"
                                                    className="w-80"
                                                    min={1000}
                                                    max={1000000}
                                                    name="price"
                                                    step={100}
                                                    data-prefix="$"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ marginTop: 10 }}>Price Range</label>
                                        <div className="price-rage-item fl-wrap">
                                            <input
                                                type="range"
                                                className="w-100"
                                                min={100}
                                                max={100000}
                                                name="price"
                                                step={1}
                                                defaultValue={1}
                                                data-prefix="$"
                                                onChange={(e) => {
                                                    // handleChange(e)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                          {/* listsearch-input-item */}
                          {/* <div className="col-sm-2">
                                        <div className="listsearch-input-item">
                                            <div className="price-range-item fl-wrap">
                                                <span className="pr_title">Size: <br />0 to {filterByObj.size ? filterByObj.size : 'max'}</span>
                                                <input
                                                    type="range"
                                                    className="w-50"
                                                    min={10}
                                                    max={10000}
                                                    name="size"
                                                    step={1}
                                                    data-prefix="$"
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label style={{ marginTop: 10 }}>Price Range</label>
                                        <div className="price-rage-item fl-wrap">
                                            <input
                                                type="range"
                                                className="w-100"
                                                min={100}
                                                max={100000}
                                                name="price"
                                                step={1}
                                                defaultValue={1}
                                                data-prefix="$"
                                                onChange={(e) => {
                                                    // handleChange(e)
                                                }}
                                            />
                                        </div>
                                    </div> */}
                        </div>


                      </div>
                      {/* <div className="more-opt_btn mor-opt-btn_act">
                        Advanced search
                        <span>
                          <i className="fa-solid fa-caret-down" />
                        </span>
                      </div> */}
                      {/* more-search-opt-wrap */}
                      <div className="more-search-opt-wrap more-hidden_wrap">
                        <div className="msotw_title fl-wrap">
                          <i className="fa-solid fa-sliders-h" />
                          Advanced Search
                        </div>
                        <div className="close_msotw">
                          <i className="fa-solid fa-times" />
                        </div>

                        {/* listsearch-input-item*/}

                        {/* listsearch-input-item end*/}
                      </div>
                      {/* more-search-opt-wrap end */}
                    </div>
                    {/* col-list-wrap */}
                    <section className="gray-bg small-padding ">
                      <div className="container">
                        <div className="row">
                          {/* search sidebar*/}
                          <div className="col-md-4">
                            {/*box-widget*/}
                            <div className="box-widget fl-wrap">
                              <div className="box-widget-title fl-wrap">
                                New Property
                              </div>
                              <div className="box-widget-content fl-wrap">
                                {/*widget-posts*/}
                                {data.length &&
                                  data.map((item, index) => (
                                    <div
                                      className="widget-posts  fl-wrap"
                                      key={index}
                                    >
                                      <ul className="no-list-style">
                                        <li>
                                          <div className="widget-posts-img">
                                            <a
                                              className="geodir-category-img_item"
                                              href={`https://hauzi-user.vercel.app/${item.slug}`}
                                            >
                                              <img
                                                src={`${apiUrl}/uploads/${item.photo1}`}
                                                alt
                                                style={{ height: '59px', objectFit: 'cover' }}
                                              />
                                              <div className="overlay" />
                                            </a>
                                          </div>
                                          <div className="widget-posts-descr agent-post_descr">
                                            <h4>
                                              <a
                                                href={`https://hauzi-user.vercel.app/${item.slug}`}
                                              >
                                                {item.name}
                                              </a>
                                            </h4>
                                            <div className="agent-post_descr_counter fl-wrap">
                                              <span>Date</span>{" "}
                                              {formatDate(item.updatedAt)}
                                            </div>
                                            <div
                                              className="listing-rating card-popup-rainingvis"
                                              data-starrating2={4}
                                            >
                                              {" "}
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  ))}
                                {/* widget-posts end*/}
                                {/* <a
                                  href="listing.html"
                                  className="btn float-btn color-bg small-btn"
                                >
                                   Property
                                </a> */}
                              </div>
                            </div>
                            {/*box-widget end */}
                            {/*box-widget*/}
                            <div className="box-widget fl-wrap">
                              <div className="banner-widget fl-wrap">
                                <div className="bg-wrap bg-parallax-wrap-gradien">
                                  <div
                                    className="bg  "
                                    data-bg="https://hauzzi.webkype.net/images/all/blog/1.jpg"
                                  />
                                </div>
                                <div className="banner-widget_content">
                                  <h5>
                                    Do you want to join our real estate network?
                                  </h5>
                                  {/* <a
                                    href="#"
                                    className="btn float-btn color-bg small-btn"
                                  >
                                    Become an Property
                                  </a> */}
                                </div>
                              </div>
                            </div>
                            {/*box-widget end */}
                          </div>
                          {/* search sidebar end*/}
                          <div className="col-md-8">
                            {/* list-main-wrap-header*/}
                            <div className="list-main-wrap-header box-list-header fl-wrap">
                              {/* list-main-wrap-title*/}
                              <div className="list-main-wrap-title">
                                <h2>
                                  All Property
                                  <strong>{data.length}</strong>
                                </h2>
                              </div>
                              {/* list-main-wrap-title end*/}
                              {/* list-main-wrap-opt*/}
                              <div className="list-main-wrap-opt">
                                {/* price-opt*/}
                                <div className="price-opt">
                                  <span className="price-opt-title">Sort by:</span>
                                  <div
                                    className="listsearch-input-item"
                                  >
                                    <select
                                      data-placeholder="Popularity"
                                      className="form-select mt-2"
                                      onChange={(e) => {
                                        handleChangeProperty(e)
                                      }}
                                    >
                                      <option>Popularity</option>
                                      <option value='increase' >Price: low to high</option>
                                      <option value='decrease' >Price: high to low</option>
                                    </select>
                                  </div>
                                </div>
                                {/* price-opt end*/}
                              </div>
                              {/* list-main-wrap-opt end*/}
                            </div>
                            {/* list-main-wrap-header end*/}
                            {/* dashboard-listings-wrap*/}
                            <div className="listing-item-container fl-wrap">
                              {/* listing-item */}
                              {data.length &&
                                data.map((item, index) => (
                                  <div
                                    className="listing-item has_one_column"
                                    key={index}
                                  >
                                    <article className="geodir-category-listing fl-wrap">
                                      <div className="geodir-category-img fl-wrap">
                                        <a
                                          className="geodir-category-img_item"
                                          href={`https://hauzi-user.vercel.app/${item.slug}`}
                                        >
                                          <img
                                            src={`${apiUrl}/uploads/${item.photo1}`}
                                            alt
                                            style={{ height: '200px', objectFit: 'cover' }}
                                          />
                                          <div className="overlay" />
                                        </a>
                                        <div className="geodir-category-location">
                                          <a
                                            href="#1"
                                            className="map-item tolt"
                                            data-microtip-position="top-left"
                                            data-tooltip="On the map"
                                          >
                                            <i className="fa-solid fa-map-marker-alt" />{" "}
                                            {item.address1}
                                          </a>
                                        </div>
                                        <ul className="list-single-opt_header_cat">
                                          <li>
                                            <a
                                              href="#"
                                              className="cat-opt blue-bg"
                                            >
                                              {item.purpose}
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#"
                                              className="cat-opt color-bg"
                                            >
                                              {item.category}
                                            </a>
                                          </li>
                                        </ul>
                                        <div className="geodir-category-listing_media-list">
                                          <span>
                                            <i className="fa-solid fa-camera" />{" "}
                                            5
                                          </span>
                                        </div>
                                      </div>
                                      <div className="geodir-category-content fl-wrap">
                                        <h3>
                                          <a href="listing-single.html">
                                            {item.name}
                                          </a>
                                        </h3>
                                        <div className="geodir-category-content_price">
                                          ${item.maxPrice}
                                        </div>
                                        <p>
                                          {TruncatedParagraph(
                                            item.description,
                                            100
                                          )}
                                        </p>
                                        <div className="geodir-category-content-details">
                                          <ul>
                                            <li>
                                              <i className="fa-solid fa-bed" />
                                              <span>
                                                {item.listingDetails.Bedrooms}
                                              </span>
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-bath" />
                                              <span>
                                                {" "}
                                                {item.listingDetails.Bathroom}
                                              </span>
                                            </li>
                                            <li>
                                              <i className="fa-solid fa-cube" />
                                              <span>{item.maxSize} sqft</span>
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="geodir-category-footer fl-wrap">
                                          <a className="gcf-company">
                                            <img
                                              // src={`${apiUrl}/uploads/${
                                              //   item.Customer.userDetails[
                                              //     index
                                              //   ] == ""
                                              //     ? null
                                              //     : "item.Customer.userDetails[index].photo"
                                              //   // item.Customer.userDetails[index]
                                              //   //   .length &&
                                              //   // userDetails[index].photo
                                              // }`}
                                              src={
                                                // item.Customer.userDetails[index]
                                                //   .photo == " "
                                                //   ? "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                                //   : `${apiUrl}/uploads/${item.Customer.userDetails[index].photo}`

                                                item?.Customer?.userDetails?.length > 0 ?
                                                  `${apiUrl}/uploads/${item.Customer.userDetails[0].photo}` :
                                                  item?.Customer?.agentDetails?.length > 0 ?
                                                    `${apiUrl}/uploads/${item.Customer.agentDetails[0].photo}` :
                                                    item?.Customer?.agencyDetails?.length > 0 ?
                                                      `${apiUrl}/uploads/${item.Customer.agencyDetails[0].photo}` :
                                                      "https://img.freepik.com/premium-vector/businessman-avatar-cartoon-character-profile_18591-50141.jpg"
                                              }
                                              alt
                                              style={{ objectFit: 'cover' }}
                                            />

                                            <span>{item.Customer.name}</span>
                                          </a>
                                          <div
                                            className="listing-rating card-popup-rainingvis tolt"
                                            data-microtip-position="top"
                                            data-tooltip="Good"
                                            data-starrating2={4}
                                          />
                                        </div>
                                        <div className="dashboard-listings-item_opt btm_footer-item">
                                          <span className="viewed-counter">
                                            <i className="fa-solid fa-eye" />{" "}
                                            Viewed - {item.views}
                                          </span>
                                          <ul>
                                            {/* <li>
                                              <a
                                                href="#"
                                                className="tolt"
                                                data-microtip-position="top-left"
                                                data-tooltip="Edit"
                                              >
                                                <i className="fa-solid fa-edit" />
                                              </a>
                                            </li> */}
                                            {/* <li>
                                              <a
                                                href="#"
                                                className="tolt text-red"
                                                data-microtip-position="top-left"
                                                data-tooltip="Disable"
                                              >
                                                <i className="fa-solid fa-signal-alt-slash" />
                                              </a>
                                            </li> */}
                                            <li>
                                              <a
                                                onClick={(e) => {
                                                  handleDelete(e, item.id)
                                                }}
                                                className="tolt"
                                                data-microtip-position="top-left"
                                                data-tooltip="Delete"
                                              >
                                                <i className="fa-solid fa-trash-alt" />
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </article>
                                  </div>
                                ))}
                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                              {/* listing-item */}

                              {/* listing-item end*/}
                            </div>
                            {/* col-md 8 end */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              {/* dashboard-footer */}
              <div
                className="dashboard-footer"
                style={{ left: 0, bottom: "-50px" }}
              >
                <div className="dashboard-footer-links fl-wrap">
                  <span>Helpfull Links:</span>
                  <ul>
                    <li>
                      <a href="https://hauzi-user.vercel.app/about">About</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/blog">Blog</a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/pricing">
                        Pricing Plans
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/contact">
                        Contacts
                      </a>
                    </li>
                    <li>
                      <a href="https://hauzi-user.vercel.app/help">Help FAQs</a>
                    </li>
                  </ul>
                </div>
                <a href="#main" className="dashbord-totop  custom-scroll-link">
                  <i className="fa-solid fa-caret-up" />
                </a>
              </div>
              {/* dashboard-footer end */}
            </div>
            {/* content end */}
            <div className="dashbard-bg gray-bg" />
          </div>
          {/* wrapper end */}
        </div>
        {/* Main end */}
        {/*=============== scripts  ===============*/}
      </div>
      ;
    </>
  );
}

export default Property;
